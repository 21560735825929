export const ClickEffectAnimationConfig = {
  atlas: 'click_effect',
  animations: [
    {
      frameRate: 24,
      key: 'click',
      name: 'click_effect_animation',
      frameLength: 12,
      repeat: 0,
    },
  ],
};
