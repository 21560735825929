import { ERROR_MESSAGES, GAME_MESSAGES } from '../../../consts/Messages';
import { AddGameChainComponent } from '../components/AddGameChainComponent';
import { HOVER_COMPONENT_ORIGIN } from '../components/HoverComponent';
import { InfoIconComponent } from '../components/InfoIconComponent';
import { Mailbox } from '../entities/Mailbox';
import { EasterEggTree } from '../entities/Tree';
import Layout from '../layouts/CharacterSelectLayout';
import { LoadingStateManager } from '../manager/LoadingStateManager';
import { SessionManager } from '../manager/SessionManager';
import { SoundManager } from '../manager/SoundManager';
import { TransactionWaitingGif } from '../manager/TransactionWaitingGif';
import { DataService } from '../services/DataService';
import { GameUtils } from '../Utils/GameUtils';

export class CharacterSelectScene extends Phaser.Scene {
  constructor() {
    super({ key: 'CharacterSelectScene' });
  }

  init(logout = false) {
    this.logout = logout;
  }

  create() {
    this.session = new SessionManager();
    this.session.clearSession();
    if (this.logout === true) {
      history.pushState({}, null, `#/oni-mansion-game/`);
      this.scene.launch('CharacterSelectPanel');
    }

    GameUtils.switchNetworks();
    this.make.sprite(Layout.BACKGROUND);
    this.make.sprite(Layout.GAME_LOGO);
    this.dataService = new DataService();
    const btn = this.make.sprite(Layout.SELECT_CHARACTER_BUTTON).setInteractive();
    const loader = new LoadingStateManager(this, false);
    btn.on('pointerup', async () => {
      try {
        SoundManager.clickScreenSound(this);
        if (!this.dataService?.session?.sessionToken) {
          loader.setVisible(true);
          this.add.modalMessage(GAME_MESSAGES.WAITING_FOR_LOGIN);
          await this.dataService.authenticateJwt();
          this.scene.stop('MessageModalSubScene');
          loader.setVisible(false);
          this.togglePanel();
        } else {
          this.togglePanel();
        }
      } catch (error) {
        loader.setVisible(false);
      }
    });
    this.checkErrors();
    this.addInfoIcon();
    this.addFAQIcon();
  }

  addFAQIcon() {
    const { x, y, depth } = Layout.FAQ_ICON;
    this.FAQ_icon = this.add
      .image(x, y, 'FAQ_icon')
      .setDepth(depth)
      .setScale(0.18)
      .setInteractive();

    this.FAQ_icon.on('pointerdown', () => {
      window.open('https://onisquad.medium.com/oni-mansion-game-faq-ddfb5eab7dcb', '_blank');
    });
  }

  addInfoIcon() {
    const { x, y, depth } = Layout.INFO_ICON;
    this.info_icon?.destroy();
    this.info_icon = new InfoIconComponent(this, x, y, 'Click to See More.', HOVER_COMPONENT_ORIGIN.BOTTOM_LEFT);
    this.info_icon.setDepth(depth);
    this.info_icon.info_button_sprite.setDisplaySize(50, 50);
    this.info_icon.info_button_sprite.on('pointerup', () => {
      window.open('https://onisquad.medium.com/oni-mansion-game-walkthrough-beta-2861ac24d8b3', '_blank');
    });
  }

  togglePanel() {
    if (!this.scene.get('CharacterSelectPanel').scene.isActive()) {
      this.scene.launch('CharacterSelectPanel');
    } else {
      this.scene.bringToTop('CharacterSelectPanel');
    }
  }

  checkErrors() {
    const error_code = location.href.includes('error_code=') ? location.href.split('error_code=')[1] : null;
    if (error_code && error_code === '401') {
      this.add.modalMessage(ERROR_MESSAGES[error_code]);
      this.dataService.session.setSessionToken(null);
      history.pushState({}, null, `#/oni-mansion-game/`);
    }
  }
}
