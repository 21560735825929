import Phaser from 'phaser';
import { HOUSE_CREATION_ORDER, HOUSE_CREATION_POSITION, HOUSE_TRAITS_CONFIG } from '../consts/HouseTraits';
import { HOUSE_LAYOUT } from '../layouts/HouseLayout';
import { Candles } from './Candles';
import { EasterEggTree } from './Tree';
import { GameUtils } from '../Utils/GameUtils';
import { LoadingStateManager } from '../manager/LoadingStateManager';
import { CandleCounter } from '../components/CandleCounter';
export const HOUSE_PINATA_URL = `https://onisquadgg.mypinata.cloud/ipfs/QmNknCLQsNprFyMkzRUnFXNHFK6iG745u2UGw24AwaWqWA`;
export const HOUSE_SMALL_PINATA_URL = `https://onisquadgg.mypinata.cloud/ipfs/QmTZSgM6K1NvxnQhS8rDD3UGqKP6UT2WUaRD9eVRydEphU`;

export class House extends Phaser.GameObjects.Container {
  constructor(scene, x, y, config, preview = false, hauntCount = 0, visitCount = 0, size = 1) {
    super(scene, x, y);
    /**
     * @type {Phaser.Scene}
     */
    this.scene = scene;
    /**
     * Is it a preview mode? If so, we don't need to load the colored house.
     * @type { boolean }
     */
    this.preview = preview;

    /**
     * The House traits Object.
     */
    this.house_traits = {};
    this.loader = new LoadingStateManager(scene, false);

    if (!this.preview) {
      this.house_sky = this.scene.add.image(0, 0, 'house_sky').setOrigin(0, 0);
      this.stars = this.scene.add
        .tileSprite(0, 0, this.scene.game.config.width, this.scene.game.config.height, 'stars')
        .setOrigin(0, 0)
        .setScrollFactor(0);
      this.tree = new EasterEggTree(scene, hauntCount, size);
      this.candles = new Candles(scene, visitCount, size);
      this.candle_counter = new CandleCounter(scene, visitCount);
    }
    this.base_house = this.scene.add.image(0, 0, preview ? 'base_house_grayscale' : 'base_house').setOrigin(0, 0);
    const items = [this.house_sky, this.stars, this.base_house, this.tree, this.candles, this.candle_counter].filter(
      (item) => item != null
    );
    this.add(items);
    this.scene.add.existing(this);

    if (preview) {
      this.previewMode();
    }
    this.createTraits(config, size);

    this.scene.events.on('update', () => {
      if (this.stars) this.stars.tilePositionX -= 0.2;
    });
  }

  async lazyLoad(key, attributeName, size) {
    this.loader.setVisible(true);
    const keyValue = `${key}/${attributeName}`;
    this.scene.load.image(
      keyValue,
      `${size === 1 ? HOUSE_PINATA_URL : HOUSE_SMALL_PINATA_URL}/${key
        .toLowerCase()
        .trim()
        .split(' ')
        .join('_')}/${attributeName}.png`
    );
    this.scene.load.start();

    await new Promise((resolve) => {
      const finish = () => {
        this.loader.setVisible(false);
        resolve();
      };
      if (this.scene.textures.exists(keyValue)) {
        finish();
        return;
      }
      this.scene.load.once(`filecomplete-image-${keyValue}`, finish);
    });
  }

  /**
   * Creates the house traits object.
   * @param {any} config
   */
  async createTraits(config, size = 1) {
    // config = Object.fromEntries(Object.entries(config).filter((v) => v[1] !== null));
    await Object.keys(HOUSE_CREATION_ORDER).forEach(async (key, index) => {
      if (config[key]) await this.lazyLoad(key, config[key], size);
      const { x, y } = HOUSE_CREATION_POSITION[key];
      this.house_traits[key]?.destroy();
      this.house_traits[key] = this.scene.add
        .image(x, y, config[key] ? `${key}/${config[key]}` : 'empty_image')
        .setOrigin(0, 0)
        .setDepth(index);
      this.add(this.house_traits[key]);
      if (this.preview) this.previewMode();
    });
  }

  setScaleMultiple(scale) {
    Object.keys(this.house_traits).forEach((key) => {
      this.house_traits[key].setScale(scale);
      // this.house_traits[key].setPosition(this.house_traits[key].x * sscale, this.house_traits[key].y * scale);
    });
  }

  updateTraitFrames(config) {
    Object.keys(this.house_traits).forEach(async (key, index) => {
      if (config[key]) await this.lazyLoad(key, config[key]);

      this.house_traits[key].setTexture(config[key] ? `${key}/${config[key]}` : 'empty_image');
    });
  }

  previewMode() {
    this.base_house.setScale(0.5);
    this.setScaleMultiple(0.5);
  }

  removeAllTraits() {
    Object.keys(this.house_traits).forEach((key) => {
      this.house_traits[key].setTexture(`empty_image`);
    });
  }

  destroyAll() {
    this.candles.destroy();
    this.tree.destroy();
    this.destroy();
  }
}
