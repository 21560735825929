import Phaser from 'phaser';
import { GameUtils } from '../../Utils/GameUtils';
import { IMAGES } from '../../layouts/Haunt';
import { InputText } from '../../components/InputText';
import { Button } from '../../components/Button';
import { DataService } from '../../services/DataService';
import { Overlay } from '../../components/Overlay';
import { LoadingStateManager } from '../../manager/LoadingStateManager';
import { TransactionWaitingGif } from '../../manager/TransactionWaitingGif';

const NFT_FRAME = {
  x: 1316,
  y: 267,
  key: 'haunt_message_box_frame',
  origin: {
    x: 0,
    y: 0,
  },
  depth: 10,
};

export const LENGTH_TEXT = {
  x: 791,
  y: 711,
  max_length: 200,
  style: {
    fontFamily: 'BlackBeard',
    fontSize: '28px',
    color: '#000000',
  },
};

export class HauntMessageBoxSubScene extends Phaser.Scene {
  constructor() {
    super({ key: 'HauntMessageBoxSubScene' });
    /**
     * @type {Array<Button>}
     */
    this.buttos = [];
    /**
     * @type {DataService}
     */
    this.dataService = new DataService();
    this.houseParams = { hauntType: null, contract_address: null, nft_id: null, nft_info: null };
    /**
     * @type {InputText}
     */
    this.messageInput;
    /**
     * @type {Phaser.GameObjects.Text}
     */
    this.messageText;
  }

  init({ hauntType, contract_address, nft_id, nft_info }) {
    this.houseParams = { hauntType, contract_address, nft_id, nft_info };
  }

  preload() {
    this.transactionGIF = new TransactionWaitingGif(this, false);
    /**
     * @type {HauntMessageBoxSubScene}
     */
    this.buttonBehaviors = {
      [BUTTONS_NAME.SEND_MESSAGE]: async () => {
        this.transactionGIF.setVisible(true);
        this.scene.get('HouseHauntScene').events.emit('haunt', {
          type: this.houseParams.hauntType,
          message: this.messageInput.value,
          nft_info: this.houseParams.nft_info,
        });
      },
      [BUTTONS_NAME.INPUT_MESSAGE_AREA]: () => {
        this.messageInput.focus();
      },
      [BUTTONS_NAME.CLOSE]: () => {
        this.messageInput.destroy();
        this.scene.get('HouseHauntScene').events.emit('messageboxclosed');
        this.scene.stop();
      },
    };
    this.overlay = new Overlay(this);
    this.load.rexAwait(async (success, error) => {
      try {
        await this.dataService.getWeb3Config();
        success();
      } catch (err) {
        console.error(err);
        error();
      }
    });
  }

  create() {
    this.events.removeListener('removeloaderGIF');
    this.events.on(
      'removeloaderGIF',
      () => {
        this.transactionGIF.setVisible(false);
      },
      this
    );
    this.make.sprite(IMAGES.MESSAGEBOX_MENU_BACKGROUND);
    this.make.sprite(NFT_FRAME);
    this.length_text = this.add.text(
      LENGTH_TEXT.x,
      LENGTH_TEXT.y,
      `Length: 0 of ${LENGTH_TEXT.max_length}`,
      LENGTH_TEXT.style
    );
    window.bbb = this.add
      .sprite(1335, 281, `${this.houseParams.nft_info.name}`)
      .setOrigin(0)
      .setDisplaySize(362, 355);
    this.createMessageInput();
    this.createMessageText();
    this.addButtons();
  }

  createMessageInput() {
    this.messageInput = new InputText(this);
    this.messageInput.setMaxLength(MAX_INPUT_MESSAGEBOX_LENGTH);
  }

  createMessageText() {
    this.messageText = this.make.text(LAYOUT.TEXT.MESSAGE_INPUT_TEXT);
    this.messageInput.setTextGameObject(this.messageText);
    this.messageText.visible = false;
  }

  addButtons() {
    this.buttos = GameUtils.addButtonsGroup(this, LAYOUT.BUTTONS, this.buttonBehaviors);
  }
}

const BUTTONS_NAME = {
  SEND_MESSAGE: 'SEND_MESSAGE',
  INPUT_MESSAGE_AREA: 'INPUT_MESSAGE_AREA',
  CLOSE: 'CLOSE',
};

const LAYOUT = {
  BUTTONS: {
    [BUTTONS_NAME.SEND_MESSAGE]: {
      SIZE: {
        WIDTH: 406,
        HEIGHT: 181,
      },
      POSITION: {
        X: 1027,
        Y: 696,
      },
    },
    [BUTTONS_NAME.INPUT_MESSAGE_AREA]: {
      SIZE: {
        WIDTH: 486,
        HEIGHT: 294,
      },
      POSITION: {
        X: 797,
        Y: 366,
      },
    },
    [BUTTONS_NAME.CLOSE]: {
      SIZE: {
        WIDTH: 87,
        HEIGHT: 85,
      },
      POSITION: {
        X: 1685,
        Y: 90,
      },
    },
  },
  TEXT: {
    MESSAGE_INPUT_TEXT: {
      x: 806,
      y: 369,
      width: 490,
      height: 290,
      style: {
        fontSize: '38px',
        fontFamily: 'BlackBeard',
        color: '#000000',
        wordWrap: {
          width: 470,
          useAdvancedWrap: true,
        },
      },
      origin: 0,
    },
  },
};

//LAYOUT.TEXT.MESSAGE_INPUT_TEXT.style.wordWrap.width = LAYOUT.BUTTONS[BUTTONS_NAME.INPUT_MESSAGE_AREA].SIZE.WIDTH;

const MAX_INPUT_MESSAGEBOX_LENGTH = 999; //113;
