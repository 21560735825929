export const LootboxAnimationConfig = {
  atlas: 'lootbox_animations',
  animations: [
    {
      frameRate: 16,
      key: 'lootbox_animations/idle/lootbox',
      name: 'lootbox_idle',
      frameLength: 16,
      repeat: -1,
    },
    {
      frameRate: 13,
      key: 'lootbox_animations/open/lootbox',
      name: 'lootbox_open',
      frameLength: 20,
      repeat: 0,
    },
  ],
};
