import { MAILBOX_PANEL } from '../../layouts/MainScreen';

export class MailBoxElement extends Phaser.GameObjects.Container {
  constructor(scene, message_config, depth = 1) {
    super(scene, 0, 0);
    /**
     * @type { Phaser.Scene}
     */
    this.scene = scene;

    this.message_config = message_config;
    this.createMailIcon();
    this.createMailTextBox();
    this.createMailText();
    const { width, height } = MAILBOX_PANEL.MAIL_BOX_GRID;
    this.setInteractive(new Phaser.Geom.Rectangle(0, 0, width, height), Phaser.Geom.Rectangle.Contains);
    this.add([this.mail_icon, this.mail_text_box, this.text]);
    this.originalDepth = depth;
    this.setDepth(this.originalDepth);
  }

  createMailIcon() {
    this.mail_icon = this.scene.add
      .image(0, 0, this.message_config.read ? 'mail_icon_read' : 'mail_icon_unread')
      .setOrigin(0, 0);
  }
  createMailTextBox() {
    this.mail_text_box = this.scene.add.image(144, 0, 'mail_box_text_box_closed').setOrigin(0, 0);
  }

  createMailText() {
    this.text = this.scene.make.text(MAILBOX_PANEL.MAIL_BOX_ELEMENT.TEXT, true);
    this.setSmallText();
  }

  toggleBox() {
    const close = this.mail_text_box.texture.key === 'mail_box_text_box_closed';
    if (close) this.setFullText();
    else this.setSmallText();

    if (!this.message_config.read) {
      this.message_config.read = !this.message_config.read;
      this.scene.dataService.updateMessage(this.message_config);
    }
    this.mail_text_box.setTexture(close ? 'mail_box_text_box_opened' : 'mail_box_text_box_closed');
    this.mail_icon.setTexture(this.message_config.read ? 'mail_icon_read' : 'mail_icon_unread');
  }

  setSmallText() {
    this.text.setText(this.message_config.message.substring(0, 40) + '...');
  }
  setFullText() {
    this.text.setText(this.message_config.message);
  }
}
