import Phaser from 'phaser';

/**
 * @class
 * This class represents a player entity.
 * */
export class PlayerEntity extends Phaser.GameObjects.Container {
  constructor(scene, x, y, config) {
    super(scene, x, y);
    /**
     * This is the scene that the player was created in.
     * @type { Phaser.Scene }
     * */
    this.scene = scene;

    /**
     * Entity configuration. Textures, animations, etc.
     */
    this.config = config;
    /**
     * Entity body parts.
     */
    this.entityBodyParts = {
      Background: null,
      Shoes: null,
      Back: null,
      'Body Color': null,
      Torso: null,
      Hand: null,
      Eyes: null,
      Mouth: null,
      Hat: null,
      Neck: null,
    };
    Object.keys(this.entityBodyParts).forEach((key) => {
      this.entityBodyParts[key] = this.scene.add.sprite(0, 0, `${key}_${this.config[key]}`);
      this.add(this.entityBodyParts[key]);
    });

    // Adds the customer to the scene.
    this.scene.add.existing(this);
  }

  playAnimation(animation) {
    Object.keys(this.entityBodyParts).forEach((key) => {
      const bodyPart = this.entityBodyParts[key];
      bodyPart.anims.play(`${bodyPart.texture.key}_${animation}`, true);
    });
  }
  setOrigin(x, y) {
    Object.keys(this.entityBodyParts).forEach((key) => this.entityBodyParts[key].setOrigin(x, y));
  }
  setScale(scale) {
    Object.keys(this.entityBodyParts).forEach((key) => this.entityBodyParts[key].setScale(scale));
  }
  setDisplaySizeMultiple(width, height) {
    Object.keys(this.entityBodyParts).forEach((key) => this.entityBodyParts[key].setDisplaySize(width, height));
  }
}
