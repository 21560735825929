import Phaser from 'phaser';
import { ScrollablePanel } from '../../components/ScrollablePanel';
import { TraitPanelElement } from '../../entities/TraitPanelElement';
import { TRAIT } from '../../layouts/MainScreen';
import { IS_DEBUG_MODE } from '../../OniMansionGame';
import { DataService } from '../../services/DataService';

const MIN_AMOUNT_OF_TRAITS_TO_CREATE_SCROLL = 9;

export class TraitPanelSubScene extends Phaser.Scene {
  constructor() {
    super({ key: 'TraitPanelSubScene' });
    /**
     * @type {Phaser.GameObjects.Image}
     */
    this.customizationMenu;
    /**
     * @type {Phaser.GameObjects.Container}
     */
    this.traits_container;

    /**
     * @type {Array<TraitPanelElement>}
     */
    this.traitsImages = [];
    /**
     * @type {number}
     */
    /**
     * @type {ScrollablePanel}
     */
    this.scroll_panel;
    this.dataService = new DataService();
  }

  create() {
    this.customizationMenu = this.add
      .image(1423, 111, 'customization_panel')
      .setOrigin(0, 0)
      .setDepth(10)
      .setInteractive();
    this.traits_container?.destroy();
    this.traits_container = this.add.container(0, 0);
    this.traits_container.depth = TRAIT.DEPTH;
    this.addImages();

    this.add.existing(this);
    this.events.on('setvisibility', this.setVisibility, this);
  }

  addImages() {
    this.dataService.owned_traits.forEach((trait, index) => {
      const traitImage = new TraitPanelElement(this);
      traitImage.setConfig(trait, index);
      this.traits_container.add(traitImage);
    });
    Phaser.Actions.GridAlign(this.traits_container.list, {
      x: TRAIT.SCROLL.X + TRAIT.SIZE.WIDTH / 2,
      y: TRAIT.SCROLL.Y + TRAIT.SIZE.HEIGHT / 2,
      width: TRAIT.GRID.WIDTH_AMOUNT,
      height: TRAIT.GRID.HEIGHT_AMOUNT,
      cellWidth: TRAIT.SIZE.WIDTH + TRAIT.MARGIN.H,
      cellHeight: TRAIT.SIZE.HEIGHT + TRAIT.MARGIN.V,
    });
    if (this.traits_container.list.length >= MIN_AMOUNT_OF_TRAITS_TO_CREATE_SCROLL) this.addScrollable();
  }

  addScrollable() {
    const scrollable = new ScrollablePanel(this, true);
    const { height } = this.traits_container.getBounds();
    scrollable.setPositions(
      TRAIT.SCROLL.X,
      TRAIT.SCROLL.Y,
      TRAIT.SCROLL.WIDTH,
      TRAIT.SCROLL.HEIGHT,
      height + TRAIT.SIZE.HEIGHT / 2
    );
    scrollable.createScrollSection(this.traits_container);
    this.scroll_panel = scrollable;
    this.traits_container.iterate((trait) => trait.setCheckIsVisible(true));
    this.scroll_panel.createArrowControl({
      up: TRAIT.SCROLL.up,
      down: TRAIT.SCROLL.down,
      scrollFactor: 1,
      duration: 1000,
    });
    if (IS_DEBUG_MODE) {
      const debug_graphics = this.add.graphics();
      debug_graphics.fillStyle(0x00ffff, 0.5);
      debug_graphics.fillRectShape(this.traits_container.getBounds());
    }
  }

  setVisible(visible) {
    this.traits_container?.setVisible(visible);
  }

  setVisibility(hide) {
    const targets = [this.customizationMenu, this.traits_container];
    if (this.traits_container?.scrollable?.mask) targets.push(this.traits_container.scrollable.mask);

    this.add.tween({
      targets,
      ease: 'Linear',
      duration: 0,
      alpha: hide ? 0 : 1,
    });
  }
}
