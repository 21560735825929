export const TRAITS_CONFIG = {
  Chimney: null,
  Door: null,
  Roof: null,
  Yard: null,
  'Tower Window': null,
  'Roof Window': null,
  'Side Window': null,
  'First Front Window': null,
};
