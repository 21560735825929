import { CANDLE_TIERS } from '../consts/HauntCounterTiers';
import { HOUSE_CANDLE_LAYOUT } from '../layouts/HouseLayout';
import { GameUtils } from '../Utils/GameUtils';

export class Candles extends Phaser.GameObjects.Sprite {
  /**
   *
   * @param { Phaser.Scene } scene
   */
  constructor(scene, count = 0, size = 1) {
    const { x, y, key, key_small } = HOUSE_CANDLE_LAYOUT;
    super(scene, x, y, size === 1 ? key : key_small);
    this.scene = scene;
    this.updateTreeFrame(count);
  }

  updateTreeFrame(count = 0) {
    const { depth } = HOUSE_CANDLE_LAYOUT;
    const { originX, originY } = HOUSE_CANDLE_LAYOUT.origin;
    const tier = GameUtils.getHouseTier(CANDLE_TIERS, count);
    if (tier) {
      this.setFrame(tier);
      this.setDepth(depth);
      this.scene.add.existing(this);
      this.setOrigin(originX, originY);
      this.setVisible(true);
    } else {
      this.setVisible(false);
    }
  }
}
