import Layout from '../../layouts/CharacterSelectLayout';
import { CharacterFrame } from './CharacterFrame';

export class CharacterPicture extends Phaser.GameObjects.Sprite {
  constructor(scene, i, j, texture) {
    const { x, y } = Layout.CHARACTER_GRID;
    const { v, h } = Layout.CHARACTER_GRID.margin;
    const { height, width } = Layout.CHARACTER_GRID;
    const originX = Layout.CHARACTER_GRID.origin.x;
    const originY = Layout.CHARACTER_GRID.origin.y;
    super(scene, x + j * width + j * h, y + i * height + i * v, texture);
    this.scene = scene;
    this.scene.add.existing(this);
    this.gridFrame = new CharacterFrame(this.scene, i, j);
    this.setOrigin(originX, originY);
    this.setDisplaySize(width, height);
    this.setInteractive();
  }
}
