export const LoreElementLayout = {
  x: 632,
  y: 296,
  height: 140,
  width: 850,
};

export class LoreElement extends Phaser.GameObjects.Container {
  /**
   *
   * @param {Phaser.Scene} scene
   */
  constructor(scene, config) {
    super(scene, 0, 0);
    this.scene = scene;
    this.config = config;
    this.createTitle();
    this.createDescription();
    scene.add.existing(this);
    this.setDepth(50);
  }

  createTitle() {
    this.title = this.scene.add
      .text(LoreElementLayout.x + LoreElementLayout.width / 2, LoreElementLayout.y + 25, this.config.lore_title, {
        fontSize: '38px',
        fontFamily: 'Helvetica Neue',
        color: '#000000',
        wordWrap: {
          width: LoreElementLayout.width,
          useAdvancedWrap: false,
        },
      })
      .setOrigin(0.5, 0.5);
    this.title.setDepth(2);
    this.add(this.title);
  }
  createDescription() {
    this.description = this.scene.add.text(LoreElementLayout.x, LoreElementLayout.y + 80, this.config.lore_text, {
      fontSize: '28px',
      fontFamily: 'Helvetica Neue',
      color: '#000000',
      wordWrap: {
        width: LoreElementLayout.width,
        useAdvancedWrap: false,
      },
    });
    this.description.setDepth(2);
    this.add(this.description);
  }
}
