import { LoadingStateManager } from '../manager/LoadingStateManager';
import JSZip from 'jszip';
import { HOUSE_TRAITS_CONFIG } from '../consts/HouseTraits';
import AwaitLoaderPlugin from 'phaser3-rex-plugins/plugins/awaitloader-plugin';
import base_house from '../assets/base_house.png';
import house_sky from '../assets/house_sky.png';
import stars from '../assets/stars.png';
import twitter_banner_base from '../assets/twitter_banner_base.png';
import candle_counter from '../assets/main_screen/candle_counter.png';
import empty_image from '../assets/empty_image.png';

import tree from '../assets/tree.png';
import tree_json from '../assets/tree.json';
import candle from '../assets/candle.png';
import candle_json from '../assets/candle.json';
import { House, HOUSE_PINATA_URL } from '../entities/House';

let house_attributes_config;
let haunt_count;
let candle_count;
let loader;
let customGame;
let gameCanvas;
let download_button;

const ATLAS = [
  {
    name: 'tree',
    image: tree,
    json: tree_json,
  },
  {
    name: 'candle',
    image: candle,
    json: candle_json,
  },
];
const load_assets = [
  {
    name: 'base_house',
    image: base_house,
  },
  {
    name: 'house_sky',
    image: house_sky,
  },
  {
    name: 'stars',
    image: stars,
  },
  {
    name: 'candle_counter',
    image: candle_counter,
  },
  {
    name: 'twitter_banner_base',
    image: twitter_banner_base,
  },
  {
    name: 'empty_image',
    image: empty_image,
  },
];
export class TwitterBannerDownload {
  /**
   *
   * @param {Phaser.Scene} scene
   */
  constructor(scene, x, y, config = { ...HOUSE_TRAITS_CONFIG }, haunt_count_param = 0, candle_count_param = 0) {
    this.scene = scene;
    this.x = x;
    this.y = y;
    house_attributes_config = config;
    haunt_count = haunt_count_param || 0;
    candle_count = candle_count_param || 0;
    this.createDownloadButton();
    loader = new LoadingStateManager(scene, false);
  }

  createDownloadButton() {
    download_button = this.scene.add
      .image(this.x, this.y, 'twitter_button')
      .setOrigin(0, 0)
      .setInteractive();
    download_button.on('pointerup', async () => {
      loader.setVisible(true);
      document.body.style.overflow = 'hidden';
      await this.createGame();
    });
    this.download_button = download_button;
  }

  createGame() {
    gameCanvas = document.createElement('canvas');
    gameCanvas.style.display = 'none';
    document.body.appendChild(gameCanvas);
    customGame = new Phaser.Game({
      canvas: gameCanvas,
      type: Phaser.WEBGL,
      scene: [DownloadScene],
      scale: {
        width: 3240,
        height: 1080,
      },
      pixelArt: false,
      fps: 60,
      plugins: {
        global: [
          {
            key: 'rexAwaitLoader',
            plugin: AwaitLoaderPlugin,
            start: true,
          },
        ],
      },
    });
  }

  toggleVisibility() {
    download_button.setVisible(!download_button.visible);
  }
}

export class DownloadScene extends Phaser.Scene {
  constructor() {
    super();
  }

  preload() {
    load_assets.forEach((values) => {
      this.load.image(values.name, values.image);
    });
    // Atlas
    ATLAS.forEach((value) => {
      this.load.atlas(value.name, value.image, value.json);
    });

    Object.keys(house_attributes_config).forEach((key) => {
      const attributeName = house_attributes_config[key];
      const keyValue = `${key}/${attributeName}`;
      this.load.image(
        keyValue,
        `${HOUSE_PINATA_URL}/${key
          .toLowerCase()
          .trim()
          .split(' ')
          .join('_')}/${attributeName}.png`
      );
    });
  }

  async create() {
    this.toggleInteractive(false);

    const zip = new JSZip();
    this.twitter_banner_base = this.add.image(0, 0, 'twitter_banner_base').setOrigin(0, 0);
    this.house = new House(this, 0, 0, house_attributes_config, false, haunt_count, candle_count);
    await this.house.createTraits(house_attributes_config);
    this.house.candle_counter.setVisible(false);
    this.rt = this.add.renderTexture(0, 0, this.twitter_banner_base.width, this.twitter_banner_base.height);
    this.rt.draw(this.house, 657, 0);
    this.rt.draw(this.twitter_banner_base);
    this.rt.snapshot(
      (snapshot) => {
        zip.file(`twitter_banner.png`, snapshot.src.split(',')[1], {
          base64: true,
        });
        zip.generateAsync({ type: 'base64' }).then((blob) => {
          var a = document.createElement('a'); //Create <a>
          a.href = 'data:application/zip;base64,' + blob; //Image Base64 Goes here
          a.download = 'twitter_banner.zip'; //File name Here
          a.click(); //Downloaded file
          a.remove();
          this.toggleInteractive(true);
          loader.setVisible(false);
          this.destroyAll();
        });
      },
      'image/png',
      1
    );
  }

  destroyAll() {
    this.rt.destroy();
    this.house.destroy();
    this.twitter_banner_base.destroy();
    customGame.destroy();
    gameCanvas.remove();
    document.body.style.overflow = null;
  }

  toggleInteractive(interactive) {
    download_button.setInteractive(interactive);
    if (interactive) download_button.setAlpha(1);
    else download_button.setAlpha(0.5);
  }
}
