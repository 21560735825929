export class TransactionGIF extends Phaser.GameObjects.Sprite {
  /**
   *
   * @param {Phaser.Scene} scene
   */
  constructor(scene) {
    super(scene, scene.cameras.main.width / 2, scene.cameras.main.height / 2, 'transaction_gif');
    this.play('transactionLoop');
    scene.add.existing(this);
  }
}
