import Phaser from 'phaser';

Phaser.GameObjects.GameObjectFactory.register('modalMessage', function(message, config, callback) {
  this.scene.scene.launch('MessageModalSubScene', { message, config, callback });
});

Phaser.GameObjects.GameObjectFactory.register('clickEffect', function(message, callback) {
  const animation = this.scene.add
    .sprite(this.scene.input.mousePointer.position.x, this.scene.input.mousePointer.position.y, 'click_effect')
    .setDepth(99999999999)
    .play('click_effect_animation')
    .setOrigin(0.8, 0.8)
    .once('animationcomplete-click_effect_animation', () => {
      animation.destroy();
    });
});
