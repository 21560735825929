export class CharacterSelectTab extends Phaser.GameObjects.Sprite {
  /**
   *
   * @param { Phaser.Scene } scene
   * @param { number} x
   * @param { number} y
   * @param { any } item
   */
  constructor(scene, x, y, item) {
    super(scene, x, y, item.key);
    this.scene = scene;
    this.scene.add.existing(this);
    this.item = item;
    this.setInteractive();
    this.setOrigin(0, 0);
  }
  onClick(callback) {
    this.on(
      'pointerup',
      () => {
        callback(this.item);
      },
      this
    );
  }
  setHighlight(highlight) {
    if (highlight) {
      this.setTexture(this.item.key1);
    } else {
      this.setTexture(this.item.key);
    }
  }
}
