import { MAILBOX_PANEL } from '../../layouts/MainScreen';
import { DataService } from '../../services/DataService';
import { Overlay } from '../Overlay';
import { ScrollablePanel } from '../ScrollablePanel';
import { MailBoxElement } from './MailBoxElement';

export class MailBoxPanel extends Phaser.Scene {
  constructor() {
    super({
      key: 'MailBoxPanel',
    });
    this.dataService = new DataService();
    this.messages = [];
  }

  create() {
    this.createCloseButton();
  }

  init({ messages }) {
    this.messages = messages;
  }

  async preload() {
    this.scrollablePanel = new ScrollablePanel(this);
    this.overlay = new Overlay(this);
    this.createPanel();
    this.createGrid();
  }

  createPanel() {
    let { x, y } = this.cameras.main.midPoint;
    this.add.image(x, y, 'mailbox_panel');
  }

  createGrid() {
    this.grid_container?.destroy();
    this.grid_container = this.add.container(0, 0);
    this.scrollablePanel.mouseOverScrollablePanel = true;
    if (this.messages && this.messages.length) {
      this.messages.forEach((message, index) => {
        const mail_box_element = new MailBoxElement(this, message, this.messages.length - index);
        mail_box_element.on('pointerup', (pointer) => {
          if (
            pointer.upTime - pointer.downTime < 300 &&
            this.scrollablePanel.overScrollablePanel() &&
            !this.scrollablePanel.isScrolling
          ) {
            mail_box_element.toggleBox();
            this.events.emit('mailbox_message_opened', this.messages);
          }
        });
        this.grid_container.add(mail_box_element);
      });

      Phaser.Actions.GridAlign(this.grid_container.list, {
        x: MAILBOX_PANEL.MAIL_BOX_GRID.x + MAILBOX_PANEL.MAIL_BOX_GRID.width / 2,
        y: MAILBOX_PANEL.MAIL_BOX_GRID.y + MAILBOX_PANEL.MAIL_BOX_GRID.height / 2,
        width: MAILBOX_PANEL.MAIL_BOX_GRID.columns,
        height: this.messages.length,
        cellWidth: MAILBOX_PANEL.MAIL_BOX_GRID.width,
        cellHeight: MAILBOX_PANEL.MAIL_BOX_GRID.height,
      }).reverse();
      const { height } = this.grid_container.getBounds();
      if (height > MAILBOX_PANEL.SCROLL_PANEL.height) {
        this.scrollablePanel.setPositions(
          MAILBOX_PANEL.SCROLL_PANEL.x,
          MAILBOX_PANEL.SCROLL_PANEL.y,
          MAILBOX_PANEL.SCROLL_PANEL.width,
          MAILBOX_PANEL.SCROLL_PANEL.height,
          height + MAILBOX_PANEL.MAIL_BOX_GRID.height / 2
        );
        this.scrollablePanel.createScrollSection(this.grid_container);
        this.scrollablePanel.setPreviousPosition();
      }
    }
  }

  createCloseButton() {
    let { x, y, width, height } = MAILBOX_PANEL.CLOSE_BUTTON;
    this.close_button = this.add
      .zone(x, y, width, height)
      .setInteractive()
      .setOrigin(0, 0);
    this.close_button.on('pointerup', () => {
      this.scene.stop();
    });
    // this.debugGraphics = this.add.graphics();
    // this.debugGraphics.fillStyle(0xffff00, 0.7);
    // this.debugGraphics.fillRectShape(this.close_button.getBounds());
  }
}
