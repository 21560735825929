import { CandleCountAnimationConfig } from './CandleCountAnimationConfig';
import { ClickEffectAnimationConfig } from './ClickEffectAnimationConfig';
import { LoadingAnimationConfig } from './LoadingAnimationConfig';
import { LootboxAnimationConfig } from './LootboxAnimationConfig';
import { LootboxButtonAnimationConfig } from './LootboxButtonAnimationConfig';
import { TransactionGIFAnimationConfig } from './TransactionGIFAnimationConfig';

export const AnimationConfig = [
  LoadingAnimationConfig,
  LootboxAnimationConfig,
  ClickEffectAnimationConfig,
  TransactionGIFAnimationConfig,
  LootboxButtonAnimationConfig,
  CandleCountAnimationConfig,
];
