import { GAME_MESSAGES } from '../../../../consts/Messages';
import { DaysLeftComponent } from '../../components/DaysLeftComponent';
import { TwitterBannerDownload } from '../../components/TwitterBannerDownload';
import { SoundManager } from '../../manager/SoundManager';
import { DataService } from '../../services/DataService';

export class MainScreenMenus extends Phaser.Scene {
  constructor() {
    super({ key: 'MainScreenMenus' });
    this.dataService = new DataService();
  }

  preload() {
    this.load.rexAwait(async (successCallback, failureCallback) => {
      await this.dataService.getCurrentGameDay();
      successCallback();
    });
  }

  create() {
    this.sound.volume = 0.3;
    this.sound.pauseOnBlur = false;
    if ((this.dataService && !this.dataService.main_song) || !this.dataService.main_song.isPlaying) {
      this.main_song = this.sound.add('main_song', {
        loop: true,
      });
      this.main_song.play();

      this.dataService.main_song = this.main_song;
    }
    this.mute_unmute = this.add
      .image(1505, 955, this.sound.mute ? 'unmute' : 'mute')
      .setOrigin(0, 0)
      .setInteractive()
      .setDepth(3);
    this.mute_unmute.on('pointerdown', () => {
      if (!this.sound.mute) {
        this.sound.setMute(true);
        this.mute_unmute.setTexture('unmute');
      } else {
        this.mute_unmute.setTexture('mute');
        this.sound.setMute(false);
      }
    });
    this.hide_menu = this.add
      .image(1700, 978, 'hide_menu')
      .setOrigin(0, 0)
      .setDepth(30)
      .setScale(0.8);
    this.hide_menu_click_zone = this.add
      .zone(1705, 990, 203, 48)
      .setOrigin(0, 0)
      .setInteractive();

    this.share_button = this.add
      .image(1134, 36, 'share_button')
      .setOrigin(0, 0)
      .setDisplaySize(50, 50)
      .setInteractive()
      .setDepth(30);
    this.share_button.on('pointerdown', () => {
      navigator.clipboard.writeText(location.href);
      this.add.modalMessage(GAME_MESSAGES.SHARE_URL, {
        big: true,
      });
    });
    this.days_left_component?.destroy();
    this.days_left_component = new DaysLeftComponent(this);

    this.twitter_banner_download = new TwitterBannerDownload(
      this,
      1120,
      960,
      this.dataService.active_traits,
      this.dataService.hauntedCounter,
      this.dataService.candle_count
    );

    this.hide_menu_click_zone.on('pointerdown', () => {
      SoundManager.clickScreenSound(this);
      this.share_button.setVisible(!this.share_button.visible);
      this.days_left_component.setVisible(!this.days_left_component.visible);
      this.scene.get('MainScreenScene').toggleMenus();
      this.mute_unmute.setVisible(!this.mute_unmute.visible);
      this.twitter_banner_download.toggleVisibility();
    });
  }
}
