import { LoadingStateManager } from '../../manager/LoadingStateManager';
import { SoundManager } from '../../manager/SoundManager';
import { DataService } from '../../services/DataService';
import { DebugUtils } from '../../Utils/DebugUtils';
import { Overlay } from '../Overlay';
import { ScrollablePanel } from '../ScrollablePanel';
import { LoreElement } from './LoreElement';

const CLOSE_BUTTON = {
  x: 1470,
  y: 212,
  origin: {
    x: 0,
    y: 0,
  },
  height: 85,
  width: 85,
};

const SCROLL_UP = {
  x: 1473,
  y: 287,
  width: 61,
  height: 52,
};
const SCROLL_DOWN = {
  x: 1473,
  y: 826,
  width: 61,
  height: 52,
};

const ScrollPanelPosition = {
  x: 625,
  y: 273,
  width: 870,
  height: 610,
};

export class LorePanel extends Phaser.Scene {
  constructor() {
    super({
      key: 'LorePanel',
    });
  }

  preload() {
    this.dataService = new DataService();
    this.loader = new LoadingStateManager(this);
    this.load.rexAwait(async (successCallback, failureCallback) => {
      await this.getData();

      if (this.textures.exists('lore_image_metadata')) successCallback();
      else
        this.load.once(`filecomplete-image-lore_image_metadata`, () => {
          this.loader.setVisible(false);
          successCallback();
        });
    });
  }

  async getData() {
    this.lore = null;
    this.interacted_contract = await this.dataService.interactedNFTToOwner();
    if (this.interacted_contract) {
      this.lore = await this.dataService.getDailyAsks();
      if (this.lore) {
        this.load.image('lore_image_metadata', this.lore.image_url);
        this.load.start();
      } else {
        this.loader.setVisible(false);
      }
    } else {
      this.loader.setVisible(false);
    }
  }

  create() {
    if (this.interacted_contract && this.lore) {
      this.scrollable = new ScrollablePanel(this);
      this.overlay = new Overlay(this, true);
      this.createPanel();
      this.createCloseButton();
      this.createLore();
      this.createImage();
    } else {
      this.scene.stop();
    }
  }

  createPanel() {
    this.panel = this.add.image(594, 147, 'lores_panel').setOrigin(0, 0);
  }

  createCloseButton() {
    const { x, y, width, height, origin } = CLOSE_BUTTON;
    this.close_button = this.add
      .zone(x, y, width, height)
      .setOrigin(origin.x, origin.y)
      .setInteractive();
    this.close_button.on('pointerup', async () => {
      SoundManager.clickScreenSound(this);
      await this.closeLore();
    });
    DebugUtils.debug(this, this.close_button);
  }
  async closeLore() {
    console.log('CLOSE LORE');
    const updated = await this.dataService.updateClickedLore(this.lore.id);
    if (updated) this.scene.stop();
    this.loader.setVisible(false);
  }

  createImage() {
    this.image = this.add
      .image(200, 269, 'lore_image_metadata')
      .setDisplaySize(381, 384)
      .setOrigin(0, 0);
    this.frame = this.add.image(181, 252, 'lore_frame').setOrigin(0, 0);
    this.image.setDepth(1);
  }

  createLore() {
    const item = new LoreElement(this, this.lore);
    const { height } = item.getBounds();

    this.scrollable.setPositions(
      ScrollPanelPosition.x,
      ScrollPanelPosition.y,
      ScrollPanelPosition.width,
      ScrollPanelPosition.height,
      height + 30
    );
    this.scrollable.createScrollSection(item);
    this.scrollable.createArrowControl({
      up: SCROLL_UP,
      down: SCROLL_DOWN,
      scrollFactor: 0.3,
      duration: 500,
    });
  }
}
