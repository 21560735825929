export const OVERLAY_OPACITY = 0.7;
export const OVERLAY_COLOR = 0x000000;

export class Overlay extends Phaser.GameObjects.RenderTexture {
  /**
   *
   * @param {Phaser.Scene} scene - The scene that owns this Overlay.
   * @param { boolean } interactive Should the overlay be interactive?
   */
  constructor(scene, interactive = true) {
    super(scene, 0, 0, scene.game.config.width, scene.game.config.height);
    this.fill(OVERLAY_COLOR, OVERLAY_OPACITY);
    if (interactive) this.setInteractive();
    scene.add.existing(this);
  }
}
