import { SHOP_GRID_LAYOUT } from '../layouts/ShopScreen';
import { CurrencyComponent } from './CurrencyComponent';

export class ShopShelveFrame extends Phaser.GameObjects.Container {
  constructor(scene, frame, price) {
    super(scene, 0, 0);
    /**
     * @type {Phaser.Scene}
     */
    this.scene = scene;

    this.frame = this.scene.add
      .image(0, 0, `shop_frames`)
      .setOrigin(0, 0)
      .setFrame(frame);
    this.text = new CurrencyComponent(
      this.scene,
      SHOP_GRID_LAYOUT.FRAME.TEXT.x,
      SHOP_GRID_LAYOUT.FRAME.TEXT.y,
      price,
      '42px'
    );

    const { height, width } = SHOP_GRID_LAYOUT.FRAME;
    this.setInteractive(new Phaser.Geom.Rectangle(0, 0, width, height), Phaser.Geom.Rectangle.Contains);
    this.add([this.frame, this.text]);
  }
}
