import { GameObjects } from 'phaser';
import { LENGTH_TEXT } from '../scenes/sub/HauntMessageBoxSubScene';

export class InputText {
  constructor(scene) {
    /**
     * @type {Phaser.Scene}
     */
    this.scene = scene;
    /**
     * HTML text Input.
     * @type {HTMLInputElement}
     */
    this.input;
    /**
     * On Input is submited
     * @type {Function}
     */
    this.submitCallback;
    /**
     * On user request focus on text input
     * @type {Function}
     */
    this.focusCallback;
    /**
     * Text gameObject that HTML text input will reflect to.
     * @type {Phaser.GameObjects.Text}
     */
    this.textGameObject;
    /**
     * Max size of the chars in input.
     * @type {number}
     */
    this.maxLength = Infinity;
    this.create();
    this.keyDownHelper = (event) => this.dispatchPageKeyDown(event);
    // we need to use that cause Phaser is overriding any other event from the DOM, that is bad! >:(
    document.addEventListener('keyup', this.keyDownHelper);
    scene.events.on('destroy', this.destroy, this);
  }

  create() {
    /**
     * @type {HTMLInputElement}
     */
    const input = document.createElement('textarea');
    input.setAttribute('type', 'text');
    input.setAttribute('id', 'messageBoxInput');
    input.setAttribute('maxlength', LENGTH_TEXT.max_length);
    const margin_top = parseInt(this.scene.sys.canvas.style.marginTop.replace('px', ''));
    const margin_bottom = parseInt(this.scene.sys.canvas.style.marginBottom.replace('px', ''));
    // ok, we need that 'gambiarra' to make the input 'invisible' to user
    // (display none and visibility hidden not worked right there)
    // input.style.left = 796 / (window.innerWidth / this.scene.scale.displaySize.width) + 'px';
    // input.style.top = 369 / (window.innerHeight / this.scene.scale.displaySize.height) - margin_top + 'px';
    input.style.left = '-999px';
    input.style.top = '-999px';
    // input.style.height = '200px';
    // input.style.width = '331px';
    input.style.fontFamily = 'BlackBeard';
    input.style.fontSize = '22px';
    input.style.position = 'absolute';
    input.addEventListener('keyup', this.dispatchKeyDown.bind(this));
    document.body.appendChild(input);
    this.input = input;
  }

  destroy() {
    document.removeEventListener('keyup', this.keyDownHelper);
    this.input.parentNode.removeChild(this.input);
  }

  dispatchKeyDown(event) {
    if (this.textGameObject.text.length >= this.maxLength && event.key !== 'Backspace') return console.log('maxLength');
    if (event.key === 'Enter') {
      if (this.input.value.trim() === '') {
        this.input.blur();
        return;
      } else {
        this.submitCallback(this.input.value);
        this.input.value = '';
      }
    }
    this.textGameObject.visible = true;
    this.textGameObject.setText(this.input.value);
    this.scene.length_text?.setText(`Length: ${this.textGameObject?.text?.length || 0} of ${LENGTH_TEXT.max_length}`);
  }

  dispatchPageKeyDown(event) {
    if (event.key === 'Enter') {
      if (this.input.value.trim() === '') {
        this.input.focus();
        this.onRequestFocus();
        return;
      } else {
        this.submitCallback(this.input.value);
        this.input.value = '';
      }
    }
  }

  focus() {
    this.input.focus();
  }

  onSubmit(callback) {
    this.submitCallback = callback;
  }

  onRequestFocus(callback) {
    this.focusCallback = callback;
  }

  get value() {
    return this.input.value;
  }

  setTextGameObject(gameObject) {
    this.textGameObject = gameObject;
  }

  setMaxLength(length) {
    this.maxLength = length;
  }
}
