import { PROJECTS } from '../../../../consts/Projects';
import { PlayerEntity } from '../../../../phaser/entities/PlayerEntity';
import { Web3GameService } from '../../../../services/Web3GameService';
import CharacterSelectLayout from '../../layouts/CharacterSelectLayout';
import Layout from '../../layouts/CharacterSelectLayout';
import { DataService } from '../../services/DataService';
import { GameUtils } from '../../Utils/GameUtils';
import { ScrollablePanel } from '../ScrollablePanel';
import { CharacterFrame } from './CharacterFrame';
import { CharacterPicture } from './CharacterPicture';
import { CharacterSelectTab } from './CharacterSelectTab';

import { ERROR_MESSAGES } from '../../../../consts/Messages';
import { SoundManager } from '../../manager/SoundManager';

const TAB_HIGHLIGHT = Layout.TAB_HIGHLIGHT;

export class CharacterSelectionGrid {
  /**
   *
   * @param {Phaser.Scene} scene
   */
  constructor(scene, nft_payload) {
    this.columns = Layout.CHARACTER_GRID.COLUMNS;
    /**
     * @type {Phaser.Scene}
     */
    this.scene = scene;

    this.gridHeight = 0;
    this.rows = 0;

    this.web3GameService = new Web3GameService();
    this.dataService = new DataService();
    this.getConfig();
    /**
     * @type { Phaser.GameObjects.Container }
     */
    this.container = null;

    this.iterable_nfts = Object.keys(PROJECTS);
    this.iterable_nfts.forEach((key) => {
      const projectKey = PROJECTS[key].key;
      this[projectKey] = nft_payload[projectKey];
    });
    this.currentContractAddress = PROJECTS.ONIS.contract_address;

    this.currentItems = [];
    this.checkCurrent();
    this.getRows();
    this.tabs = [];

    this.scrollable_panel = new ScrollablePanel(scene);

    this.pointerControl = false;
    this.createGrid();
    this.checkHightlight();
    this.checkNFTOwnershipButton();
  }

  async getConfig() {
    await this.web3GameService.getConfig();
  }

  checkNFTOwnershipButton() {
    this.btn_check_nft = this.scene.make.image(CharacterSelectLayout.CHECK_NFT_OWNERSHIP).setInteractive();
    this.btn_check_nft.on('pointerup', async () => {
      SoundManager.clickScreenSound(this.scene);
      const owned_address = prompt('Please enter your NFT ID');
      const owns_nft = await this.web3GameService.mappedNFTToOwner(this.currentContractAddress, owned_address);
      console.log(owns_nft);
      if (owns_nft === this.web3GameService.account) {
        console.log('You own this NFT');
        try {
          const response = await this.dataService.setNFTOwnership(
            owned_address,
            this.currentContractAddress,
            this.web3GameService.account
          );
          if (response.done) {
            this.scene.events.emit('reload_selection_panel');
          }
        } catch (err) {
          this.scene.add.modalMessage(ERROR_MESSAGES.UNKOWN_ERROR);
        }
      } else {
        this.scene.add.modalMessage(ERROR_MESSAGES.NOT_YOUR_NFT);
      }
    });
  }

  checkCurrent() {
    for (let key in PROJECTS) {
      const projectKey = PROJECTS[key].key;
      const { contract_address } = PROJECTS[key];
      if (this[projectKey] && this[projectKey].length > 0) {
        this.setCurrentItems(this[projectKey]);
        this.currentContractAddress = contract_address;
        break;
      }
    }
  }

  getRows() {
    if (this.currentItems && this.currentItems.length > 0)
      this.rows = Math.ceil(this.currentItems.length / this.columns);
  }

  checkHightlight() {
    for (let key in PROJECTS) {
      const projectKey = PROJECTS[key].key;
      if (this[projectKey] && this[projectKey].length > 0) {
        this.setTabHighlight(projectKey);
        break;
      }
    }

    if (!this.currentItems || this.currentItems.length === 0) {
      this.setTabHighlight('first', true);
    }
  }

  setCurrentItems(items) {
    this.currentItems = items;
  }

  setTabHighlight(rule, first = false) {
    this.tabs.forEach((t) => {
      if (first) {
        t.setHighlight(true);
        first = false;
      } else t.setHighlight(t.item.type === rule);
    });
  }

  createButtons() {
    const { x, y } = Layout.TABS;
    const { v } = Layout.TABS.margin;
    const { items } = Layout.TABS;
    this.tabs.forEach((t) => t.destroy());
    this.tabs = [];
    items.forEach((item, index) => {
      const tab = new CharacterSelectTab(this.scene, x, y + index * v, item);
      tab.onClick((payload) => {
        SoundManager.clickScreenSound(this.scene);
        this.removeSelections();
        // console.log(this[payload.type]);
        this.setCurrentItems(this[payload.type]);
        this.getRows();
        this.createGrid();
        this.setTabHighlight(payload.type);
        this.currentContractAddress = payload.raw_data.contract_address;
      });
      this.tabs.push(tab);
    });
  }

  createGrid() {
    if (this.container) this.container.destroy();
    this.container = this.scene.add.container(0, 0);
    this.scene.add.existing(this.container);
    this.scrollable_panel.resetPanel();
    const { v } = Layout.CHARACTER_GRID.margin;
    const { height } = Layout.CHARACTER_GRID;
    this.gridHeight = this.rows * (v * Layout.CHARACTER_GRID.extraSpaceMultiplier) + this.rows * height;
    let counter = 0;
    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.columns; j++) {
        if (this.currentItems && this.currentItems[counter]) {
          const currentItem = this.currentItems[counter];
          counter++;
          if (currentItem) {
            const entity = new CharacterPicture(this.scene, i, j, currentItem.name);
            if (this.selectedCharacter && this.selectedCharacter.name === currentItem.name) {
              entity.gridFrame.setSelection(true);
            }
            entity.on('pointerup', (p) => {
              this.pointerControl = p.upTime - p.downTime < 300;
              SoundManager.clickScreenSound(this.scene);
              if (
                this.pointerControl &&
                this.scrollable_panel.overScrollablePanel() &&
                !this.scrollable_panel.isScrolling
              ) {
                this.removeSelections();
                entity.gridFrame.setSelection(true);
                this.scene.events.emit('character_selected', {
                  selected_nft: currentItem,
                  contract_address: this.currentContractAddress,
                });
                this.selectedCharacter = currentItem;
              }
            });
            this.container.add([entity, entity.gridFrame]);
          }
        }
      }
    }
    this.createButtons();
    this.createScrollablePanel();
  }

  removeSelections() {
    this.container.list.forEach((entity) => {
      if (entity.gridFrame) {
        entity.gridFrame.setSelection(false);
      }
    });
    this.scene.events.emit('clear_selection');
  }

  createScrollablePanel() {
    const { x, y } = Layout.SCROLLABLE_PANEL;
    const { width, height } = Layout.SCROLLABLE_PANEL;
    const padding = Layout.SCROLLABLE_PANEL.padding;

    if (this.gridHeight > height) {
      this.scrollable_panel.setPositions(x, y + padding, width, height - padding * 2, this.gridHeight);
      this.scrollable_panel.createScrollSection(this.container);
    } else {
      this.scrollable_panel.scrollZone?.destroy();
    }
  }
}
