import Phaser from 'phaser';
import { LOOTBOX } from '../layouts/ShopScreen';
import { DataService } from '../services/DataService';
import { Overlay } from './Overlay';
import lodash from 'lodash';
import { GAME_MESSAGES } from '../../../consts/Messages';
import { LoadingStateManager } from '../manager/LoadingStateManager';
import { SoundManager } from '../manager/SoundManager';
import { TransactionWaitingGif } from '../manager/TransactionWaitingGif';
import { LootboxProgressBar } from './LootboxProgressbar';
const MIN_MISSION_COUNT = 14;

export class LootboxWindow extends Phaser.Scene {
  constructor() {
    super({
      key: 'LootboxWindow',
    });
    this.dataService = new DataService();
  }

  preload() {
    this.loader = new LoadingStateManager(this);
    this.transactionGif = new TransactionWaitingGif(this, false);
    this.load.rexAwait(async (successCallback, failureCallback) => {
      const owned_ids = await this.dataService.getHouseAttributes();
      this.mission_counter = parseInt(await this.dataService.getMissionCounter());
      this.item_counter = owned_ids.filter((id) => id >= 10000000).length;
      this.loader.setVisible(false);
      this.input.mouse.enabled = true;
      successCallback();
    });
  }

  create() {
    this.overlay = new Overlay(this);
    this.window = this.make.image(LOOTBOX.LOOTBOX_WINDOW);
    this.createButtons();
    this.createActions();
    new LootboxProgressBar(this, this.mission_counter, this.item_counter);
  }

  createButtons() {
    Object.keys(LOOTBOX.BUTTONS).forEach((master_key) => {
      let { x, y, width, height, key } = LOOTBOX.BUTTONS[master_key];
      this[key] = this.add
        .zone(x, y, width, height)
        .setOrigin(0, 0)
        .setInteractive();
    });
  }

  async handleLootbox(type = 'openLootbox') {
    this.transactionGif.setVisible(true);
    await this.dataService.getHouseAttributes();
    const before_items = lodash.cloneDeep(this.dataService.owned_traits_ids);
    // const before_items = [];
    // console.log('BEFORE', before_items);
    const response = await this.dataService[type]();
    // console.log(response);
    if (response && response.code === 4001) {
      this.transactionGif.setVisible(false);
      this.add.modalMessage(GAME_MESSAGES.USER_REJECTED_TX);
    } else if (response) {
      this.dataService.should_reload_data = true;
      const after_items = await this.dataService.getHouseAttributes();
      // console.log('AFTER', after_items);
      const difference = lodash.difference(after_items, before_items);
      const { data } = await this.dataService.getHouseAttributeDescription(difference[0]);
      // console.log('DIFFERENCE', difference[0]);
      const apiCreation = await this.dataService.addHouseAttributes(data);
      // console.log(apiCreation);
      if (!apiCreation?.code) {
        this.dataService.should_reload_data = true;
        this.scene.launch('OpenLootbox', { data });
      } else {
        this.add.modalMessage(apiCreation.message);
      }
    }
    this.transactionGif.setVisible(false);
  }

  createActions() {
    this[LOOTBOX.BUTTONS.CLOSE_BUTTON.key].on('pointerup', () => {
      SoundManager.clickScreenSound(this);
      this.scene.stop('LootboxWindow');
    });
    this[LOOTBOX.BUTTONS.OPEN_BUTTON.key].on('pointerup', async () => {
      // console.log('Mission COUNTER', this.dataService.mission_counter);
      SoundManager.clickScreenSound(this);

      if (parseInt(MIN_MISSION_COUNT) <= parseInt(this.dataService.mission_counter)) {
        await this.handleLootbox();
      }
    });

    // this.debugGraphics = this.add.graphics();
    // this.debugGraphics.fillStyle(0xffff00, 0.8);
    // this.debugGraphics.fillRectShape(this[LOOTBOX.BUTTONS.CLOSE_BUTTON.key].getBounds());
    // this.debugGraphics.fillRectShape(this[LOOTBOX.BUTTONS.OPEN_BUTTON.key].getBounds());
    // this.debugGraphics.fillRectShape(this[LOOTBOX.BUTTONS.BUY_BUTTON.key].getBounds());
  }
}
