import { HoverComponent, HOVER_COMPONENT_ORIGIN } from './HoverComponent';

export class InfoIconComponent extends Phaser.GameObjects.Container {
  /**
   * @param { Phaser.Scene} scene
   * @param { string } text for the Hover component.
   * @param { HOVER_COMPONENT_ORIGIN } origin origin of the Hover component.
   */
  constructor(scene, x, y, text, origin = HOVER_COMPONENT_ORIGIN.BOTTOM_RIGHT) {
    super(scene, x, y);
    const INFO = {
      id: 'info_button',
      key: 'info_icon',
      x: 0,
      y: 0,
      scale: 0.2,
      origin: {
        x: 0.5,
        y: 0.5,
      },
    };
    this.info_button_sprite = scene.make.sprite(INFO).setInteractive();
    const { width, height, scale } = this.info_button_sprite;
    this.info_button_sprite
      .on('pointerover', () => {
        this.hover_component = new HoverComponent(scene, (width * scale) / 2.3, (height * scale) / 2.3, text, origin);
        this.add(this.hover_component);
      })
      .on('pointerout', () => {
        this.hover_component?.destroy();
      });
    this.add(this.info_button_sprite);
    scene.add.existing(this);
  }
}
