export const HOVER_COMPONENT_ORIGIN = {
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
};

export class HoverComponent extends Phaser.GameObjects.Container {
  /**
   *
   * @param {Phaser.Scene} scene
   * @param { number } x
   * @param { number} y
   * @param {string} text
   * @param {string} origin
   */
  constructor(scene, xContainer, yContainer, text, origin = HOVER_COMPONENT_ORIGIN.BOTTOM_RIGHT) {
    super(scene, xContainer, yContainer);
    this.box = scene.add.image(0, 0, 'mail_box_text_box_opened');

    scene.add.existing(this);
    const { width, height } = this.box;
    this.text_element = scene.add
      .text(width / 2, height / 2, text, {
        fontFamily: 'BlackBeard',
        color: '#000000',
        fontSize: '32px',
        wordWrap: {
          width: width - 40,
        },
        align: 'center',
        wordWrapUseAdvanced: false,
      })
      .setDepth(22)
      .setOrigin(0.5, 0.5);

    this.checkOrigin(origin);
    this.add([this.box, this.text_element]);
  }

  checkOrigin(origin) {
    const { width, height } = this.box;
    if (origin === HOVER_COMPONENT_ORIGIN.BOTTOM_RIGHT) {
      this.box.setOrigin(0, 0);
    } else if (origin === HOVER_COMPONENT_ORIGIN.BOTTOM_LEFT) {
      this.box.setOrigin(1, 0);
      this.text_element.setPosition(-width / 2, height / 2);
    } else if (origin === HOVER_COMPONENT_ORIGIN.TOP_RIGHT) {
      this.box.setOrigin(0, 1);
      this.text_element.setPosition(width / 2, -height / 2);
    } else if (origin === HOVER_COMPONENT_ORIGIN.TOP_LEFT) {
      this.box.setOrigin(1, 1);
      this.text_element.setPosition(-width / 2, -height / 2);
    }
  }
}
