import Phaser from 'phaser';

import { GameUtils } from '../../Utils/GameUtils';
import { IMAGES, BUTTONS } from '../../layouts/Haunt';
import { buttonBehaviors } from '../../services/HauntButtonsBehaviorService';

export class HauntMenuSubScene extends Phaser.Scene {
  constructor () {
    super({ key: 'HauntMenuSubScene' });
  }

  init (params) {
    this.params = params;
  }

  create () {
    this.createBase();
  }

  createBase () {
    this.make.sprite(IMAGES.HAUNT_MENU_BACKGROUND);
    this.addButtons();
  }

  addButtons () {
    GameUtils.addButtonsGroup(this, BUTTONS[this.scene.key], buttonBehaviors);
  }
};