import { GameUtils } from '../Utils/GameUtils';

const LAYOUT_CONFIG = {
  x: 150,
  y: 150,
  key: 'back_button',
  depth: 50,
  origin: {
    x: 0,
    y: 0,
  },
};

export class AddGameChainComponent extends Phaser.GameObjects.Sprite {
  /**
   * Component responsible for adding the game Chain programmatically to the Metamask.
   * @param {Phaser.Scene} scene
   */
  constructor(scene) {
    const { x, y, key, depth } = LAYOUT_CONFIG;
    super(scene, x, y, key);
    this.setInteractive();
    this.setDepth(depth);
    this.on('pointerup', async (pointer) => {
      await this.addGameChain();
    });
    scene.add.existing(this);
  }
  async addGameChain() {
    GameUtils.switchNetworks();
  }
}
