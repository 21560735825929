const BAR_CONFIG = {
  x: 940,
  y: 555,
  origin: {
    x: 0,
    y: 0,
  },
  depth: 50,
};

const FACTOR = 21;

export class LootboxProgressBar extends Phaser.GameObjects.Container {
  /**
   *
   * @param { Phaser.Scene } scene
   */
  constructor(scene, mission_count, lootbox_item_count = 0) {
    const { x, y, depth } = BAR_CONFIG;
    super(scene, x, y);
    this.scene = scene;
    this.lootbox_item_count = lootbox_item_count;
    this.mission_count = mission_count;
    this.max_mission_count = 84;
    this.setDepth(depth);
    this.createBackground();
    this.createProgress();
    this.createGoals();
    this.createBoxes();
    this.createTexts();
    scene.add.existing(this);
  }

  createBackground() {
    this.background = this.scene.add
      .image(0, 0, 'lootbox_progress_bar_box')
      .setOrigin(0, 0)
      .setDepth(50);
    this.add(this.background);
  }

  createProgress() {
    this.progress = this.scene.add
      .image(15, 13, 'lootbox_progress_bar_progress')
      .setOrigin(0, 0)
      .setDepth(51);
    const full_size = this.progress.width;
    const progress_size = Math.min((this.mission_count / this.max_mission_count) * full_size, full_size);
    this.progress.setDisplaySize(progress_size, this.progress.height);
    this.add(this.progress);
  }

  createGoals() {
    this.progress_bar_goals = this.scene.add
      .image(142, -4, 'progress_bar_goals')
      .setDepth(51)
      .setOrigin(0, 0);
    this.add(this.progress_bar_goals);
  }

  createBoxes() {
    this.boxes = this.scene.add
      .image(50, 33, 'lootbox_progress_bar')
      .setDepth(52)
      .setOrigin(0, 0);
    const frame = `stage0${Math.min(this.lootbox_item_count, 4)}`;
    if (this.lootbox_item_count > 0) this.boxes.setFrame(frame);
    this.add(this.boxes);
  }

  createTexts() {
    this.progress_text?.destroy();
    this.progress_text = this.scene.add.container(0, 0);
    for (let i = 1; i <= 4; i++) {
      const count = (this.mission_count > FACTOR ? FACTOR : this.mission_count) | 0;
      const text = this.scene.add
        .text(0, 0, `${count}/${FACTOR}`, {
          fontFamily: 'BlackBeard',
          fontSize: '42px',
          color: '#ffffff',
        })
        .setDepth(55)
        .setOrigin(0, 0);
      this.progress_text.add(text);
      this.mission_count = Math.max(this.mission_count - FACTOR, 0);
    }
    Phaser.Actions.GridAlign(this.progress_text.list, {
      x: 128,
      y: 99,
      width: 4,
      height: 1,
      cellWidth: 147,
      cellHeight: 35,
    }).reverse();
    this.add(this.progress_text);
  }
}
