import Layout from '../../layouts/CharacterSelectLayout';

export class CharacterFrame extends Phaser.GameObjects.Sprite {
  /**
   *
   * @param { Phaser.Scene } scene
   */
  constructor(scene, i, j) {
    const { x, y } = Layout.CHARACTER_GRID_FRAME;
    const originX = Layout.CHARACTER_GRID_FRAME.origin.x;
    const originY = Layout.CHARACTER_GRID_FRAME.origin.y;
    const { width, height } = Layout.CHARACTER_GRID_FRAME.size;
    const { h, v } = Layout.CHARACTER_GRID_FRAME.margin;
    const texture = Layout.CHARACTER_GRID_FRAME.key0;
    super(scene, x + j * width + j * h, y + i * height + i * v, texture);
    this.scene = scene;
    this.setOrigin(originX, originY);
    this.scene.add.existing(this);
  }

  setSelection(selected) {
    const texture = selected ? Layout.CHARACTER_GRID_FRAME.key1 : Layout.CHARACTER_GRID_FRAME.key0;
    this.setTexture(texture);
  }
}
