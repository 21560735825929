// Shop
import background_shop from '../assets/shop/background_shop.png';
import background_stuffs_overlay from '../assets/shop/background_stuffs_overlay.png';
import jack_the_seller from '../assets/shop/jack_the_seller.png';
import shop_shelves from '../assets/shop/shop_shelves.png';
import shop_sign from '../assets/shop/shop_sign.png';
import back_button from '../assets/shop/back_button.png';
import previous_button from '../assets/shop/previous_button.png';
import next_button from '../assets/shop/next_button.png';
import wallet from '../assets/shop/wallet.png';
import shop_name from '../assets/shop/shop_name.png';
import shop_item from '../assets/shop/shop_item.png';
import purchase_preview_frame from '../assets/shop/purchase_preview_frame.png';
import close_button_shop from '../assets/shop/close_button_shop.png';
import purchase_value_card from '../assets/shop/purchase_value_card.png';
import lootbox_toggle from '../assets/shop/lootbox/lootbox_toggle.png';
import lootbox_window from '../assets/shop/lootbox/lootbox_window.png';
import lootbox_progress_bar_box from '../assets/shop/lootbox/progress_bar/progress_bar_box.png';
import progress_bar_goals from '../assets/shop/lootbox/progress_bar/progress_bar_goals.png';
import lootbox_progress_bar_progress from '../assets/shop/lootbox/progress_bar/progress_bar_progress.png';
import lootbox_preview_frame from '../assets/shop/lootbox/lootbox_preview_frame.png';

import twitter_banner_base from '../assets/twitter_banner_base.png';
import twitter_button from '../assets/twitter_button.png';
import base_house from '../assets/base_house.png';
import base_house_grayscale from '../assets/base_house_grayscale.png';
import house_sky from '../assets/house_sky.png';
import stars from '../assets/stars.png';
import oni from '../assets/oni.png';
import hide_menu from '../assets/main_screen/hide_menu.png';
import main_menu from '../assets/main_screen/main_menu.png';
import candle_counter from '../assets/main_screen/candle_counter.png';
import logo from '../assets/main_screen/logo.png';
import customization_panel from '../assets/main_screen/customization_panel.png';
import logout from '../assets/main_screen/logout.png';
import wallet_panel from '../assets/main_screen/wallet.png';
import share_button from '../assets/main_screen/share_button.png';
import mute from '../assets/misc_ui/mute.png';
import unmute from '../assets/misc_ui/unmute.png';
import landscape_mobile from '../assets/misc_ui/landscape_mobile.png';
import lores_panel from '../assets/main_screen/lores/lores_panel.png';
import lores_text_box from '../assets/main_screen/lores/lores_text_box.png';
import lore_frame from '../assets/main_screen/lores/lore_frame.png';

// Mission Panel
import mission_panel from '../assets/mission_panel/panel.png';
import mission_info_grid_claim from '../assets/mission_panel/mission_info_grid_claim.png';
import mission_info_grid_claim_clear from '../assets/mission_panel/mission_info_grid_claim_clear.png';
import mission_info_grid_no_claim from '../assets/mission_panel/mission_info_grid_no_claim.png';
import mission_name_panel from '../assets/mission_panel/mission_name_panel.png';
import mission_price_panel from '../assets/mission_panel/mission_price_panel.png';
import mission_claim_panel from '../assets/mission_panel/mission_claim_panel.png';
import close_button from '../assets/mission_panel/close_button.png';

// Haunt Scene
import haunt_background from '../assets/haunt_scene/background.png';
import haunt_messagebox_menu from '../assets/haunt_scene/haunt_messagebox_menu.png';
import haunt_message_box_frame from '../assets/haunt_scene/haunt_message_box_frame.png';
import haunt_nft_list_menu from '../assets/haunt_scene/haunt_oni_list.png';
import nft_list_element_frame from '../assets/haunt_scene/haunt_list_frame.png';
import nft_list_frame_selected from '../assets/haunt_scene/haunt_list_frame_selected.png';

// Character Selection Logo
import character_select_bg from '../assets/character_select/character_select_bg.png';
import game_logo from '../assets/character_select/game_logo.png';
import select_character_button from '../assets/character_select/select_character_button.png';
import CHS_play_game_button from '../assets/character_select/CHS_play_game_button.png';
import CHS_play_highlight from '../assets/character_select/CHS_play_highlight.png';
import selection_menu_panel from '../assets/character_select/selection_menu_panel.png';
import oni_tab from '../assets/character_select/oni_tab.png';
import oni_tab_highlight from '../assets/character_select/oni_tab_highlight.png';
import freaks_tab from '../assets/character_select/freaks_tab.png';
import freaks_tab_highlight from '../assets/character_select/freaks_tab_highlight.png';
import mirror_world_tab from '../assets/character_select/mirror_world_tab.png';
import mirror_world_tab_highlight from '../assets/character_select/mirror_world_tab_highlight.png';
import lazy_lions_tab_highlight from '../assets/character_select/lazy_lions_tab_highlight.png';
import lazy_lions_tab from '../assets/character_select/lazy_lions_tab.png';
import character_frame from '../assets/character_select/character_frame.png';
import character_frame_highlight from '../assets/character_select/character_frame_highlight.png';
import empty_image from '../assets/empty_image.png';
import oni_frame_holder_body from '../assets/player/oni_frame_holder_body.png';
import oni_frame_holder_frame from '../assets/player/oni_frame_holder_frame.png';
import oni_frame_holder_hands from '../assets/player/oni_frame_holder_hands.png';
import check_nft_ownership from '../assets/character_select/check_nft_ownership.png';
import curious_addys_tab from '../assets/character_select/curious_addys_tab.png';
import curious_addys_tab_highlight from '../assets/character_select/curious_addys_tab_highlight.png';
import baby_spirits_tab from '../assets/character_select/baby_spirits_tab.png';
import baby_spirits_tab_highlight from '../assets/character_select/baby_spirits_tab_highlight.png';
import mailbox_panel from '../assets/mailbox/mailbox_panel.png';
import mail_box_text_box_closed from '../assets/mailbox/mail_box_text_box_closed.png';
import mail_box_text_box_opened from '../assets/mailbox/mail_box_text_box_opened.png';
import mail_icon_read from '../assets/mailbox/mail_icon_read.png';
import mail_icon_unread from '../assets/mailbox/mail_icon_unread.png';
import light_candle from '../assets/light_candle.png';

// House Haunt Scene
import generic_haunt_button from '../assets/house_scene/generic_haunt_button.png';
import specific_haunt_button from '../assets/house_scene/specific_haunt_button.png';
import haunt_button from '../assets/haunt_scene/haunt_button.png';
import back_button_house from '../assets/house_scene/backbtn.png';
import back_button_no_text from '../assets/house_scene/back_button_no_text.png';

// Error panel
import error_box from '../assets/error_box.png';
import error_box_2x from '../assets/error_box_2x.png';

import game_coin from '../assets/game_coin.png';
import info_icon from '../assets/info_icon.png';
import FAQ_icon from '../assets/FAQ_icon.png';
import days_left_time_panel from '../assets/days_left/days_left_time_panel.png';
import days_left_panel from '../assets/days_left/days_left_panel.png';

export default (async () => {
  const SPRITE_SHEETS_ARRAY = await [
    {
      name: 'empty_image',
      image: empty_image,
    },
    {
      name: 'background_shop',
      image: background_shop,
    },
    {
      name: 'background_stuffs_overlay',
      image: background_stuffs_overlay,
    },
    {
      name: 'shop_shelves',
      image: shop_shelves,
    },
    {
      name: 'jack_the_seller',
      image: jack_the_seller,
    },
    {
      name: 'shop_sign',
      image: shop_sign,
    },
    {
      name: 'back_button',
      image: back_button,
    },
    {
      name: 'previous_button',
      image: previous_button,
    },
    {
      name: 'next_button',
      image: next_button,
    },
    {
      name: 'wallet',
      image: wallet,
    },
    {
      name: 'shop_name',
      image: shop_name,
    },
    {
      name: 'shop_item',
      image: shop_item,
    },
    {
      name: 'purchase_preview_frame',
      image: purchase_preview_frame,
    },
    {
      name: 'close_button_shop',
      image: close_button_shop,
    },
    {
      name: 'purchase_value_card',
      image: purchase_value_card,
    },
    {
      name: 'base_house_grayscale',
      image: base_house_grayscale,
    },

    // Main Screen
    {
      name: 'base_house',
      image: base_house,
    },
    {
      name: 'house_sky',
      image: house_sky,
    },
    {
      name: 'stars',
      image: stars,
    },
    {
      name: 'oni',
      image: oni,
    },
    {
      name: 'hide_menu',
      image: hide_menu,
    },
    {
      name: 'main_menu',
      image: main_menu,
    },
    {
      name: 'logo',
      image: logo,
    },
    {
      name: 'customization_panel',
      image: customization_panel,
    },
    {
      name: 'mute',
      image: mute,
    },
    {
      name: 'unmute',
      image: unmute,
    },
    {
      name: 'landscape_mobile',
      image: landscape_mobile,
    },

    // Mission Panel.
    {
      name: 'mission_panel',
      image: mission_panel,
    },
    {
      name: 'mission_info_grid_claim',
      image: mission_info_grid_claim,
    },
    {
      name: 'mission_info_grid_claim_clear',
      image: mission_info_grid_claim_clear,
    },
    {
      name: 'mission_info_grid_no_claim',
      image: mission_info_grid_no_claim,
    },
    {
      name: 'close_button',
      image: close_button,
    },
    // haunt bounty scene
    {
      name: 'haunt_background',
      image: haunt_background,
    },
    {
      name: 'haunt_messagebox_menu',
      image: haunt_messagebox_menu,
    },
    {
      name: 'haunt_message_box_frame',
      image: haunt_message_box_frame,
    },
    {
      name: 'haunt_nft_list_menu',
      image: haunt_nft_list_menu,
    },
    {
      name: 'nft_list_element_frame',
      image: nft_list_element_frame,
    },
    {
      name: 'nft_list_frame_selected',
      image: nft_list_frame_selected,
    },

    // Character Selection Logo
    {
      name: 'character_select_bg',
      image: character_select_bg,
    },
    {
      name: 'game_logo',
      image: game_logo,
    },
    {
      name: 'select_character_button',
      image: select_character_button,
    },
    {
      name: 'CHS_play_game_button',
      image: CHS_play_game_button,
    },
    {
      name: 'CHS_play_highlight',
      image: CHS_play_highlight,
    },
    {
      name: 'selection_menu_panel',
      image: selection_menu_panel,
    },
    {
      name: 'oni_tab',
      image: oni_tab,
    },
    {
      name: 'oni_tab_highlight',
      image: oni_tab_highlight,
    },
    {
      name: 'lazy_lions_tab_highlight',
      image: lazy_lions_tab_highlight,
    },
    {
      name: 'lazy_lions_tab',
      image: lazy_lions_tab,
    },
    {
      name: 'jungle_freaks_tab',
      image: freaks_tab,
    },
    {
      name: 'jungle_freaks_tab_highlight',
      image: freaks_tab_highlight,
    },
    {
      name: 'mirror_world_tab',
      image: mirror_world_tab,
    },
    {
      name: 'info_icon',
      image: info_icon,
    },
    {
      name: 'FAQ_icon',
      image: FAQ_icon,
    },
    {
      name: 'mirror_world_tab_highlight',
      image: mirror_world_tab_highlight,
    },
    {
      name: 'character_frame',
      image: character_frame,
    },
    {
      name: 'character_frame_highlight',
      image: character_frame_highlight,
    },
    {
      name: 'oni_frame_holder_body',
      image: oni_frame_holder_body,
    },
    {
      name: 'oni_frame_holder_frame',
      image: oni_frame_holder_frame,
    },
    {
      name: 'oni_frame_holder_hands',
      image: oni_frame_holder_hands,
    },
    {
      name: 'error_box',
      image: error_box,
    },
    {
      name: 'error_box_2x',
      image: error_box_2x,
    },
    {
      name: 'check_nft_ownership',
      image: check_nft_ownership,
    },
    {
      name: 'logout',
      image: logout,
    },
    {
      name: 'curious_addys_tab',
      image: curious_addys_tab,
    },
    {
      name: 'curious_addys_tab_highlight',
      image: curious_addys_tab_highlight,
    },
    {
      name: 'lootbox_toggle',
      image: lootbox_toggle,
    },
    {
      name: 'lootbox_window',
      image: lootbox_window,
    },
    {
      name: 'lootbox_preview_frame',
      image: lootbox_preview_frame,
    },
    {
      name: 'baby_spirits_tab',
      image: baby_spirits_tab,
    },
    {
      name: 'baby_spirits_tab_highlight',
      image: baby_spirits_tab_highlight,
    },
    // house scene
    {
      name: 'generic_haunt_button',
      image: generic_haunt_button,
    },
    {
      name: 'specific_haunt_button',
      image: specific_haunt_button,
    },
    {
      name: 'haunt_button',
      image: haunt_button,
    },
    {
      name: 'back_button_house',
      image: back_button_house,
    },
    {
      name: 'mailbox_panel',
      image: mailbox_panel,
    },
    {
      name: 'mail_box_text_box_closed',
      image: mail_box_text_box_closed,
    },
    {
      name: 'mail_box_text_box_opened',
      image: mail_box_text_box_opened,
    },
    {
      name: 'mail_icon_read',
      image: mail_icon_read,
    },
    {
      name: 'mail_icon_unread',
      image: mail_icon_unread,
    },
    {
      name: 'light_candle',
      image: light_candle,
    },
    {
      name: 'wallet_panel',
      image: wallet_panel,
    },
    {
      name: 'share_button',
      image: share_button,
    },
    {
      name: 'game_coin',
      image: game_coin,
    },
    {
      name: 'back_button_no_text',
      image: back_button_no_text,
    },
    {
      name: 'mission_name_panel',
      image: mission_name_panel,
    },
    {
      name: 'mission_price_panel',
      image: mission_price_panel,
    },
    {
      name: 'mission_claim_panel',
      image: mission_claim_panel,
    },
    {
      name: 'days_left_time_panel',
      image: days_left_time_panel,
    },
    {
      name: 'days_left_panel',
      image: days_left_panel,
    },
    {
      name: 'lootbox_progress_bar_box',
      image: lootbox_progress_bar_box,
    },
    {
      name: 'lootbox_progress_bar_progress',
      image: lootbox_progress_bar_progress,
    },
    {
      name: 'progress_bar_goals',
      image: progress_bar_goals,
    },
    {
      name: 'candle_counter',
      image: candle_counter,
    },
    {
      name: 'lores_panel',
      image: lores_panel,
    },
    {
      name: 'lores_text_box',
      image: lores_text_box,
    },
    {
      name: 'lore_frame',
      image: lore_frame,
    },
    {
      name: 'twitter_banner_base',
      image: twitter_banner_base,
    },
    {
      name: 'twitter_button',
      image: twitter_button,
    },
  ];

  return SPRITE_SHEETS_ARRAY;
})();
