import { GAME_MESSAGES } from '../../../consts/Messages';
import { Overlay } from './Overlay';

export class LightCandleFeedback extends Phaser.GameObjects.Container {
  /**
   *
   * @param {Phaser.Scene} scene
   */
  constructor(scene) {
    super(scene, 0, 0);
    this.overlay = new Overlay(scene);
    this.candle_animation = scene.add
      .sprite(scene.cameras.main.midPoint.x, scene.cameras.main.midPoint.y - 150, 'candle_animation')
      .play('candle_explosion');
    this.text_information = scene.add
      .text(scene.cameras.main.midPoint.x, scene.cameras.main.midPoint.y + 150, GAME_MESSAGES.YOUR_CANDLE_WAS_LIT, {
        fontFamily: 'BlackBeard',
        color: '#ffffff',
        fontSize: '68px',
        wordWrap: { width: 800, useAdvancedWrap: true },
      })
      .setOrigin(0.5, 0.5);
    this.close_button = scene.add.image(1800, 75, 'close_button').setInteractive();

    scene.tweens.add({
      targets: this.text_information,
      alpha: { from: 0.3, to: 1 },
      duration: 2000,
      yoyo: true,
      repeat: -1,
    });

    this.setDepth(9999999999999999);
    this.add([this.overlay, this.candle_animation, this.text_information, this.close_button]);
    scene.add.existing(this);

    this.candle_animation.once(`animationcomplete-candle_explosion`, () => {
      this.candle_animation.play('candle_idle');
    });
    this.close_button.on('pointerup', () => {
      console.log('close_button');
      this.destroy();
    });
  }
}
