export const LootboxButtonAnimationConfig = {
  atlas: 'lootbox_button_gif',
  animations: [
    {
      frameRate: 24,
      key: 'lootbox_button',
      name: 'lootbox_button_animation',
      frameLength: 55,
      repeat: -1,
    },
  ],
};
