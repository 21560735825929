import { GameUtils } from '../Utils/GameUtils';

const paddingImageX = 14;
const paddingImageY = 19;
export class HauntNFTListElement extends Phaser.GameObjects.Container {
  constructor(scene, nft_config, haunt_type) {
    super(scene, 0, 0);
    /**
     * @type { Phaser.Scene}
     */
    this.scene = scene;
    /**
     * @type {Phaser.GameObjects.Sprite}
     */
    this.frame;
    this.nft_config = nft_config;
    this.haunt_type = haunt_type;

    this.createElements();

    this.depth = 20;
    this.on('pointerup', this.onClick, this);
    scene.add.existing(this);
  }

  createElements() {
    this.frame = this.scene.add.sprite(0, 0, 'nft_list_element_frame').setOrigin(0, 0);
    this.image = this.scene.add
      .sprite(paddingImageX, paddingImageY, this.nft_config.name)
      .setDisplaySize(244, 244)
      .setOrigin(0, 0);
    this.haunt_button = this.scene.add
      .sprite(138, 268, 'haunt_button')
      .setInteractive()
      .setVisible(false)
      .setScale(0.5);
    this.haunt_button.on('pointerup', (pointer) => {
      if (GameUtils.pointerTimeCheck(pointer)) {
        this.scene.scene.get('HauntChoiceScene').events.emit('perform_haunt', this.haunt_type);
      }
    });
    const { width, height } = this.image.getBounds();
    this.setInteractive(
      new Phaser.Geom.Rectangle(0, 0, width + paddingImageX, height + paddingImageY),
      Phaser.Geom.Rectangle.Contains
    );
    this.add([this.image, this.frame, this.haunt_button]);
  }

  onClick({ downTime, upTime }) {
    if (upTime - downTime < 300) {
      this.scene.removeSelections();
      this.setSelection(true);
    }
  }

  setSelection(selected = false) {
    if (selected) this.scene.events.emit('haunt_list_nft_selected', this.nft_config);
    this.haunt_button.setVisible(selected);
    this.frame.setTexture(selected ? 'nft_list_frame_selected' : 'nft_list_element_frame');
  }
}
