import { IS_DEBUG_MODE } from '../OniMansionGame';

export class DebugUtils {
  /**
   *
   * @param {Phaser.Scene} scene
   * @param {*} obj
   */
  static debug(scene, obj) {
    if (IS_DEBUG_MODE) {
      const graphics = scene.add.graphics(0, 0).setDepth(999999999999);
      graphics.fillStyle(0x00ffff, 0.5);
      graphics.fillRectShape(obj.getBounds());
      console.log(graphics);
      scene.add.existing(graphics);
    }
  }
}
