import { BUTTONS_NAMES } from '../consts/HauntScene';
import { BUTTONS } from '../layouts/Haunt';
import { HauntNFTListSubScene } from '../scenes/sub/HauntNFTListSubScene';

export const buttonBehaviors = {
  /**
   * @type {HauntNFTListSubScene}
   */
  scene: null,
  // main scene
  [BUTTONS_NAMES.RANDOM_SEE_MORE]: () => {
    console.log('RANDOM SEE MORE');
    this.scene.launch('HauntOniListSubScene', { type: 'random', nfts: this.scene.generic_haunt_nfts });
  },
  [BUTTONS_NAMES.TRAITS_SEE_MORE]: (payload) => {
    console.log(this);
    this.scene.launch('HauntOniListSubScene', { type: 'traits', nfts: this.scene.specific_haunt_nfts });
  },
  [BUTTONS_NAMES.RANDOM_HAUNT]: function() {
    console.log('HauntMenuSubScene launched by type random');
    //this.scene.launch('HauntMenuSubScene', { type: 'random' });
  },
  [BUTTONS_NAMES.TRAITS_HAUNT]: function() {
    console.log('HauntMenuSubScene launched by type traits');
    //this.scene.launch('HauntMenuSubScene', { type: 'traits' });
  },
  // haunt scene
  [BUTTONS_NAMES.HAUNT_GO]: function() {
    console.log('GO!');
  },
  [BUTTONS_NAMES.HAUNT_CLOSE]: function() {
    console.log('HAUNT MENU WAS CLOSED');
    this.scene.setVisible(false);
  },
  // oni list scene
  [BUTTONS_NAMES.ONI_LIST_SEARCH]: function() {
    console.log('make a search, please');
    console.log(this.scene.scene.searchInput.value);
  },
  [BUTTONS_NAMES.ONI_LIST_SEARCH_INPUT]: function() {
    console.log('test ONI_LIST_SEARCH_INPUT', this.scene);
    this.scene.scene.searchInput.focus();
  },
  [BUTTONS_NAMES.ONI_LIST_CLOSE]: function() {
    console.log('ONI LIST CLOSED');
    this.scene.setVisible(false);
  },
};
