import main_song from '../assets/sound/music/main_song.mp3';
import cash_register from '../assets/sound/sfx/cash_register.mp3';
import click_screen from '../assets/sound/sfx/click_screen.mp3';

export const SOUND_EFFECTS_ASSETS = [
  {
    name: 'main_song',
    file: main_song,
  },
  {
    name: 'cash_register',
    file: cash_register,
  },
  {
    name: 'click_screen',
    file: click_screen,
  },
];
