export class CurrencyComponent extends Phaser.GameObjects.Container {
  /**
   *
   * @param {Phaser.Scene} scene
   * @param {number} x
   * @param {number} y
   * @param {string} textSize
   */
  constructor(scene, x, y, amount = 0, textSize = '38px') {
    super(scene, x, y);
    this.scene = scene;
    const TextStyle = {
      fontFamily: 'BlackBeard',
      fontSize: textSize,
      color: '#000000',
    };
    const coinSize = parseFloat(textSize.replace('px', ''));
    this.coin_sign = this.scene.add
      .image(0, coinSize / 3.1, 'game_coin')
      .setOrigin(0, 0)
      .setDisplaySize(coinSize / 1.65, coinSize / 1.65);
    this.coin_amount = this.scene.add
      .text(this.coin_sign.width * this.coin_sign.scale + 3, 0, amount, TextStyle)
      .setOrigin(0, 0);
    this.add([this.coin_sign, this.coin_amount]);
    this.scene.add.existing(this);
  }

  updateCoinAmount(amount) {
    this.coin_amount.setText(amount);
  }
}
