import Phaser from 'phaser';

import { GameUtils } from '../Utils/GameUtils';
import { buttonBehaviors } from '../services/HauntButtonsBehaviorService';
import { IMAGES, BUTTONS, BOUNTY_HAUNT_SCENE_IMAGES } from '../layouts/Haunt';
import { DataService } from '../services/DataService';
import { LoadingStateManager } from '../manager/LoadingStateManager';
import { onCameraFadeout } from '../Utils/ScenePromisify';
import { SoundManager } from '../manager/SoundManager';

const HAUNT_TYPES = {
  speficic: 'traits',
  generic: 'random',
};

export class HauntChoiceScene extends Phaser.Scene {
  constructor() {
    super({ key: 'HauntChoiceScene' });
    this.dataService = new DataService();
    this.specific_nft;
    this.generic_nft;
    this.type;
  }

  preload() {
    this.loader = new LoadingStateManager(this);
    this.createBase();
    this.load.rexAwait(async (successCallback, failureCallback) => {
      await this.getData();
      this.input.mouse.enabled = true;
      successCallback();
    });
  }

  async getData() {
    this.specific_haunt_nfts = await this.dataService.getSpecificHauntMissionNFTIDs();
    this.generic_haunt_nfts = await this.dataService.getGenericHauntNFTs();
    GameUtils.shuffleArray(this.specific_haunt_nfts);
    if (this.specific_haunt_nfts?.length > 0) {
      const specific_nft = this.data.get('specific_nft');
      this.specific_nft = specific_nft
        ? specific_nft
        : this.specific_haunt_nfts[Math.floor(Math.random() * this.specific_haunt_nfts.length)];
      this.data.set('specific_nft', this.specific_nft);
    }
    if (this.generic_haunt_nfts?.length > 0) {
      const generic_nft = this.data.get('generic_nft');
      this.generic_nft = generic_nft ? generic_nft : this.generic_haunt_nfts[0];
      this.data.set('generic_nft', this.generic_nft);
    }
    await this.fetchNftsImage([this.specific_nft, this.generic_nft]);
    if (
      (!this.specific_haunt_nfts || this.specific_haunt_nfts.length === 0) &&
      (!this.generic_haunt_nfts || this.generic_haunt_nfts.length === 0)
    ) {
      this.loader.setVisible(false);
    }
  }

  fetchNftsImage(nfts) {
    this.loader.setVisible(true);
    if (nfts?.length > 0) {
      nfts.forEach((nft) => {
        if (nft) {
          if (!this.textures.exists(nft.name)) {
            this.load.image(nft.name, nft.image);
            // this.load.once(`filecomplete-image-${nft.name}`, this.onNftImageComplete, this);
            this.load.on(`complete`, this.onNftImageComplete, this);
            this.load.start();
          } else {
            this.onNftImageComplete();
          }
        }
      });
    }
  }

  async setSpecificNFTImage() {
    if (this.specific_nft) {
      this.specific_nft_image?.destroy();
      const { x, y, width, height } = BOUNTY_HAUNT_SCENE_IMAGES.SPECIFIC_NFT;
      this.specific_nft_image = this.add
        .image(x, y, this.specific_nft.name)
        .setOrigin(0, 0)
        .setDisplaySize(width, height);
    }
  }
  async setGenericNFTImage() {
    if (this.generic_nft) {
      this.generic_nft_image?.destroy();
      const { x, y, width, height } = BOUNTY_HAUNT_SCENE_IMAGES.GENERIC_NFT;
      this.generic_nft_image = this.add
        .image(x, y, this.generic_nft.name)
        .setOrigin(0, 0)
        .setDisplaySize(width, height);
    }
  }

  onNftImageComplete() {
    this.loader.setVisible(false);
    this.setSpecificNFTImage();
    this.setGenericNFTImage();
  }

  createBase() {
    this.make.sprite(IMAGES.MAIN_BACKGROUND);
    this.addButtons();
    this.back = this.make.image(BUTTONS.BACK_HOME).setInteractive();
    this.back.on('pointerup', (p) => {
      if (p.leftButtonReleased()) {
        SoundManager.clickScreenSound(this);
        this.dataService.should_reload_data = true;
        this.scene.start('MainScreenScene');
        this.scene.stop('HauntNFTListSubScene');
      }
    });
  }

  createSpecificSeeMore() {
    const { x, y, width, height } = BUTTONS.HauntChoiceScene.TRAITS_SEE_MORE;
    this.see_more_specific = this.add
      .zone(x, y, width, height)
      .setInteractive()
      .setOrigin(0, 0);
    this.see_more_specific.on('pointerup', () => {
      SoundManager.clickScreenSound(this);
      this.scene.launch('HauntNFTListSubScene', {
        type: 'traits',
        nfts: this.specific_haunt_nfts,
        selected: this.specific_nft,
      });
    });
  }

  createSpecificHaunt() {
    const { x, y, width, height } = BUTTONS.HauntChoiceScene.TRAITS_HAUNT;
    this.haunt_specific = this.add
      .zone(x, y, width, height)
      .setInteractive()
      .setOrigin(0, 0);
    this.haunt_specific.on('pointerup', () => {
      SoundManager.clickScreenSound(this);
      if (this.specific_nft) {
        this.performSpecificHaunt();
      }
    });
  }

  createGenericSeeMore() {
    const { x, y, width, height } = BUTTONS.HauntChoiceScene.RANDOM_SEE_MORE;
    this.see_more_generic = this.add
      .zone(x, y, width, height)
      .setInteractive()
      .setOrigin(0, 0);
    this.see_more_generic.on('pointerup', () => {
      SoundManager.clickScreenSound(this);
      this.scene.launch('HauntNFTListSubScene', {
        type: 'random',
        nfts: this.generic_haunt_nfts,
        selected: this.generic_nft,
      });
    });
    // const debug = this.add.graphics(0, 0);
    // debug.fillStyle(0x00ffff, 0.5);
    // debug.fillRectShape(this.see_more_generic.getBounds());
  }

  createGenericHaunt() {
    const { x, y, width, height } = BUTTONS.HauntChoiceScene.RANDOM_HAUNT;
    this.haunt_generic = this.add
      .zone(x, y, width, height)
      .setInteractive()
      .setOrigin(0, 0);
    this.haunt_generic.on('pointerup', async () => {
      SoundManager.clickScreenSound(this);
      if (this.generic_nft) {
        this.performGenericHaunt();
      }
    });
    // const debug = this.add.graphics(0, 0);
    // debug.fillStyle(0x00ffff, 0.5);
    // debug.fillRectShape(this.haunt_generic.getBounds());
  }
  performHaunt(haunt_type) {
    if (haunt_type === HAUNT_TYPES.generic) this.performGenericHaunt();
    else this.performSpecificHaunt();
  }

  performSpecificHaunt() {
    console.log('Specific');
    this.scene.pause();
    this.scene.launch('HouseHauntScene', {
      contract_address: this.specific_nft.contract_address,
      nft_id: this.specific_nft.id,
      nft_info: this.specific_nft,
    });
  }

  performGenericHaunt() {
    console.log('Generic');
    this.scene.pause();
    this.scene.launch('HouseHauntScene', {
      contract_address: this.generic_nft.contract_address,
      nft_id: this.generic_nft.id,
      nft_info: this.generic_nft,
    });
  }

  addButtons() {
    this.createSpecificSeeMore();
    this.createSpecificHaunt();
    this.createGenericSeeMore();
    this.createGenericHaunt();
  }

  create() {
    this.events.removeListener('nft_selected_for_haunt');
    this.events.removeListener('perform_haunt');
    this.events.on('perform_haunt', this.performHaunt, this);
    this.events.on(
      'nft_selected_for_haunt',
      ({ nft, type }) => {
        this.type = type;
        if (type === HAUNT_TYPES.speficic) {
          this.specific_nft = nft;
          this.data.set('specific_nft', this.specific_nft);
          this.setSpecificNFTImage();
        }
        if (type === HAUNT_TYPES.generic) {
          this.generic_nft = nft;
          this.data.set('generic_nft', this.generic_nft);
          this.setGenericNFTImage();
        }
      },
      this
    );
  }
}
