import { SCROLL_PANEL_SPEED } from '../consts/GameConstants';
import { IS_DEBUG_MODE } from '../OniMansionGame';

import { GameUtils } from '../Utils/GameUtils';

const SCROLL_PANEL_UI = {
  width: 61,
  height: 52,
};

/**
 * Creates a Scrollable Panel
 */
export class ScrollablePanel {
  /**
   *
   * @param { Phaser.Scene } scene phaser scene.
   * @param { boolean } vertical Is the panel vertical or horizontal.
   */
  constructor(scene, vertical = true, id = 'scrollable-panel') {
    /**
     * @type { Phaser.Scene }
     */
    this.scene = scene;

    this.panel_id = id;
    this.panel_event = 'scrollable_panel_end_of_panel';

    /**
     * Is the panel vertical or horizontal.
     * @type { boolean }
     */
    this.isVertical = vertical;
    /**
     * @type {Phaser.GameObjects.Graphics|Phaser.GameObjects.Sprite}
     */
    this.mask;
    /**
     * @type {Phaser.GameObjects.Container}
     */
    this.panelContainer;
    /**
     * @type {Phaser.Structs.Size}
     */
    this.displaySize;
    /**
     * @type {Phaser.Math.Vector2}
     */
    this.initialPosition;
    /**
     * Controls if the mouse is over the scrollable panel.
     * @type { boolean }
     * @default true
     */
    this.mouseOverScrollablePanel = false;
    /**
     * @type {number}
     */
    this.touchDragCounter;

    this.infiniteScroll = true;
    this.canScroll = true;
    this.previous_position = 0;
  }

  /**
   * Sets positions and dimensions of the panel.
   * @param { number } x the x position of the panel.
   * @param { number } y the y position of the panel.
   * @param { number } width the width of the panel.
   * @param { number } height the height of the panel.
   * @param { number } scrollLimits Scrolling limits of the panel. The actual height of the container.
   */
  setPositions(x, y, width, height, limits) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.scrollLimits = limits;
  }

  /**
   * Scrolls the panel by the given amount.
   * @param { number } velocity The abount to scroll the panel by.
   */
  scrollMove(velocity, tween = false, duration = 400) {
    if (this.isVertical) {
      if (tween) {
        const newPosition = this.panelContainer.y - velocity;
        this.scene.tweens.add({
          targets: this.panelContainer,
          y: { from: this.panelContainer.y, to: newPosition },
          duration,
          onUpdate: () => {
            this.panelContainer.y = Phaser.Math.Clamp(this.panelContainer.y, -this.scrollLimits + this.height, 0);
            this.checkEndLine();
          },
        });
      } else {
        this.panelContainer.y -= velocity;
        this.panelContainer.y = Phaser.Math.Clamp(this.panelContainer.y, -this.scrollLimits + this.height, 0);
        this.checkEndLine();
      }
    } else {
      this.panelContainer.x -= velocity;

      this.panelContainer.x = Phaser.Math.Clamp(this.panelContainer.x, -this.scrollLimits + this.width, 0);
    }
  }

  checkEndLine() {
    const endOfLine = this.panelContainer.y === -this.scrollLimits + this.height;
    this.previous_position = this.panelContainer.y;
    if (this.infiniteScroll && endOfLine && this.canScroll) {
      this.canScroll = false;
      this.scene.events.emit(`${this.panel_event}${this.panel_id}`, true);
    } else if (!endOfLine) {
      this.canScroll = true;
    }
  }

  endOfPanel(callback) {
    callback();
  }

  /**
   * Creates the panel container. And its boundaries.
   * @param {Phaser.GameObjects.Container} container
   */
  createScrollSection(container, config) {
    this.initialPosition = new Phaser.Math.Vector2(container.x, container.y);
    this.displaySize = GameUtils.getContainerSize(container);
    this.scene.input.setTopOnly(false);
    this.mask?.destroy();
    this.panelContainer = container;

    this.mask = this.scene.make.graphics();
    this.mask.fillRect(this.x, this.y, this.width, this.height);
    this.mask = this.mask.createGeometryMask();
    this.panelContainer.setMask(this.mask);

    this.scrollZone = this.scene.add
      .zone(this.x, this.y, this.width, this.height)
      .setOrigin(0)
      .setInteractive();
    this.scrollZone.on('pointermove', this.onPointerMove, this);
    this.scrollZone.on('pointerout', () => (this.mouseOverScrollablePanel = false), this);
    this.scene.input.on('wheel', this.onWheel, this);
  }

  onWheel(pointer, gameObjects, deltaX, deltaY, deltaZ) {
    if (!this.mouseOverScrollablePanel) return;
    if (!this.panelContainer.visible && !this.mouseOverScrollablePanel) return;
    const normalizedDelta = Math.sign(deltaY) * SCROLL_PANEL_SPEED;
    this.scrollMove(normalizedDelta);
  }

  onPointerMove(pointer) {
    if (!this.panelContainer.visible) return;
    this.mouseOverScrollablePanel = true;
    if (pointer.isDown) {
      this.touchDragCounter = 0;
      this.scrollMove(-(pointer.velocity.y / 4));
    }
  }

  resetPanel() {
    this.mouseOverScrollablePanel = true;
  }

  /**
   *
   * @returns { boolean } Returns true if the mouse is over the scrollable panel.
   */
  overScrollablePanel() {
    return this.mouseOverScrollablePanel;
  }

  /**
   * Sets the panel container to the previous position.
   */
  setPreviousPosition() {
    this.panelContainer.y = this.previous_position;
  }

  /**
   *
   * @param { Vector } up Up x and y position.
   * @param { Vector } down Down x and y position.
   * @param { number } scrollFactor How much of the panel should be moved? 1 = 100%
   * @param { number } duration Duration of the Tween in milliseconds.
   */
  createArrowControl({ up, down, scrollFactor, duration }) {
    this.addScrollUp({ ...up, scrollFactor, duration });
    this.addScrollDown({ ...down, scrollFactor, duration });
  }

  /**
   * Creates the arrow up Scroll Controller.
   */
  addScrollUp({ x, y, scrollFactor, duration }) {
    const { width, height } = SCROLL_PANEL_UI;
    this.scroll_up = this.scene.add
      .zone(x, y, width, height)
      .setInteractive()
      .setOrigin(0, 0)
      .setDepth(100);
    this.scroll_up.on('pointerup', () => {
      if (this.panelContainer) this.scrollMove(-this.height * scrollFactor, true, duration);
    });
    if (IS_DEBUG_MODE) {
      const grph = this.scene.add.graphics();
      grph.fillStyle(0x00ffff, 0.5);
      grph.fillRectShape(this.scroll_up.getBounds());
    }
  }

  /**
   * Creates the down up Scroll Controller.
   */
  addScrollDown({ x, y, scrollFactor, duration }) {
    const { width, height } = SCROLL_PANEL_UI;
    this.scroll_down = this.scene.add
      .zone(x, y, width, height)
      .setInteractive()
      .setOrigin(0, 0)
      .setDepth(100);
    this.scroll_down.on('pointerup', () => {
      if (this.panelContainer) this.scrollMove(this.height * scrollFactor, true, duration);
    });
    if (IS_DEBUG_MODE) {
      const grph = this.scene.add.graphics();
      grph.fillStyle(0x00ffff, 0.5);
      grph.fillRectShape(this.scroll_down.getBounds());
    }
  }
}
