import Phaser from 'phaser';
import { Overlay } from '../components/Overlay';
import { TransactionGIF } from '../components/TransactionGIF';

export class TransactionWaitingGif extends Phaser.GameObjects.Container {
  /**
   * Manages the game Loading State.
   * @param { Phaser.Scene } scene
   */
  constructor(scene, visible = true) {
    super(scene, 0, 0);
    this.overlayTexture = new Overlay(scene);
    this.loading_gif = new TransactionGIF(scene);
    this.add([this.overlayTexture, this.loading_gif]);
    this.depth = 99999999;
    scene.add.existing(this);

    this.setVisible(visible);
  }
}
