import { BUTTONS_NAMES } from '../consts/HauntScene';

export const BUTTONS = {
  HauntChoiceScene: {
    RANDOM_SEE_MORE: {
      width: 327,
      height: 127,
      x: 741,
      y: 81,
    },
    TRAITS_SEE_MORE: {
      width: 327,
      height: 127,
      x: 1407,
      y: 81,
    },
    RANDOM_HAUNT: {
      width: 327,
      height: 127,
      x: 741,
      y: 794,
    },
    TRAITS_HAUNT: {
      width: 327,
      height: 127,
      x: 1407,
      y: 794,
    },
  },
  HauntMenuSubScene: {
    [BUTTONS_NAMES.HAUNT_GO]: {
      SIZE: {
        WIDTH: 406,
        HEIGHT: 181,
      },
      POSITION: {
        X: 1027,
        Y: 696,
      },
    },
    [BUTTONS_NAMES.HAUNT_CLOSE]: {
      SIZE: {
        WIDTH: 87,
        HEIGHT: 85,
      },
      POSITION: {
        X: 1685,
        Y: 90,
      },
    },
  },
  HauntNFTListSubScene: {
    CLOSE: {
      SIZE: {
        WIDTH: 88,
        HEIGHT: 86,
      },
      POSITION: {
        X: 1697,
        Y: 31,
      },
    },
    SCROLL_UP: {
      x: 1656,
      y: 189,
      width: 61,
      height: 52,
    },
    SCROLL_DOWN: {
      x: 1656,
      y: 868,
      width: 61,
      height: 52,
    },
    NO_RECORDS_AVAILABLE: {
      x: 1220,
      y: 254,
      origin: {
        x: 0.5,
        y: 0.5,
      },
      style: {
        fontSize: '50px',
        fontFamily: 'BlackBeard',
        color: '#000000',
      },
    },
  },
  BACK_HOME: {
    x: 40,
    y: 960,
    key: 'back_button',
    origin: {
      x: 0,
      y: 0.5,
    },
  },
};

export const BOUNTY_HAUNT_SCENE_IMAGES = {
  SPECIFIC_NFT: {
    x: 1424,
    y: 353,
    width: 299,
    height: 295,
  },
  GENERIC_NFT: {
    x: 764,
    y: 360,
    width: 299,
    height: 295,
  },
};

export const IMAGES = {
  MAIN_BACKGROUND: {
    x: 0,
    y: 0,
    key: 'haunt_background',
    origin: { x: 0, y: 0 },
  },
  MESSAGEBOX_MENU_BACKGROUND: {
    x: 718,
    y: 32,
    key: 'haunt_messagebox_menu',
    origin: { x: 0, y: 0 },
  },
  NFT_LIST_BACKGROUND: {
    x: 716,
    y: 31,
    key: 'haunt_nft_list_menu',
    origin: { x: 0, y: 0 },
  },
};

export const SEE_MORE_LIST_GRID = {
  width: 274,
  height: 274,
  columns: 3,
  x: 953,
  y: 406,
  margin: {
    h: 19,
    v: 50,
  },
};

export const ONI_LIST_ELEMENT = {};
