import { Overlay } from './Overlay';
import Phaser from 'phaser';
import { House } from '../entities/House';
import { LOOTBOX } from '../layouts/ShopScreen';
import lodash from 'lodash';
import { HOUSE_TRAITS_CONFIG } from '../consts/HouseTraits';
import { GameUtils } from '../Utils/GameUtils';
import { SoundManager } from '../manager/SoundManager';
import { TRAITS_CONFIG } from '../consts/traits/TraitsConfig';

export class OpenLootbox extends Phaser.Scene {
  constructor() {
    super({
      key: 'OpenLootbox',
    });
    this.show_house = null;
    /**
     * @type {House}
     */
    this.house_preview;
  }

  init({ data }) {
    this.attribute = data;
    // console.log('OpenLootbox.init()', this.attribute);
    this.config = lodash.cloneDeep(HOUSE_TRAITS_CONFIG);
    this.config[this.attribute.trait_type] = this.attribute.name;
    // console.log('CONFIG()', this.config);
  }

  create() {
    this.overlay = new Overlay(this);
    let { x, y, width, height } = LOOTBOX.OPEN_LOOTBOX_CLOSE;
    this.close_button = this.add.zone(x, y, width, height).setInteractive();

    this.lootbox_animation = this.add
      .sprite(this.cameras.main.midPoint.x, this.cameras.main.midPoint.y, 'lootbox_animations')
      .setInteractive();
    this.frame = this.add
      .image(
        this.cameras.main.midPoint.x + LOOTBOX.FRAME_OFFSET.x,
        this.cameras.main.midPoint.y + LOOTBOX.FRAME_OFFSET.y,
        'lootbox_preview_frame'
      )
      .setVisible(false)
      .setDepth(2);
    this.text_open_lb = this.add.text(650, 905, 'Click on the Box to Open', {
      fontStyle: 'bold',
      fontSize: '80px',
      fontFamily: 'BlackBeard',
      color: '#ffffff',
    });
    this.tweens.add({
      targets: this.text_open_lb,
      yoyo: true,
      repeat: -1,
      duration: 500,
      alpha: { from: 1, to: 0.5 },
    });
    this.lootbox_animation.play('lootbox_idle');
    this.lootbox_animation.on('pointerup', () => {
      SoundManager.clickScreenSound(this);
      this.text_open_lb.setVisible(false);
      this.lootbox_animation.setInteractive(false);
      this.lootbox_animation.play('lootbox_open');
      this.lootbox_animation.on(Phaser.Animations.Events.ANIMATION_UPDATE, (animation, frame) => {
        if (frame.index === animation.frames.length - 5) {
          this.cameras.main.flash(500, 255, 255, 255, false, () => {});
        }
      });
      this.lootbox_animation.on(Phaser.Animations.Events.ANIMATION_COMPLETE, (animation, frame) => {
        this.lootbox_animation.setVisible(false);

        let { x, y } = LOOTBOX.HOUSE_PREVIEW;
        this.house_preview = new House(this, x, y, this.config, true);

        this.frame.setVisible(true);
        this.close_button.setVisible(true);
      });
    });
    this.close_button.on('pointerup', () => {
      SoundManager.clickScreenSound(this);
      this.house_preview.destroy();
      this.close_button.destroy();
      this.frame.destroy();
      this.text_open_lb?.destroy();
      this.scene.get('LootboxWindow').scene.restart();
      this.scene.stop();
    });
  }
}
