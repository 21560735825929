export class CandleCounter extends Phaser.GameObjects.Container {
  /**
   *
   * @param {Phaser.Scene} scene
   * @param {number} visits
   */
  constructor(scene, visits = 0) {
    super(scene, 0, 0);
    this.candle = scene.add.image(1855, 879, 'candle_counter');
    this.text_counter = scene.add
      .text(1855, 922, visits | 0, {
        fontFamily: 'BlackBeard',
        color: '#000000',
        fontSize: '38px',
      })
      .setOrigin(0.5, 0.5);
    this.add([this.candle, this.text_counter]);
    this.setDepth(30);
    scene.add.existing(this);
  }

  updateCandleCounter(counter) {
    this.text_counter.setText(counter);
  }
}
