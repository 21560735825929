import { MAILBOX } from '../layouts/MainScreen';
import Phaser from 'phaser';

export class Mailbox extends Phaser.GameObjects.Sprite {
  /**
   *
   * @param {Phaser.Scene} scene
   */
  constructor(scene, counter) {
    const { x, y, texture, frame, depth } = MAILBOX;
    super(scene, x, y, texture, frame);
    /**
     * @type {Phaser.Scene}
     */
    this.scene = scene;

    this.counter = counter;

    this.setDepth(depth);
    this.setOrigin(0.5, 1);
    this.scene.add.existing(this);
    this.setInteractive();
    this.on('pointerup', () => {
      this.scene.scene.launch('MailBoxPanel', { messages: this.messages });
    });
    this.scene.scene.get('MailBoxPanel').events.removeListener('mailbox_message_opened');
    this.scene.scene.get('MailBoxPanel').events.on('mailbox_message_opened', this.updateMessages, this);
    this.haunt_counter = this.scene.add
      .text(x - 50, y - 160, 0, {
        fontFamily: 'BlackBeard',
        color: '#000000',
        fontSize: '32px',
      })
      .setDepth(10)
      .setOrigin(0.5, 0);
    this.scene.add.existing(this.haunt_counter);
  }

  updateMessages(messages) {
    this.messages = messages;
    const hasMessage = this.messages.some((m) => !m.read);
    this.haunt_counter.setText(`${this.messages.length > 999 ? '999 +' : this.messages.length}`);
    if (hasMessage) this.setFrame('mailbox_flag_up');
    else this.setFrame(MAILBOX.frame);
  }
}
