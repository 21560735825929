import Phaser from 'phaser';

import { GameUtils } from '../../Utils/GameUtils';
import { IMAGES, BUTTONS, SEE_MORE_LIST_GRID } from '../../layouts/Haunt';
import { BUTTONS_NAMES } from '../../consts/HauntScene';

import { buttonBehaviors } from '../../services/HauntButtonsBehaviorService';
import { DataService } from '../../services/DataService';

import { ScrollablePanel } from '../../components/ScrollablePanel';
import { HauntNFTListElement } from '../../components/HauntNFTListElement';
import { PROJECTS } from '../../../../consts/Projects';
import { Button } from '../../components/Button';
import { InputText } from '../../components/InputText';
import { LoadingStateManager } from '../../manager/LoadingStateManager';
import { Overlay } from '../../components/Overlay';
import { GAME_MESSAGES } from '../../../../consts/Messages';

const SCROLLABLE_PANEL_NFT_LIST = {
  x: 762,
  y: 185,
  width: 966,
  height: 740,
  perContainerMarginDiff: 222,
};

const SCROLL_FACTOR = 0.5;
export class HauntNFTListSubScene extends Phaser.Scene {
  constructor() {
    super({ key: 'HauntNFTListSubScene' });
    /**
     * @type {Phaser.GameObjects.Container}
     */
    this.oniList;
    /**
     * @type {ScrollablePanel}
     */
    this.scrollable;
    /**
     * @type {DataService}
     */
    /**
     * @type {Array<Button>}
     */
    this.buttons = {};
    /**
     * @type {DataService}
     */
    this.dataService = new DataService();
    /**
     * @type {InputText}
     */
    this.searchInput;
    /**
     * @type {Phaser.GameObjects.Text}
     */
    this.searchInputText;

    /**
     * @type {Phaser.GameObjects.Container}
     * */
    this.grid_container;

    this.limit = 9;
    this.offset = 0;
    this.page = 0;
    this.current_list = [];
  }

  init({ type, nfts, selected }) {
    this.nft_list = nfts;
    this.haunt_type = type;
    this.selected = selected;
  }

  create() {
    // we need to use `createBase` cause we do not know native Phaser `create` method side effects.
    this.loader = new LoadingStateManager(this, false);
    this.overlay = new Overlay(this);
    this.createBase();
    this.events.removeListener('haunt_list_nft_selected');
    this.events.on('haunt_list_nft_selected', this.hauntSelected, this);
  }

  hauntSelected(nft) {
    this.selected = nft;
    this.scene.get('HauntChoiceScene').events.emit('nft_selected_for_haunt', { nft, type: this.haunt_type });
  }

  async createBase() {
    this.make.sprite(IMAGES.NFT_LIST_BACKGROUND);
    this.addClosebutton();
    if (this.nft_list && this.nft_list?.length > 0) {
      this.scrollable = new ScrollablePanel(this, true, `nft_list_panel${this.haunt_type}`);
      this.events.removeListener(`${this.scrollable.panel_event}nft_list_panel${this.haunt_type}`);
      this.events.on(`${this.scrollable.panel_event}nft_list_panel${this.haunt_type}`, () => {
        console.log('nft_list_panel_END');
        if (this.current_list.length != this.nft_list.length) {
          this.pagination();
          this.fetchNftsImage(this.current_list);
        }
      });
      this.pagination(1);
      this.fetchNftsImage(this.current_list);
    } else {
      this.addNoRecords();
    }
  }

  addNoRecords() {
    this.no_records_available = this.make
      .text(BUTTONS.HauntNFTListSubScene.NO_RECORDS_AVAILABLE, true)
      .setText(GAME_MESSAGES.NO_RECORDS_AVAILABLE);
  }

  pagination(page) {
    if (this.nft_list) {
      if (page) this.page = page;
      else this.page++;
      this.current_list = this.nft_list.slice(
        this.offset,
        Phaser.Math.Clamp(this.page * this.limit, 0, this.nft_list.length)
      );
    }
  }

  addClosebutton() {
    const { X, Y } = BUTTONS.HauntNFTListSubScene.CLOSE.POSITION;
    const { WIDTH, HEIGHT } = BUTTONS.HauntNFTListSubScene.CLOSE.SIZE;
    this.close_button = this.add
      .zone(X, Y, WIDTH, HEIGHT)
      .setInteractive()
      .setOrigin(0, 0);
    this.close_button.on('pointerup', () => {
      this.grid_container?.destroy();
      this.scene.stop();
    });
  }

  async fetchNftsImage(nfts) {
    if (nfts?.length > 0) {
      nfts.forEach((nft) => {
        if (!this.textures.exists(`${nft.name}`)) {
          this.load.image(`${nft.name}`, nft.image);
          this.loader.setVisible(true);
        }
      });
      this.load.once(`complete`, this.onNftSpriteLoad, this);
      this.load.start();
    } else {
      this.loader.setVisible(false);
    }
  }

  removeSelections() {
    this.grid_container.list.forEach((nft) => {
      nft.setSelection(false);
    });
  }

  onNftSpriteLoad() {
    this.loader.setVisible(false);
    this.grid_container?.destroy();
    this.grid_container = this.add.container(0, 0);
    this.current_list.forEach((nft) => {
      const frame = new HauntNFTListElement(this, nft, this.haunt_type);
      const { contract_address, id } = nft;
      if (contract_address === this.selected.contract_address && id === this.selected.id) {
        frame.setSelection(true);
      }
      this.grid_container.add(frame);
    });

    const rows = Math.ceil(this.grid_container.list.length / SEE_MORE_LIST_GRID.columns);
    Phaser.Actions.GridAlign(this.grid_container.list, {
      x: SEE_MORE_LIST_GRID.x,
      y: SEE_MORE_LIST_GRID.y,
      width: SEE_MORE_LIST_GRID.columns,
      height: rows,
      cellWidth: SEE_MORE_LIST_GRID.width + SEE_MORE_LIST_GRID.margin.h,
      cellHeight: SEE_MORE_LIST_GRID.height + SEE_MORE_LIST_GRID.margin.v,
    });

    const { height } = this.grid_container.getBounds();
    if (height > SCROLLABLE_PANEL_NFT_LIST.height) {
      this.scrollable.setPositions(
        SCROLLABLE_PANEL_NFT_LIST.x,
        SCROLLABLE_PANEL_NFT_LIST.y,
        SCROLLABLE_PANEL_NFT_LIST.width,
        SCROLLABLE_PANEL_NFT_LIST.height,
        height + SEE_MORE_LIST_GRID.height / 2
      );
      this.scrollable.createScrollSection(this.grid_container);
      this.scrollable.setPreviousPosition();
      this.scrollable.createArrowControl({
        up: BUTTONS.HauntNFTListSubScene.SCROLL_UP,
        down: BUTTONS.HauntNFTListSubScene.SCROLL_DOWN,
        scrollFactor: SCROLL_FACTOR,
        duration: 500,
      });
    }
  }

  goHaunt(houseData) {
    this.scene.launch('HouseHauntScene', houseData);
  }
}
