import { set } from 'lodash';
import Phaser from 'phaser';
import { GAME_MESSAGES } from '../../../consts/Messages';
import { METAMASK_ERROR_CODE } from '../../../consts/Web3Consts';
import { MISSION_PANEL_CONFIG } from '../consts/Missions';
import { LoadingStateManager } from '../manager/LoadingStateManager';
import { SoundManager } from '../manager/SoundManager';
import { TransactionWaitingGif } from '../manager/TransactionWaitingGif';
import { IS_DEBUG_MODE } from '../OniMansionGame';
import { DataService } from '../services/DataService';
import { GameUtils } from '../Utils/GameUtils';
import { CurrencyComponent } from './CurrencyComponent';
import { MissionElement } from './MissionPanel/MissionElement';
import { Overlay } from './Overlay';
import { ScrollablePanel } from './ScrollablePanel';

export const MISSION_PANEL_LAYOUT = {
  PANEL: {
    INFO_PANEL: {
      x: 524,
      y: 284,
      DEPTH: 22,
    },
    TEXT: {
      x: 925,
      y: 290,
    },
  },
};
export class MissionPanel extends Phaser.Scene {
  constructor() {
    super({
      key: 'MissionPanel',
    });
    this.dataService = new DataService();
  }

  preload() {
    this.debug_graphics = this.add.graphics();
    this.debug_graphics.fillStyle(0x00ffff, 0.3);
    this.debug_graphics.depth = 50;
    this.loader = new LoadingStateManager(this);
    this.transactionGif = new TransactionWaitingGif(this, false);
    this.load.rexAwait(async (successCallback, failureCallback) => {
      await this.getData();

      this.loader.setVisible(false);

      successCallback();
    });
  }

  create() {
    this.input.mouse.enabled = true;
    this.overlay = new Overlay(this, true);

    this.mission_panel = this.add
      .image(473, 130, 'mission_panel')
      .setOrigin(0, 0)
      .setDepth(21);

    this.close_button = this.add
      .image(1233, 164, 'close_button')
      .setInteractive()
      .setDepth(22)
      .setOrigin(0, 0);

    this.close_button.on('pointerdown', () => {
      this.setUIVisibility(false);
      SoundManager.clickScreenSound(this);
    });

    this.setUIVisibility(true);
  }

  async canDailyBonus() {
    this.hasGenericHaunt = await this.dataService.hasGenericHaunt();
    this.hasSpecificHaunt = await this.dataService.hasSpecificHaunt();
    const claimedDailyBonusToday = await this.dataService.claimedDailyBonusToday();
    if (!claimedDailyBonusToday && !this.hasGenericHaunt && !this.hasSpecificHaunt && !this.canDailyCheckIn) {
      return true;
    } else {
      return false;
    }
  }

  async getMissions() {
    this.missionCounter = await this.dataService.getMissionCounter();
    this.mission_count_text?.destroy();
    this.canDailyCheckIn = await this.dataService.canDailyCheckIn();

    const canDailyBonus = await this.canDailyBonus();
    const can7DayStreak = await this.dataService.web3GameService.canUserGetStreakBonus(
      this.dataService.session.contractAddress,
      this.dataService.session.nft.id
    );
    this.missions = [
      {
        type: MISSION_PANEL_CONFIG.daily_check_in.type,
        amount: MISSION_PANEL_CONFIG.daily_check_in.reward,
        text: MISSION_PANEL_CONFIG.daily_check_in.text,
        enabled: this.canDailyCheckIn,
        claim_text: 'CLAIM',
      },
      {
        type: MISSION_PANEL_CONFIG.generic_haunt.type,
        amount: MISSION_PANEL_CONFIG.generic_haunt.reward,
        text: MISSION_PANEL_CONFIG.generic_haunt.text,
        enabled: true,
        claim_text: 'GO TO',
      },
      {
        type: MISSION_PANEL_CONFIG.specific_haunt.type,
        amount: MISSION_PANEL_CONFIG.specific_haunt.reward,
        text: MISSION_PANEL_CONFIG.specific_haunt.text,
        enabled: true,
        claim_text: 'GO TO',
      },
      {
        type: MISSION_PANEL_CONFIG.daily_bonus.type,
        amount: MISSION_PANEL_CONFIG.daily_bonus.reward,
        text: MISSION_PANEL_CONFIG.daily_bonus.text,
        enabled: canDailyBonus,
        claim_text: 'CLAIM',
      },
      {
        type: MISSION_PANEL_CONFIG.weekly_bonus.type,
        amount: MISSION_PANEL_CONFIG.weekly_bonus.reward,
        text: MISSION_PANEL_CONFIG.weekly_bonus.text,
        enabled: can7DayStreak,
        claim_text: 'CLAIM',
      },
    ];
    // console.log(this.missions.sort((a, b) => a.type - b.type));
  }

  async getData(create = true) {
    await this.getMissions();
    if (create) this.createInfoPanels();
  }

  async createInfoPanels(reload = false) {
    this.loader.setVisible(true);
    if (reload) {
      this.panelContainer?.destroy();
      await this.getMissions();
    }
    const info_panel_height = 128;
    this.panelContainer = this.add.container(0, 0).setDepth(24);
    this.missions.forEach((_, i) => {
      const position_y = info_panel_height * i;
      const element = new MissionElement(
        this,
        MISSION_PANEL_LAYOUT.PANEL.INFO_PANEL.x,
        MISSION_PANEL_LAYOUT.PANEL.INFO_PANEL.y + position_y,
        this.missions[i],
        i
      );

      this.panelContainer.add(element);
    });
    this.loader.setVisible(false);
    this.createMissionCounter();
  }

  createMissionCounter() {
    this.mission_count_text = this.add
      .text(905, 970, `MISSION COUNT: ${this.missionCounter | 0}`, {
        color: '#000000',
        fontStyle: 'bold',
        fontFamily: 'BlackBeard',
        fontSize: '40px',
      })
      .setDepth(23)
      .setOrigin(0, 0);
  }

  async claimMissionReward(index) {
    const mission = this.missions[index];
    this.transactionGif.setVisible(true);
    if (mission.type == MISSION_PANEL_CONFIG.daily_check_in.type) {
      await this.doDailyCheckIn();
    } else if (
      mission.type == MISSION_PANEL_CONFIG.generic_haunt.type ||
      mission.type == MISSION_PANEL_CONFIG.specific_haunt.type
    ) {
      this.doHaunt();
    } else if (mission.type == MISSION_PANEL_CONFIG.daily_bonus.type) {
      await this.claimDailyBonus(index);
    } else if (mission.type == MISSION_PANEL_CONFIG.weekly_bonus.type) {
      await this.claimWeeklyBonus();
    }
    this.scene.get('Wallet').getData();
    this.transactionGif.setVisible(false);
  }

  async claimWeeklyBonus() {
    const response = await this.dataService.claimWeeklyBonus();
    await this.handleMission(response);
  }
  async claimDailyBonus() {
    const response = await this.dataService.claimDailyBonus();
    await this.handleMission(response);
  }

  doHaunt(index) {
    this.stopAll();
    // this.add.modalMessage(GAME_MESSAGES.USE_THE_URL);
    this.scene.start('HauntChoiceScene');
    this.scene.stop('MainScreenMenus');
  }

  async doDailyCheckIn(index) {
    const response = await this.dataService.doDailyCheckIn();
    this.dataService.updateInteractedContract();
    await this.handleMission(response);
  }

  async handleMission(response) {
    if (response && response.code) {
      if (response.code === METAMASK_ERROR_CODE.REJECTED) {
        this.add.modalMessage(GAME_MESSAGES.USER_REJECTED_TX);
      } else if (response.code === METAMASK_ERROR_CODE.METAMASK_ERROR) {
        this.add.modalMessage(GAME_MESSAGES.RESET_YOUR_ACCOUNT);
      }
    } else {
      this.missionCounter = await this.dataService.getMissionCounter();
      this.createInfoPanels(true);
    }
  }

  togglePanelElements(toggle) {
    this.panelContainer.setVisible(toggle);
  }

  setUIVisibility(visible) {
    this.overlay.setVisible(visible);
    this.mission_panel.setVisible(visible);
    this.close_button.setVisible(visible);
    this.mission_count_text.setVisible(visible);
    this.panelContainer?.setVisible(visible);
    this.togglePanelElements(visible);
    this.scene.setVisible(visible);
  }

  stopAll() {
    this.scene.stop();
    this.scene.get('TraitPanelSubScene').scene.stop();
    this.scene.get('MainScreenScene').base_house.destroy();
    this.scene.stop('MainScreenScene');
    this.scene.stop('Wallet');
  }
}
