export const SHOP_GRID_LAYOUT = {
  FRAME: {
    x: 701,
    y: 134,
    columns: 3,
    rows: 3,
    width: 349,
    height: 205,
    margin: {
      h: 33,
      v: 45,
    },
    TEXT: {
      x: 259,
      y: 145,
    },
  },
};

export const PAGINATION_LAYOUT = {
  PREVIOUS: {
    x: 646,
    y: 935,
    key: `previous_button`,
    origin: {
      x: 0,
      y: 0,
    },
    alpha: 0.4,
  },
  NEXT: {
    x: 1453,
    y: 935,
    key: `next_button`,
    origin: {
      x: 0,
      y: 0,
    },
    alpha: 0.4,
  },
};

export const LOOTBOX = {
  LOOTBOX_TOGGLE_BUTTON: {
    x: 116,
    y: 241,
    key: `lootbox_button_gif`,
    origin: { x: 0, y: 0 },
  },
  LOOTBOX_WINDOW: {
    x: 837,
    y: 108,
    key: `lootbox_window`,
    origin: { x: 0, y: 0 },
  },
  BUTTONS: {
    CLOSE_BUTTON: {
      key: 'close_button',
      x: 1600,
      y: 168,
      width: 85,
      height: 85,
      depth: 1,
      // origin: { x: 0, y: 0 },
    },
    OPEN_BUTTON: {
      key: 'open_button',
      x: 1075,
      y: 706,
      width: 360,
      height: 144,
    },
    // BUY_BUTTON: {
    //   key: 'buy_button',
    //   x: 1108,
    //   y: 566,
    //   width: 296,
    //   height: 127,
    // },
  },
  OPEN_LOOTBOX_CLOSE: {
    x: 1550,
    y: 208,
    width: 85,
    height: 85,
    visible: false,
    depth: 150,
    origin: { x: 0, y: 0 },
  },
  HOUSE_PREVIEW: {
    x: 480,
    y: 270,
  },
  FRAME_OFFSET: {
    x: 37,
    y: 15,
  },
};
