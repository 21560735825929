export const BUTTONS_NAMES = {
  // main scene
  RANDOM_SEE_MORE: 'RANDOM_SEE_MORE',
  TRAITS_SEE_MORE: 'TRAITS_SEE_MORE',
  RANDOM_HAUNT: 'RANDOM_HAUNT',
  TRAITS_HAUNT: 'TRAITS_HAUNT',

  // sub scene haunt
  HAUNT_GO: 'HAUNT_GO',
  HAUNT_CLOSE: 'HAUNT_CLOSE',

  // sub scene oni list
  ONI_LIST_SEARCH: 'ONI_LIST_SEARCH',
  ONI_LIST_SEARCH_INPUT: 'ONI_LIST_SEARCH_INPUT',
  ONI_LIST_CLOSE: 'ONI_LIST_CLOSE',
};
