import { PlayerEntity } from '../../../phaser/entities/PlayerEntity';
import { MAIN_PLAYER_LAYOUT } from '../layouts/MainScreen';
import { GameUtils } from '../Utils/GameUtils';

export class HomePlayer extends Phaser.GameObjects.Container {
  /**
   *
   * @param { Phaser.Scene} scene
   * @param { boolean } isOni
   * @param { any } nft
   */
  constructor(scene, isOni, nft) {
    const { x, y } = MAIN_PLAYER_LAYOUT;
    const { originX, originY } = MAIN_PLAYER_LAYOUT.origin;
    const { width, height } = MAIN_PLAYER_LAYOUT.size;
    super(scene, x, y);
    if (isOni) {
      const config = GameUtils.mapOniApiTraits(nft);
      this.player = new PlayerEntity(scene, 0, 0, config);
      this.player.setOrigin(originX, originY);
      this.player.setDisplaySizeMultiple(width, height);
      this.player.entityBodyParts.Background.setVisible(false);
    } else {
      this.player = [];
      this.body = scene.add
        .image(0, 0, 'oni_frame_holder_body')
        .setOrigin(originX, originY)
        .setDisplaySize(width, height);
      this.frame = scene.add
        .image(0, 0, 'oni_frame_holder_frame')
        .setOrigin(originX, originY)
        .setDisplaySize(width, height);
      this.nft_image = scene.add
        .image(
          MAIN_PLAYER_LAYOUT.frame_image.x * this.frame.scaleX,
          MAIN_PLAYER_LAYOUT.frame_image.y * this.frame.scaleY,
          nft.name
        )
        .setOrigin(originX, originY)
        .setDisplaySize(
          MAIN_PLAYER_LAYOUT.frame_image.size.width * this.frame.scaleX,
          MAIN_PLAYER_LAYOUT.frame_image.size.height * this.frame.scaleY
        );
      this.hands = scene.add
        .image(0, 0, 'oni_frame_holder_hands')
        .setOrigin(originX, originY)
        .setDisplaySize(width, height);
      this.player.push(...[this.body, this.frame, this.nft_image, this.hands]);
    }
    this.depth = 50;
    this.add(this.player);
    scene.add.existing(this);
    scene.tweens.add({
      targets: this,
      y: { from: this.y, to: this.y + 10, ease: 'Sine.easeInOut' },
      duration: 2000,
      repeat: -1,
      yoyo: true,
    });
    scene.tweens.add({
      targets: this,
      alpha: { from: 1, to: 0.6, ease: 'Sine.easeInOut' },
      duration: 1500,
      repeat: -1,
      yoyo: true,
    });
  }
}
