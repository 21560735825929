import { PROJECTS } from '../../../consts/Projects';

export default {
  CHECK_NFT_OWNERSHIP: {
    x: 1164,
    y: 937,
    key: 'check_nft_ownership',
    depth: 90,
  },
  BACKGROUND: {
    x: 0,
    y: 0,
    key: 'character_select_bg',
    origin: {
      x: 0,
      y: 0,
    },
  },
  SELECT_CHARACTER_BUTTON: {
    x: 691,
    y: 763,
    key: 'select_character_button',
    origin: {
      x: 0,
      y: 0,
    },
  },
  GAME_LOGO: {
    x: 521,
    y: 113,
    key: 'game_logo',
    origin: {
      x: 0,
      y: 0,
    },
  },
  CHARACTER_SELECT_PANEL: {
    x: 201,
    y: 36,
    key: 'selection_menu_panel',
    origin: {
      x: 0,
      y: 0,
    },
  },
  BUTTON_PLAY: {
    x: 382,
    y: 900,
    origin: {
      x: 0.5,
      y: 0.5,
    },
    scale: {
      x: 0.7,
      y: 0.7,
    },
    depth: 50,
    key: 'CHS_play_game_button',
    key1: 'CHS_play_highlight',
  },
  SCROLLABLE_PANEL: {
    padding: 1,
    x: 600,
    y: 105,
    width: 1600,
    height: 870,
  },
  CLOSE_BUTTON: {
    x: 1750,
    y: 32,
    width: 85,
    height: 85,
    depth: 99950,
    origin: {
      x: 0,
      y: 0,
    },
  },
  CHARACTER_GRID: {
    COLUMNS: 3,
    x: 683,
    y: 153,
    width: 302,
    height: 302,
    extraSpaceMultiplier: 1.5,
    origin: {
      x: 0,
      y: 0,
    },
    margin: {
      h: 60,
      v: 70,
    },
  },
  CHARACTER_GRID_FRAME: {
    x: 663,
    y: 133,
    key0: 'character_frame',
    key1: 'character_frame_highlight',
    size: {
      width: 342,
      height: 342,
    },
    margin: {
      h: 20,
      v: 31,
    },
    origin: {
      x: 0,
      y: 0,
    },
  },

  ONI_TAB: {
    x: 215,
    y: 66,
    key: 'oni_tab',
    key1: 'oni_tab_highlight',
    origin: {
      x: 0,
      y: 0,
    },
  },
  LION_TAB: {
    x: 620,
    y: 66,
    key: 'lazy_lions_tab',
    key1: 'lazy_lions_tab_highlight',
    origin: {
      x: 0,
      y: 0,
    },
  },
  TAB_HIGHLIGHT: {
    ONI: 1,
    LION: 2,
  },
  TABS: {
    x: 220,
    y: 104,
    origin: {
      x: 0,
      y: 0,
    },
    margin: {
      v: 113,
    },
    items: [
      {
        raw_data: PROJECTS.ONIS,
        type: PROJECTS.ONIS.key,
        key: 'oni_tab',
        key1: 'oni_tab_highlight',
      },
      {
        raw_data: PROJECTS.LAZY_LIONS,
        type: PROJECTS.LAZY_LIONS.key,
        key: 'lazy_lions_tab',
        key1: 'lazy_lions_tab_highlight',
      },
      {
        raw_data: PROJECTS.CURIOUS_ADDYS,
        type: PROJECTS.CURIOUS_ADDYS.key,
        key: 'curious_addys_tab',
        key1: 'curious_addys_tab_highlight',
      },
      {
        raw_data: PROJECTS.BABY_SPIRITS,
        type: PROJECTS.BABY_SPIRITS.key,
        key: 'baby_spirits_tab',
        key1: 'baby_spirits_tab_highlight',
      },
      {
        raw_data: PROJECTS.JUNGLE_FREAKS,
        type: PROJECTS.JUNGLE_FREAKS.key,
        key: 'jungle_freaks_tab',
        key1: 'jungle_freaks_tab_highlight',
      },
      // {
      //   raw_data: PROJECTS.MIRROR,
      //   type: PROJECTS.MIRROR.key,
      //   key: 'mirror_world_tab',
      //   key1: 'mirror_world_tab_highlight',
      // },
    ],
  },
  FAQ_ICON: {
    x: 1750,
    y: 50,
    depth: 50,
  },
  INFO_ICON: {
    x: 1850,
    y: 50,
    depth: 50,
  },
};
