import { LoadingStateManager } from '../../manager/LoadingStateManager';
import { DataService } from '../../services/DataService';
import Layout from '../../layouts/CharacterSelectLayout';
import { ScrollablePanel } from '../ScrollablePanel';
import { CharacterSelectionGrid } from './CharacterSelectionGrid';
import { SessionManager } from '../../manager/SessionManager';
import { Web3GameService } from '../../../../services/Web3GameService';
import { PROJECTS } from '../../../../consts/Projects';
import { ERROR_MESSAGES } from '../../../../consts/Messages';
import { SoundManager } from '../../manager/SoundManager';
import { Overlay } from '../Overlay';
import { IS_DEBUG_MODE } from '../../OniMansionGame';
import { DebugUtils } from '../../Utils/DebugUtils';

export class CharacterSelectPanel extends Phaser.Scene {
  constructor() {
    super({
      key: 'CharacterSelectPanel',
    });
    Object.keys(PROJECTS).forEach((key) => {
      this[PROJECTS[key].key] = [];
    });
    this.selected_character = null;
    this.session = new SessionManager();
    this.web3GameService = new Web3GameService();
    this.dataService = new DataService();
    this.nfts = {};
  }

  async preload() {
    this.loader = new LoadingStateManager(this);
    try {
      this.dataService.clearData();

      await this.web3GameService.getConfig();
      const nfts = await this.dataService.getNFTOwnership(this.web3GameService.account);
      const connected = await this.dataService.checkNetwork();
      if (!connected) this.add.modalMessage(ERROR_MESSAGES.WRONG_NETWORK_POLYGON);
      const iterable_object = Object.keys(nfts);
      if (iterable_object.length > 0) {
        iterable_object.forEach((key) => {
          this.nfts[key] = nfts[key];
          if (this.nfts[key] && this.nfts[key].length) {
            this.nfts[key].forEach((nftConfig) => {
              const hasImage = this.textures.exists(nftConfig.name);
              if (!hasImage) {
                this.load.image(nftConfig.name, nftConfig.image);
              }
            });
          } else {
            this.loader.setVisible(false);
            this.add.modalMessage(ERROR_MESSAGES.CHARACTER_SELECT);
          }
          this.load.start();
        });
      } else {
        this.launchPanel();
      }
      this.load.on('complete', this.launchPanel, this);
    } catch (err) {
      console.log(err);
      this.add.modalMessage(err.message);
      this.loader.setVisible(false);
    }
  }

  launchPanel() {
    this.loader.setVisible(false);
    this.createLayout();
  }

  createLayout() {
    this.overlay?.destroy();
    this.overlay = new Overlay(this);
    this.panel?.destroy();
    this.panel = this.make.sprite(Layout.CHARACTER_SELECT_PANEL);
    this.close_button?.destroy();
    this.close_button = this.add
      .zone(Layout.CLOSE_BUTTON.x, Layout.CLOSE_BUTTON.y, Layout.CLOSE_BUTTON.width, Layout.CLOSE_BUTTON.height)
      .setOrigin(0, 0)
      .setDepth(Layout.CLOSE_BUTTON.depth)
      .setInteractive();
    this.close_button.on('pointerup', () => {
      SoundManager.clickScreenSound(this);
      this.scene.sendToBack();
    });
    this.btn_play?.destroy();
    this.btn_play = this.make.image(Layout.BUTTON_PLAY).setInteractive();
    this.btn_play.on('pointerup', () => {
      SoundManager.clickScreenSound(this);
      if (this.selected_character) {
        // this.dataService.setSelectedCharacter(this.selected_character);
        history.pushState({}, null, `#/oni-mansion-game/${this.contract_address}/${this.selected_character.id}`);
        // this.data_service.setSelectedCharacter(this.selected_character);
        this.scene.stop();
        this.scene.stop('CharacterSelectScene');
        this.scene.start('MainScreenScene');
      }
    });

    this.selection_grid?.container?.destroy();
    this.selection_grid = new CharacterSelectionGrid(this, this.nfts);
    this.events.removeListener('character_selected');
    this.events.on('character_selected', (payload) => {
      this.selected_character = payload.selected_nft;
      this.contract_address = payload.contract_address;
      this.session.setNft(this.selected_character);
      this.session.setContractAddress(this.contract_address);
      if (this.selected_character) this.btn_play.setTexture(Layout.BUTTON_PLAY.key1);
      else this.btn_play.setTexture(Layout.BUTTON_PLAY.key);
    });

    this.events.removeListener('reload_selection_panel');
    this.events.on('reload_selection_panel', () => this.scene.restart());
    this.events.removeListener('clear_selection');
    this.events.on('clear_selection', () => this.clearSelection());
    if (IS_DEBUG_MODE) this.debug();
  }

  debug() {
    DebugUtils.debug(this, this.close_button);
  }

  clearSelection() {
    this.selected_character = null;
    this.contract_address = null;
    this.session.setNft(null);
    this.session.setContractAddress(null);
    this.btn_play.setTexture(Layout.BUTTON_PLAY.key);
  }
}
