export const MISSION_PANEL_CONFIG = {
  daily_check_in: {
    reward: 5,
    type: 1,
    text: 'DAILY CHECK-IN',
  },
  generic_haunt: {
    reward: 5,
    type: 2,
    text: 'GENERIC HAUNT',
  },
  specific_haunt: {
    reward: 5,
    type: 3,
    text: 'SPECIFIC HAUNT',
  },
  daily_bonus: {
    reward: 10,
    type: 4,
    text: 'DAILY BONUS',
  },
  weekly_bonus: {
    reward: 30,
    type: 5,
    text: '7 DAY STREAK',
  },
  no_mission: {
    reward: 0,
    type: 100,
    text: 'CLAIMED',
  },
};
