export const HOUSE_LAYOUT = {
  'Tower Window': {
    x: 812,
    y: 386,
  },
  'Roof Window': {
    x: 1116,
    y: 412,
  },
  'Side Window': {
    x: 427,
    y: 656,
  },
  'First Front Window': {
    x: 1010,
    y: 645,
  },
  'Second Front Window': {
    x: 1162,
    y: 551,
  },
  Chimney: {
    x: 910,
    y: 79,
  },
  Door: {
    x: 816,
    y: 672,
  },
  Roof: {
    x: 481,
    y: 111,
  },
  Yard: {
    x: 311,
    y: 794,
  },
};

export const HOUSE_TREE_LAYOUT = {
  x: 1611,
  y: 866,
  depth: 1,
  key: 'tree',
  key_small: 'tree_small',
  origin: {
    originX: 0.5,
    originY: 1,
  },
};
export const HOUSE_CANDLE_LAYOUT = {
  x: 1570,
  y: 1029,
  depth: 100,
  key: 'candle',
  key_small: 'candle_small',
  origin: {
    originX: 0.5,
    originY: 1,
  },
};
