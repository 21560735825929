import Phaser from 'phaser';
import { PurchasePanel } from '../components/PurchasePanel';
import { ShopPagination } from '../components/ShopPagination';
import { LoadingStateManager } from '../manager/LoadingStateManager';
import { SoundManager } from '../manager/SoundManager';
import { IS_DEBUG_MODE } from '../OniMansionGame';
import { DataService } from '../services/DataService';
import { GameUtils } from '../Utils/GameUtils';

import { ERROR_MESSAGES, GAME_MESSAGES } from '../../../consts/Messages';
import { LOOTBOX, SHOP_GRID_LAYOUT } from '../layouts/ShopScreen';
import { ShopShelveFrame } from '../components/ShopShelveFrame';
import { CurrencyComponent } from '../components/CurrencyComponent';
import { TransactionWaitingGif } from '../manager/TransactionWaitingGif';

export class ShopScreenScene extends Phaser.Scene {
  constructor() {
    super({ key: 'ShopScreenScene' });
    this.dataService = new DataService();
    this.approved_store = false;
  }

  preload() {
    this.input.mouse.enabled = true;
    this.createStaticLayout();
    this.loader = new LoadingStateManager(this);
    this.transactionGif = new TransactionWaitingGif(this, false);
    this.load.rexAwait(async (successCallback, failureCallback) => {
      await this.getData();
      this.loader.setVisible(false);
      this.approved_store = await this.dataService.checkApprovedStore(this);
      successCallback();
    });
  }

  async getData(update = false) {
    await this.dataService.getCoins();
    this.owned_traits = this.dataService.owned_traits;
    this.coins = this.dataService.coins;
    // console.log(this.coins);
    await this.getAvailableTraits();
    if (this.pagination) {
      this.pagination.complete_list = this.not_bought_traits;
      this.pagination.getCurrentData(false);
      this.current_traits = this.pagination.current_page_list;
      this.createShelvesList();
    }
  }

  async getAvailableTraits() {
    const result = await this.dataService.getAvailableTraits(true);
    const unowned_traits = result;
    this.not_bought_traits = unowned_traits.filter(
      (trait) => trait.value > 0 && !this.owned_traits.some((owned_trait) => owned_trait.id === trait.id)
      // (trait) => !this.owned_traits.some((owned_trait) => owned_trait.id === trait.id)
    );
  }

  createStaticLayout() {
    this.background_stuffs_overlay = this.add
      .image(0, 0, `background_stuffs_overlay`)
      .setOrigin(0, 0)
      .setDisplaySize(this.game.canvas.width, this.game.canvas.height);
    this.shop_shelves = this.add.image(664, 208, `shop_shelves`).setOrigin(0, 0);
    this.jack_the_seller = this.add.image(128, 373, `jack_the_seller`).setOrigin(0, 0);
    this.shop_sign = this.add.image(59, 0, `shop_sign`).setOrigin(0, 0);
    this.shop_name = this.add
      .image(950, 18, `shop_name`)
      .setOrigin(0, 0)
      .setInteractive();
    this.lootbox_toggle = this.make.sprite(LOOTBOX.LOOTBOX_TOGGLE_BUTTON).setInteractive();
    this.lootbox_toggle.play('lootbox_button_animation');
    this.back_button = this.add
      .image(190, 922, `back_button`)
      .setOrigin(0, 0)
      .setInteractive();
    this.wallet = this.add.image(1093, 916, `wallet`).setOrigin(0, 0);
  }

  createAssets() {
    // this.background_shop = this.add
    //   .image(0, 0, `background_shop`)
    //   .setOrigin(0, 0)
    //   .setDisplaySize(this.game.canvas.width, this.game.canvas.height);

    this.pagination = new ShopPagination(this, this.not_bought_traits);
    this.current_traits = this.pagination.current_page_list;

    this.coins_text = new CurrencyComponent(this, 1133, 927, this.coins | 0, '82px').setDepth(1);
    this.createShelvesList();
  }

  createShelvesList() {
    this.shelves_container?.destroy();
    this.shelves_container = this.add.container(0, 0);
    this.current_traits.forEach((item) => {
      const shelve_frame = new ShopShelveFrame(
        this,
        `${GameUtils.folderName(item.trait_type)}/${item.name}`,
        `${item.value}`
      );
      shelve_frame.on('pointerdown', async () => {
        // console.log(item);
        if (this.approved_store) {
          SoundManager.clickScreenSound(this);
          this.purchase_panel.showPanel(item);
        } else {
          this.approved_store = await this.dataService.checkApprovedStore(this);
        }
      });
      this.shelves_container.add(shelve_frame);
    });

    Phaser.Actions.GridAlign(this.shelves_container.list, {
      x: SHOP_GRID_LAYOUT.FRAME.x + (SHOP_GRID_LAYOUT.FRAME.width + SHOP_GRID_LAYOUT.FRAME.margin.h) / 2,
      y: SHOP_GRID_LAYOUT.FRAME.y + (SHOP_GRID_LAYOUT.FRAME.height + SHOP_GRID_LAYOUT.FRAME.margin.v) / 2,
      width: SHOP_GRID_LAYOUT.FRAME.columns,
      height: SHOP_GRID_LAYOUT.FRAME.rows,
      cellWidth: SHOP_GRID_LAYOUT.FRAME.width + SHOP_GRID_LAYOUT.FRAME.margin.h,
      cellHeight: SHOP_GRID_LAYOUT.FRAME.height + SHOP_GRID_LAYOUT.FRAME.margin.v,
    });

    this.debug();

    this.loader.setVisible(false);
  }

  create() {
    if (IS_DEBUG_MODE) {
      if (this.debug_graphics) this.debug_graphics.destroy();
      this.debug_graphics = this.add.graphics();
      this.debug_graphics.fillStyle(0xffff00, 0.5);
      this.debug_graphics.setDepth(3);
    }
    this.purchase_panel = new PurchasePanel(this);

    this.createAssets();
    this.cameras.main.once('camerafadeoutcomplete', (camera) => {
      this.scene.start('MainScreenScene');
    });
    this.lootbox_toggle.on('pointerup', () => {
      this.input.mouse.enabled = false;
      SoundManager.clickScreenSound(this);
      this.scene.launch('LootboxWindow');
    });
    this.back_button.on('pointerdown', (pointer) => {
      SoundManager.clickScreenSound(this);
      // console.log('Back');
      if (pointer.leftButtonDown()) {
        delete this.pagination;
        this.events.removeListener('update_scene_data');
        this.events.removeListener('paginated_data');
        this.cameras.main.fadeOut(200);
      }
    });
    this.events.removeListener('update_scene_data');

    this.events.on('update_scene_data', async (update = true) => {
      if (update) {
        await this.getData(update);
        if (this.coins_text) this.setCoins();
      }
    });
    this.events.removeListener('paginated_data');
    this.events.on('paginated_data', async (update = true) => {
      this.current_traits = this.pagination.current_page_list;
      this.createShelvesList();
    });
  }

  setCoins() {
    this.coins_text.updateCoinAmount(`${this.coins | 0}`);
  }

  debug() {
    if (IS_DEBUG_MODE) {
      this.debug_graphics?.destroy();
      this.debug_graphics = this.add.graphics(0, 0);

      this.shelves_container.iterate((frame) => {
        this.debug_graphics.fillStyle(0x00fffff, 0.5);
        this.debug_graphics.fillRectShape(frame.getBounds());
      });
    }
  }
}
