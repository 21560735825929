import { BUTTONS_NAMES } from '../consts/ErrorModalScene';

export const IMAGES = {
  MODAL: {
    x: 495,
    y: 348,
    key: 'error_box',
    origin: { x: 0, y: 0 },
  },
};

export const BUTTONS = {
  CLOSE: {
    x: 1390,
    y: 365,
    width: 85,
    height: 85,
  },
};

export const TEXTS = {
  MESSAGE: {
    style: {
      fontSize: '50px',
      fontFamily: 'BlackBeard',
      color: '#000000',
      wordWrap: {
        width: 700,
      },
      align: 'center',
      wordWrapUseAdvanced: false,
    },
    origin: 0.5,
  },
};

export const DELAY = {
  FADE_IN: 800,
};
