import { GameUtils } from '../Utils/GameUtils';

export const LOGOUT_BUTTON = {
  x: 10,
  y: 1050,
  key: 'logout',
  origin: {
    x: 0,
    y: 1,
  },
  depth: 10,
};

export const TRAIT = {
  TEXTURE: 'customization_frames',
  FRAME: ({ trait_type, name }) => `${GameUtils.folderName(trait_type)}/${name}`,
  GRID: {
    WIDTH_AMOUNT: 2,
    HEIGHT_AMOUNT: Infinity,
  },
  DEPTH: 15,
  POSITION: {
    X: 1545.1565557729941,
    Y: 347.84375,
  },
  SIZE: {
    WIDTH: 160,
    HEIGHT: 123,
  },
  ORIGIN: {
    X: 0,
    Y: 0,
  },
  MARGIN: {
    H: 3,
    V: 3,
  },
  SCROLL: {
    X: 1466,
    Y: 294,
    WIDTH: 317,
    HEIGHT: 489,
    LIMITS: 100,
    up: {
      x: 1420,
      y: 257,
    },
    down: {
      x: 1420,
      y: 767,
    },
  },
};

export const MAIN_PLAYER_LAYOUT = {
  x: 0,
  y: 607,
  size: {
    width: 450,
    height: 450,
  },
  origin: {
    originX: 0,
    originY: 0,
  },
  frame_image: {
    x: 444,
    y: 363,
    size: {
      width: 302,
      height: 302,
    },
  },
};

export const MAILBOX = {
  x: 1750,
  y: 985,
  depth: 10,
  texture: 'mailbox',
  frame: 'mailbox_flag_down',
};

export const MAILBOX_PANEL = {
  CLOSE_BUTTON: {
    x: 1289,
    y: 209,
    width: 85,
    height: 85,
  },
  MAIL_BOX_ELEMENT: {
    TEXT: {
      x: 170,
      y: 25,
      width: 505,
      height: 55,
      style: {
        fontSize: '28px',
        fontFamily: 'BlackBeard',
        color: '#000000',
        wordWrap: {
          width: 505,
          useAdvancedWrap: true,
        },
      },
      origin: {
        x: 0,
        y: 0,
      },
    },
  },
  SCROLL_PANEL: {
    x: 570,
    y: 345,
    width: 715,
    height: 505,
  },
  MAIL_BOX_GRID: {
    columns: 1,
    x: 578,
    y: 345,
    width: 705,
    height: 113,
  },
};
