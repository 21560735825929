import Phaser from 'phaser';
import { DataService } from '../services/DataService';
import { House } from '../entities/House';
import { HOUSE_TRAITS_CONFIG } from '../consts/HouseTraits';
import { SoundManager } from '../manager/SoundManager';
import { ERROR_MESSAGES, GAME_MESSAGES } from '../../../consts/Messages';
import { METAMASK_ERROR_CODE } from '../../../consts/Web3Consts';
import { LoadingStateManager } from '../manager/LoadingStateManager';
import { GameUtils } from '../Utils/GameUtils';
import { HomePlayer } from '../entities/HomePlayer';
import { PROJECTS } from '../../../consts/Projects';
import { HoverComponent } from '../components/HoverComponent';
import { InfoIconComponent } from '../components/InfoIconComponent';
import { LightCandleFeedback } from '../components/LightCandleFeedback';

export const BUTTONS_NAMES = {
  GENERIC_HAUNT: 'GENERIC_HAUNT',
  SPECIFIC_HAUNT: 'SPECIFIC_HAUNT',
  LIGHT_CANDLE: 'LIGHT_CANDLE',
  EXIT: 'EXIT',
};

/**
 * The method that will be called using `DataService` class instance to network purpouses
 */
const BUTTONS_NETWORK_BEHAVIOR = {
  [BUTTONS_NAMES.GENERIC_HAUNT]: 'genericHauntHouse',
  [BUTTONS_NAMES.SPECIFIC_HAUNT]: 'specificHauntHouse',
};

// const INFO_TYPE = {
//   DAILY_CHECK_IN: 1,
//   ALREADY_HAUNTED: 2,
//   NO_BUTTON: 3,
// };

export class HouseHauntScene extends Phaser.Scene {
  constructor() {
    super({ key: 'HouseHauntScene' });
    /**
     * @type {DataService}
     */
    this.dataService = new DataService();
    /**
     * @type {HOUSE_TRAITS_CONFIG}
     */
    this.houseConfig;
    /**
     * @type {House}
     */
    this.baseHouse;
    /**
     * @type {Array<Phaser.GameObjects.Sprite>}
     */
    this.buttons = [];
    this.canPlayerHaunt = {
      [BUTTONS_NAMES.GENERIC_HAUNT]: false,
      [BUTTONS_NAMES.SPECIFIC_HAUNT]: false,
      canCheckin: true,
    };
    // this.info_button_type = INFO_TYPE.DAILY_CHECK_IN;
  }

  init({ contract_address, nft_id, nft_info }) {
    const vue = this.registry.get('vue');
    if (vue.$route.name === 'oni-game-specific-mansion') {
      history.pushState({}, null, `#/oni-mansion-game/${contract_address}/${nft_id}`);
      this.houseParams = { ...GameUtils.getContractNFTID(location.href), nft_info };
    } else {
      this.houseParams = { contract_address, nft_id, nft_info };
      vue.$router.push({ name: 'oni-game-specific-mansion', params: { contract_address, nft_id } });
    }
  }

  preload() {
    this.messageMenuSubScene = this.scene.get('MessageModalSubScene');
    this.loader = new LoadingStateManager(this);
    this.dataService.should_reload_data = true;
    this.isOni = false;
    this.buttons = [];
    this.load.rexAwait(async (success, error) => {
      try {
        const connected = await this.dataService.checkNetwork();
        if (!connected && window?.ethereum) {
          this.add.modalMessage(ERROR_MESSAGES.WRONG_NETWORK_POLYGON_RELOAD);
        } else {
          await this.dataService.getWeb3Config();
          const [houseData, canPlayerHaunt] = await Promise.all([
            this.fetchHouseData.bind(this)(),
            this.fetchIfPlayerCanHaunt.bind(this)(),
            this.dataService.getSpecificHauntMissionNFTIDs(),
          ]);
          if (!this.houseParams.nft_info) {
            const project = GameUtils.getProject(this.houseParams.contract_address);
            await this.fetchPlayerData(project, this.houseParams.nft_id);
            await this.updateCandleCounter(this.houseParams.contract_address, this.houseParams.nft_id);
          }
          this.houseConfig = houseData;
          this.canPlayerHaunt = canPlayerHaunt;
          this.load.image(this.houseParams.nft_info.name, this.houseParams.nft_info.image);

          if (this.houseParams.contract_address === PROJECTS.ONIS.contract_address) {
            this.isOni = true;
            this.houseParams.nft_info.attributes.forEach((item) => {
              const hasTexture = this.textures.exists(item.value);
              if (!hasTexture) {
                this.load.image(
                  `${item.trait_type}_${item.value}`,
                  `${process.env.VUE_APP_SERVER_URI}/traits/${item.trait_type}/${item.value}.png`
                );
              }
            });
            this.load.start();
          }
          this.load.on('complete', () => {
            this.player?.destroy();
            this.player = new HomePlayer(this, this.isOni, this.houseParams.nft_info);
          });
          this.load.start();
          this.loader.setVisible(false);
          success();
        }
      } catch (err) {
        console.error(err);
        this.add.modalMessage('Something bad happened.');
        error();
      }
    });
  }

  async updateCandleCounter(project, nft_id) {
    this.candle_counter = await this.dataService.getCandleLightCounter(project, nft_id);
  }

  create() {
    this.baseHouse = new House(
      this,
      0,
      0,
      { ...this.houseConfig.attrs },
      false,
      this.houseConfig.hauntCounter,
      this.houseConfig.candleCounter
    );
    this.baseHouse.candle_counter.updateCandleCounter(this.candle_counter);
    this.addButtons();
    this.events.removeListener('haunt');
    this.events.removeListener('messageboxclosed');
    this.events.on('haunt', this.haunt, this);
    this.events.on('messageboxclosed', this.closeLoader, this);
    if (this.dataService.session?.contractAddress) {
      this.createBackButton();
    }
  }

  async haunt({ type, message }) {
    try {
      if (message.length > 0) {
        await this.createHaunt(
          type,
          message,
          type === 'genericHauntHouse' ? BUTTONS_NAMES.GENERIC_HAUNT : BUTTONS_NAMES.SPECIFIC_HAUNT
        );
      } else {
        this.add.modalMessage(ERROR_MESSAGES.LEAVE_A_MESSAGE);
        this.scene.get('HauntMessageBoxSubScene').events.emit('removeloaderGIF');
      }
    } catch (err) {
      console.error(err);
      this.add.modalMessage('Something bad happened.');
      this.loader.setVisible(false);
    }
  }

  closeLoader() {
    this.loader?.setVisible(false);
  }

  goBackRoute() {
    const contract_address = this.dataService.session?.contractAddress;
    const nft_id = this.dataService?.session?.nft?.id;
    this.scene.stop();
    history.pushState({}, null, `#/oni-mansion-game/${contract_address}/${nft_id}`);
  }

  createBackButton() {
    this.back_buton = this.make
      .sprite({
        id: BUTTONS_NAMES.EXIT,
        key: 'back_button_no_text',
        x: 15,
        y: 930,
      })
      .setScale(0.8)
      .setOrigin(0)
      .setInteractive();

    this.back_buton.on('pointerup', () => {
      SoundManager.clickScreenSound(this);
      this.scene.start(`HauntChoiceScene`);
      this.goBackRoute();
    });
  }

  async fetchPlayerData(project, id) {
    const { data } = await this.dataService[project.key]([id]);
    this.houseParams.nft_info = data[0];
  }

  addButtons() {
    let HAUNT_BUTTONS = [];
    if (this.dataService?.session?.sessionToken && this.houseParams.contract_address) {
      HAUNT_BUTTONS = [
        {
          id: BUTTONS_NAMES.GENERIC_HAUNT,
          texture: 'generic_haunt_button',
          x: 43,
          y: 44,
          onClick: () => this['showHauntBoxMessage'](BUTTONS_NAMES.GENERIC_HAUNT),
        },
      ];

      if (
        this.dataService.specific_haunt_nfts_project[this.houseParams.contract_address].find(
          (v) => v === this.houseParams.nft_id
        )
      ) {
        HAUNT_BUTTONS.push({
          id: BUTTONS_NAMES.SPECIFIC_HAUNT,
          texture: 'specific_haunt_button',
          x: 43,
          y: 151,
          onClick: () => this['showHauntBoxMessage'](BUTTONS_NAMES.SPECIFIC_HAUNT),
        });
      }
    }
    this.buttons = [
      ...HAUNT_BUTTONS,
      {
        id: BUTTONS_NAMES.LIGHT_CANDLE,
        texture: 'light_candle',
        x: 1670,
        y: 970,
        onClick: this[BUTTONS_NAMES.LIGHT_CANDLE],
      },
    ].map(this.addButton, this);
    this.info_icons_containter?.destroy();
    this.info_icons_containter = this.add.container(0, 0);
    [BUTTONS_NAMES.GENERIC_HAUNT, BUTTONS_NAMES.SPECIFIC_HAUNT].forEach((buttonAttr) => {
      if (!this.canPlayerHaunt[buttonAttr] || this.canPlayerHaunt.canCheckin) this.disableButton(buttonAttr);
    });
  }

  addButton({ id, texture, x, y, onClick, pointerOver }) {
    /**
     * @type {Phaser.GameObjects.Sprite}
     */
    const btn = this.add.sprite(x, y, texture);
    btn
      .setInteractive()
      .on('pointerdown', onClick, this)
      .setOrigin(0)
      .setDepth(55);
    if (pointerOver) {
      btn.on('pointerover', pointerOver, this);
    }
    btn.id = id;
    return btn;
  }
  disableButton(id) {
    const button = this.buttons.find((button) => button.id == id);
    if (button) {
      button.setAlpha(0.4);
      button.input.enabled = false;
      let text = GAME_MESSAGES.HAUNT_NEW_DAY_STARTED;
      if (!this.canPlayerHaunt.canCheckin) {
        text = GAME_MESSAGES.MISSION_ALREADY_COMPLETED;
      }
      this.info_icons_containter.add(
        new InfoIconComponent(this, button.x + 30 + button.width, button.y + button.height / 2, text)
      );
    }
  }
  async showHauntBoxMessage(hauntType) {
    SoundManager.clickScreenSound(this);
    this.scene.launch('HauntMessageBoxSubScene', {
      ...this.houseParams,
      ...{ hauntType: BUTTONS_NETWORK_BEHAVIOR[hauntType] },
    });
  }
  /**
   *
   * @param {string} methodName
   * The method name provided by `DataService` to hunt can be `genericHauntHouse` or `specificHauntHouse`
   * @param {string} message
   * The message that player will send to another player
   */
  async createHaunt(methodName, message, hauntType) {
    const { contract_address, nft_id } = this.houseParams;
    try {
      const response = await this.dataService[methodName](contract_address, nft_id, message);
      if (response && !response.code) {
        this.add.modalMessage(GAME_MESSAGES.HAUNT_SUCCESS);
        this.scene.stop('HauntMessageBoxSubScene');
        this.disableButton(hauntType);
      } else if (response && response.code === METAMASK_ERROR_CODE.REJECTED) {
        this.add.modalMessage(GAME_MESSAGES.USER_REJECTED_TX);
        this.scene.get('HauntMessageBoxSubScene').events.emit('removeloaderGIF');
      }
      this.loader.setVisible(false);
      this.scene.get('HauntMessageBoxSubScene').events.emit('removeloader');
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  async [BUTTONS_NAMES.LIGHT_CANDLE]() {
    const response = await this.dataService.checkSessionCandles(this);
    if (response) {
      const candleLight = await this.dataService.lightCandle();
      if (!candleLight.success) this.add.modalMessage(GAME_MESSAGES.ALREADY_LIT_CANDLE);
      else {
        new LightCandleFeedback(this);
        await this.updateCandleCounter(this.houseParams.contract_address, this.houseParams.nft_id);
        this.baseHouse.candle_counter.updateCandleCounter(this.candle_counter);
      }
    }
  }
  async fetchHouseData() {
    const { contract_address, nft_id } = this.houseParams;
    try {
      await this.dataService.getAvailableTraits(); // You have to load all attributes before you get the equiped house attributes.
      const [attrs, hauntCounter, candleCounter] = await Promise.all([
        this.dataService.getHouseInformation(contract_address, nft_id, true),
        this.dataService.getHauntedCountContract(contract_address, nft_id),
        this.dataService.getCandleLightCounter(contract_address, nft_id),
      ]);
      // console.log({ attrs, hauntCounter, candleCounter });
      return { attrs: attrs, hauntCounter, candleCounter };
    } catch (err) {
      throw err;
    }
  }
  async fetchIfPlayerCanHaunt() {
    try {
      const [generic, specific, canCheckin] = await Promise.all([
        this.dataService.hasGenericHaunt(),
        this.dataService.hasSpecificHaunt(),
        this.dataService.canDailyCheckIn(),
      ]);
      return {
        [BUTTONS_NAMES.GENERIC_HAUNT]: canCheckin ? false : generic,
        [BUTTONS_NAMES.SPECIFIC_HAUNT]: canCheckin ? false : specific,
        canCheckin,
      };
    } catch (err) {
      throw err;
    }
  }
}
