import Phaser from 'phaser';
import { House } from '../entities/House';
import { LoadingStateManager } from '../manager/LoadingStateManager';
import { SoundManager } from '../manager/SoundManager';
import { IS_DEBUG_MODE } from '../OniMansionGame';
import { DataService } from '../services/DataService';
import { onCameraFadeout } from '../Utils/ScenePromisify';
import { HOUSE_TRAITS_CONFIG } from '../consts/HouseTraits';
import { SessionManager } from '../manager/SessionManager';
import { PROJECTS } from '../../../consts/Projects';
import { HomePlayer } from '../entities/HomePlayer';
import { LOGOUT_BUTTON } from '../layouts/MainScreen';
import { Mailbox } from '../entities/Mailbox';
import { ScrollablePanel } from '../components/ScrollablePanel';
import { TransactionWaitingGif } from '../manager/TransactionWaitingGif';
import { LorePanel } from '../components/Lores/LorePanel';
import { TwitterBannerDownload } from '../components/TwitterBannerDownload';

export class MainScreenScene extends Phaser.Scene {
  constructor() {
    super({ key: 'MainScreenScene' });
    window.debug = this;
    this.showDebug = IS_DEBUG_MODE;
    this.active_traits;
    this.traits_zone = [];
    /**
     * @type {DataService}
     */
    this.dataService = new DataService();
    this.session = new SessionManager();
    this.haunt_counter = 0;
    this.candle_counter = 0;
  }
  async getConfig() {
    await this.dataService.web3GameService.getConfig();
  }
  async getData(reload = false) {
    return new Promise(async (resolve, reject) => {
      try {
        this.owned_traits = [];
        await this.dataService.getAvailableTraits();
        const calls = [
          this.dataService.getHouseInformation(),
          this.dataService.getHauntedCountContract(),
          this.dataService.getCandleLightCounter(),
          this.dataService.getReceivedMessages(),
        ];
        Promise.all(calls).then(async (response) => {
          await this.dataService.checkMissingAttributes();
          this.active_traits = response[0];
          this.haunt_counter = response[1];
          this.candle_counter = response[2];
          this.messages = response[3];
          // console.log('CANDLE COUNT', this.candle_counter);
          // console.log('HAUNT COUNT', this.haunt_counter);
          if (this.base_house) {
            this.base_house.updateTraitFrames(this.active_traits);
            this.base_house.tree.updateTreeFrame(this.haunt_counter);
            this.base_house.candles.updateTreeFrame(this.candle_counter);
            this.base_house.candle_counter.updateCandleCounter(this.candle_counter);
            if (!reload) this.scene.launch('TraitPanelSubScene');
          }
          if (this.mailbox && this.messages.length > 0) this.mailbox.updateMessages(this.messages);
          this.owned_traits = this.dataService.owned_traits;
          this.dataService.should_reload_data = false;
          resolve();
        });
      } catch (err) {
        console.error(err);
        reject();
      }
    });
  }

  preload() {
    this.loader = new LoadingStateManager(this);
    this.transactionGif = new TransactionWaitingGif(this, false);
    this.createBaseLayout();
    this.load.rexAwait(async (successCallback, failureCallback) => {
      await this.getConfig();
      await this.getData();
      this.messages = await this.dataService.getReceivedMessages();
      successCallback();
    });

    if (this.session.contractAddress === PROJECTS.ONIS.contract_address) {
      this.session.nft.attributes.forEach((item) => {
        const hasTexture = this.textures.exists(item.value);
        if (!hasTexture) {
          this.load.image(
            `${item.trait_type}_${item.value}`,
            `${process.env.VUE_APP_SERVER_URI}/traits/${item.trait_type}/${item.value}.png`
          );
        }
        this.load.start();
      });
    } else {
      this.load.image(this.session.nft.name, this.session.nft.image);
    }
    this.load.on('complete', () => {
      if (this.session.contractAddress === PROJECTS.ONIS.contract_address) this.createPlayer();
      else this.createPlayer(false);
      this.loader.setVisible(false);
    });
  }

  async reloadData() {
    this.active_traits = await this.dataService.getEquipedHouseAttributes();
  }

  createPlayer(isOni = true) {
    if (this.player) this.player.destroy();
    this.player = new HomePlayer(this, isOni, this.session.nft);
  }

  createBaseLayout() {
    this.active_traits = { ...HOUSE_TRAITS_CONFIG };
    this.base_house = new House(this, 0, 0, this.active_traits, false, this.haunt_counter);
    this.mailbox = new Mailbox(this);
    this.logo = this.add.image(91, 20, 'logo').setOrigin(0, 0);
    this.main_menu = this.add
      .image(140, 220, 'main_menu')
      .setOrigin(0, 0)
      .setDepth(10);
  }

  showLore() {
    if (this.scene.get('LorePanel')) {
      this.scene.launch('LorePanel');
    } else {
      this.scene.add('LorePanel', LorePanel, true);
    }
  }

  create() {
    this.showLore();
    this.logout_button = this.make.image(LOGOUT_BUTTON).setInteractive();
    this.logout_button.on('pointerup', async (pointer) => {
      // console.log(pointer);
      if (pointer.leftButtonReleased()) {
        this.base_house.destroy();
        this.stopScenes();
        this.scene.start('CharacterSelectScene', true);
        this.dataService.main_song.destroy();
      }
    });

    this.debug_graphics = this.add.graphics().setDepth(50);
    this.debug_graphics.fillStyle(0xffff55, 0.3);
    // Click Zones
    this.shop_click_zone = this.add
      .zone(164, 311, 313, 69)
      .setOrigin(0, 0)
      .setInteractive();
    this.shop_click_zone.on('pointerdown', async () => {
      this.input.mouse.enabled = false;
      SoundManager.clickScreenSound(this);
      this.cameras.main.fadeOut(200, 0, 0, 0);
      this.base_house.destroy();
      this.stopScenes();
      await onCameraFadeout(this);
      this.scene.start('ShopScreenScene');
    });
    this.missions_click_zone = this.add
      .zone(164, 408, 313, 69)
      .setOrigin(0, 0)
      .setInteractive();
    this.missions_click_zone.on('pointerdown', () => {
      this.input.mouse.enabled = false;
      SoundManager.clickScreenSound(this);
      this.scene.launch('MissionPanel');
    });
    this.haunt_click_zone = this.add
      .zone(164, 505, 313, 69)
      .setOrigin(0, 0)
      .setInteractive();
    this.haunt_click_zone.on('pointerdown', async () => {
      this.input.mouse.enabled = false;
      SoundManager.clickScreenSound(this);
      this.stopScenes();
      this.cameras.main.fadeOut(200, 0, 0, 0);
      this.base_house.destroy();
      await onCameraFadeout(this);
      this.scene.start('HauntChoiceScene');
    });

    if (this.showDebug) {
      this.debug_graphics.fillRectShape(this.shop_click_zone);
      this.debug_graphics.fillRectShape(this.missions_click_zone);
      this.debug_graphics.fillRectShape(this.haunt_click_zone);
    }
    this.scene.launch('MainScreenMenus');
    this.scene.launch('Wallet');
  }

  stopScenes() {
    this.scene.get('TraitPanelSubScene').scene.stop();
    this.scene.stop('MainScreenMenus');
    this.scene.stop('MissionPanel');
    this.scene.stop('Wallet');
    this.scene.remove('LorePanel');
  }

  toggleMenus() {
    this.main_menu.setVisible(!this.main_menu.visible);
    this.missions_click_zone.setVisible(!this.missions_click_zone.visible);
    this.shop_click_zone.setVisible(!this.shop_click_zone.visible);
    this.logo.setVisible(!this.logo.visible);
    this.logout_button.setVisible(!this.logout_button.visible);
    this.scene.get('TraitPanelSubScene').events.emit('setvisibility', !this.main_menu.visible);
    this.scene.get('Wallet').scene.setVisible(!this.scene.get('Wallet').scene.isVisible());
  }
}
