export const TransactionGIFAnimationConfig = {
  atlas: 'transaction_gif',
  animations: [
    {
      frameRate: 9,
      key: 'tx',
      name: 'transactionLoop',
      frameLength: 16,
      repeat: -1,
    },
  ],
};
