import { GAME_MESSAGES } from '../../../../consts/Messages';
import { MISSION_PANEL_CONFIG } from '../../consts/Missions';
import { SoundManager } from '../../manager/SoundManager';
import { IS_DEBUG_MODE } from '../../OniMansionGame';
import { DebugUtils } from '../../Utils/DebugUtils';
import { CurrencyComponent } from '../CurrencyComponent';
import { HOVER_COMPONENT_ORIGIN } from '../HoverComponent';
import { InfoIconComponent } from '../InfoIconComponent';
import { MISSION_PANEL_LAYOUT } from '../MissionPanel';

export class MissionElement extends Phaser.GameObjects.Container {
  /**
   *
   * @param {Phaser.Scene} scene
   * @param {number} x
   * @param {number} y
   */
  constructor(scene, x, y, config, index) {
    super(scene, x, y);
    const text_style = {
      fontStyle: 'bold',
      fontSize: '50px',
      fontFamily: 'BlackBeard',
      color: '#000000',
    };
    this.scene = scene;
    this.config = config;
    this.name_panel = scene.add.image(0, 0, 'mission_name_panel').setOrigin(0, 0);
    this.price_panel = scene.add.image(378, 0, 'mission_price_panel').setOrigin(0, 0);
    this.claim_panel = scene.add
      .image(520, -12, 'mission_claim_panel')
      .setOrigin(0, 0)
      .setInteractive();
    const claim_center = this.claim_panel.getCenter();
    this.claim_panel_text = scene.add
      .text(claim_center.x, claim_center.y, this.config.claim_text, text_style)
      .setOrigin(0.5, 0.5);
    this.claim_panel.alpha = !this.config.enabled ? 0.6 : 1;
    const name_center = this.name_panel.getCenter();
    this.mission_name_text = scene.add
      .text(name_center.x, name_center.y, this.config.text, text_style)
      .setOrigin(0.5, 0.5);
    this.mission_currency_text = new CurrencyComponent(
      scene,
      this.price_panel.x + 30,
      this.price_panel.y + 12,
      this.config.amount,
      '60px'
    );
    if (this.config.type != MISSION_PANEL_CONFIG.no_mission.type) {
      this.claim_panel.setInteractive();
      this.claim_panel.on('pointerup', async (pointer) => {
        SoundManager.clickScreenSound(scene);
        if (this.config.enabled) await scene.claimMissionReward(index);
      });
      if (IS_DEBUG_MODE) {
        DebugUtils.debug(this.scene, this.claim_panel);
      }
    }
    this.add([
      this.name_panel,
      this.price_panel,
      this.claim_panel,
      this.mission_name_text,
      this.mission_currency_text,
      this.claim_panel_text,
    ]);
    if (this.config.type == MISSION_PANEL_CONFIG.weekly_bonus.type) {
      this.addInfoIcon(GAME_MESSAGES.WEEKLY_BONUS_REQUIREMENTS);
    } else if (this.config.type == MISSION_PANEL_CONFIG.daily_bonus.type) {
      this.addInfoIcon(GAME_MESSAGES.DAILY_BONUS_REQUIREMENTS);
    }
    scene.add.existing(this);
  }

  addInfoIcon(text) {
    this.info_icon = new InfoIconComponent(this.scene, 35, 48, text, HOVER_COMPONENT_ORIGIN.TOP_RIGHT);
    this.info_icon.setDepth(3);
    this.info_icon.info_button_sprite.setDisplaySize(35, 35);
    this.add(this.info_icon);
  }
}
