import Phaser from 'phaser';
import { ERROR_MESSAGES } from '../../../consts/Messages';
import { GAME_ERROR_CODE } from '../../../consts/Web3Consts';
import { AnimationConfig } from '../animations/AnimationsConfig';
import { ATLAS_CONFIG } from '../consts/AtlasConfig';
import { SOUND_EFFECTS_ASSETS } from '../consts/SoundEffectsAssets';
import SPRITE_SHEETS_ARRAY from '../consts/SpriteSheets';
import { DataService } from '../services/DataService';
import { GameUtils } from '../Utils/GameUtils';

/**
 * This Scene is responsible for loading all game assets of the game.
 * While it is loading, it will present a progressbar with the loading progress.
 * @class
 */
export class PreloadScene extends Phaser.Scene {
  /**
   * This Scene is responsible for loading all game assets of the game.
   * While it is loading, it will present a progressbar with the loading progress.
   */
  constructor() {
    super({
      key: 'PreloadScene',
    });

    /**
     * The Progress Bar that will be updated with the current loading value.
     * @type { Phaser.GameObjects.Graphics }
     */
    this.progressBar = null;

    /**
     * The Background of the Progress Bar.
     * @type { Phaser.GameObjects.Graphics }
     */
    this.progressBox = null;

    /**
     * The curretn camera Width.
     * @type { numnber }
     */
    this.cameraWidth = 0;

    /**
     * The current camera Height.
     * @type { number }
     */
    this.cameraHeight = 0;

    /**
     * The loading text.
     * @type { Phaser.GameObjects.Text }
     */
    this.loadingText = null;

    /**
     * The percentage text.
     * @type { Phaser.GameObjects.Text }
     */
    this.percentText = null;

    /**
     * The current progress value.
     * @type { number }
     */
    this.currentValue = 0;

    /**
     * The Progressbar (background / box) tarting X position.
     * @type { number }
     */
    this.boxStartingX = 10;

    /**
     * The progressbar (background / box) height.
     * @type { number }
     */
    this.boxHeight = 50;
    /**
     * The progressbar (background / box) padding.
     * @type { number }
     */
    this.boxPadding = 30;

    /**
     * The progress (background / box) margin from the text.
     * @type { number }
     */
    this.boxMargin = 30;

    /**
     * The progress bar height.
     * @type { number }
     */
    this.barHeight = 30;

    /**
     * The progressbar starting X position.
     * @type { number }
     */
    this.barStartingX = 20;

    /**
     * The progressbar margin from the text.
     * @type { number }
     */
    this.barMargin = 40;

    /**
     * The scene that is loaded after the preload scene is done loading assets.
     * @type { string }
     * */
    // this.AFTER_LOAD_SCENE = this.AFTER_LOAD_SCENE || 'HauntChoiceScene';
    this.AFTER_LOAD_SCENE = this.AFTER_LOAD_SCENE || 'CharacterSelectScene';
    // this.AFTER_LOAD_SCENE = this.AFTER_LOAD_SCENE || 'ShopScreenScene';
    // this.AFTER_LOAD_SCENE = this.AFTER_LOAD_SCENE || 'MobileCheckScene';
    // this.AFTER_LOAD_SCENE = this.AFTER_LOAD_SCENE || 'HauntChoiceScene';

    /**
     * The array of images that will be loaded on this game. You can either use the image path or the imported image.
     * @example
     * [
     *   {
     *   name: 'image1',
     *   image: 'assets/images/image1.png',
     *   }
     *   {
     *   name: 'image1',
     *   image: 'https://myimageurl.com', // URL needs to enable CORS.
     *   }
     * ];
     * @type { array }
     * @default
     */
    this.SPRITE_SHEETS = [];
    this.dataService = new DataService();
  }

  preload() {
    this.load.script('webfont', 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js');
    this.load.rexAwait(async (successCallback) => {
      await this.dataService.getWeb3Config();

      //const house = await HouseTraitsSprite;
      const sprites = await SPRITE_SHEETS_ARRAY;
      this.SPRITE_SHEETS = [/*...house, */ ...sprites];
      if (this.SPRITE_SHEETS) {
        this.SPRITE_SHEETS.forEach((values) => {
          this.load.image(values.name, values.image);
        });
      }
      successCallback();
    });

    if (SOUND_EFFECTS_ASSETS.length > 0) {
      SOUND_EFFECTS_ASSETS.forEach((sound) => {
        this.load.audio(sound.name, sound.file);
      });
    }
    // Atlas
    if (ATLAS_CONFIG.length > 0) {
      ATLAS_CONFIG.forEach((value) => {
        if (value.multiatlas) {
          this.load.multiatlas(value.name, value.json);
        } else {
          this.load.atlas(value.name, value.image, value.json);
        }
      });
    }

    // Custom CSS
    // this.load.css('nescss', nesCss);
    this.progressBar = this.add.graphics();
    this.progressBox = this.add.graphics();
    this.cameraWidth = this.cameras.main.width;
    this.cameraHeight = this.cameras.main.height;

    this.rt = this.add.renderTexture(0, 0, this.cameraWidth, this.cameraHeight);
    this.rt.fill(0x000000, 0.3);

    this.progressBox.fillStyle(0x222222, 0.8);
    this.progressBox.fillRect(
      this.boxStartingX,
      this.cameraHeight / 2 + this.boxMargin,
      this.cameraWidth - this.boxPadding,
      this.boxHeight
    );

    this.loadingText = this.make.text({
      x: this.cameraWidth / 2,
      y: this.cameraHeight / 2 - this.boxHeight,
      text: 'Loading...',
      style: {
        font: '20px monospace',
        fill: '#ffffff',
      },
    });
    this.loadingText.setOrigin(0.5, 0.5);

    this.percentText = this.make.text({
      x: this.cameraWidth / 2,
      y: this.cameraHeight / 2 - 5,
      text: '0%',
      style: {
        font: '18px monospace',
        fill: '#ffffff',
      },
    });
    this.percentText.setOrigin(0.5, 0.5);
    this.load.on('progress', (value) => {
      this.currentValue = value;
      this.percentText.setText(parseInt(value * 100) + '%');
      this.progressBar.clear();
      this.progressBar.fillStyle(0xffffff, 1);
      this.progressBar.fillRect(
        this.barStartingX,
        this.cameraHeight / 2 + this.barMargin,
        (this.cameraWidth - this.boxHeight) * value,
        this.barHeight
      );
    });

    this.load.on('complete', () => {
      this.progressBar.destroy();
      this.progressBox.destroy();
      this.loadingText.destroy();
      this.percentText.destroy();
      this.rt.destroy();

      this.scene.stop();
    });
    this.scale.on('resize', (size) => {
      this.resize(size);
    });
  }

  create() {
    AnimationConfig.forEach((animConfig, index) => {
      animConfig.animations.forEach((animation) => {
        this.anims.create({
          key: animation.name,
          frameRate: animation.frameRate,
          frames: this.anims.generateFrameNames(animConfig.atlas, {
            prefix: animation.key,
            start: 0,
            end: animation.frameLength,
            zeroPad: 2,
          }),
          repeat: animation.repeat,
        });
      });
    });

    WebFont.load({
      custom: {
        families: ['BlackBeard'],
      },
      active: async () => await this.goToNextScene(),
    });
    // this.goToNextScene(); // Use in case there is no internet connection.
  }

  async goToNextScene() {
    const connected = await this.dataService.checkNetwork();
    if (!connected && window?.ethereum) this.add.modalMessage(ERROR_MESSAGES.WRONG_NETWORK_POLYGON_RELOAD);
    const vue = this.registry.get('vue');
    if (vue.$route.name === 'oni-game-specific-mansion') {
      const { contract_address, nft_id } = GameUtils.getContractNFTID(location.href);
      const { owns, wallet_address } = await this.dataService.checkNFTOwnership(contract_address, nft_id);
      // console.log(wallet_address);

      if (!owns) {
        this.scene.launch('HouseHauntScene', GameUtils.getContractNFTID(location.href));
        return;
      } else if (
        this.dataService.session?.contractAddress === contract_address &&
        this.dataService.session?.nft?.id === nft_id
      ) {
        this.scene.launch('MainScreenScene');
      } else {
        history.pushState({}, null, `#/oni-mansion-game/`);
      }
    } else {
      this.scene.launch(this.AFTER_LOAD_SCENE);
    }
    const configResponse = await this.dataService.web3GameService.checkNetwork();
    if (configResponse?.code === GAME_ERROR_CODE.WRONG_NETWORK) {
      this.add.modalMessage(configResponse.message);
    }
  }

  resize(size) {
    if (size) {
      this.cameraWidth = size.width;
      this.cameraHeight = size.height;

      this.rt.setPosition(0, 0);
      this.rt.width = this.cameraWidth;
      this.rt.height = this.cameraHeight;

      this.loadingText.setPosition(this.cameraWidth / 2, this.cameraHeight / 2 - this.boxHeight);
      this.percentText.setPosition(this.cameraWidth / 2, this.cameraHeight / 2 - 5);

      this.progressBox.clear();
      this.progressBox.fillStyle(0x222222, 0.8);
      this.progressBox.fillRect(
        this.boxStartingX,
        this.cameraHeight / 2 + this.boxMargin,
        this.cameraWidth - this.boxPadding,
        this.boxHeight
      );
      this.progressBar.clear();
      this.progressBar.fillStyle(0xffffff, 1);
      this.progressBar.fillRect(
        this.barStartingX,
        this.cameraHeight / 2 + this.barMargin,
        (this.cameraWidth - this.boxHeight) * this.currentValue,
        this.barHeight
      );
    }
  }
}
