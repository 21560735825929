import { DataService } from '../services/DataService';
import { CurrencyComponent } from './CurrencyComponent';

const WALLET_CONFIG = {
  x: 1682,
  y: 12,
  key: 'wallet_panel',
  origin: {
    x: 0,
    y: 0,
  },
};
const CURRENCY_POSITION = {
  x: 1705,
  y: 22,
};
export class Wallet extends Phaser.Scene {
  constructor() {
    super({ key: 'Wallet' });
    this.dataService = new DataService();
  }

  preload() {
    this.make.image(WALLET_CONFIG);
    this.wallet = new CurrencyComponent(this, CURRENCY_POSITION.x, CURRENCY_POSITION.y, this.coins, '58px', 58);
    this.load.rexAwait(async (successCallback, failureCallback) => {
      await this.getData();
      successCallback();
    });
  }

  async getData() {
    this.coins = await this.dataService.getCoins();
    this.wallet.updateCoinAmount(this.coins);
  }

  create() {
    this.events.removeListener('update_wallet');
    this.events.on('update_wallet', () => this.getData, this);
  }
}
