import Phaser from 'phaser';
import { ShopScreenScene } from './scenes/ShopScreenScene';
import { PreloadScene } from './scenes/PreloadScene';
import { MainScreenScene } from './scenes/MainScreenScene';
import { MobileCheckScene } from './scenes/MobileCheckScene';
import { MissionPanel } from './components/MissionPanel';
import { HauntChoiceScene } from './scenes/HauntChoiceScene';
import { HauntMenuSubScene } from './scenes/sub/HauntMenuSubScene';
import { HauntNFTListSubScene } from './scenes/sub/HauntNFTListSubScene';
import { MessageModalSubScene } from './scenes/sub/MessageModalSubScene';
import { CharacterSelectPanel } from './components/CharacterSelectPanel/CharacterSelectPanel';
import { CharacterSelectScene } from './scenes/CharacterSelectScene';
import { HauntMessageBoxSubScene } from './scenes/sub/HauntMessageBoxSubScene';
import { TraitPanelSubScene } from './scenes/sub/TraitPanelSubScene';
import { HouseHauntScene } from './scenes/HouseHauntScene';
import { LootboxWindow } from './components/LootboxWindow';
import { OpenLootbox } from './components/OpenLootbox';
import { MailBoxPanel } from './components/MailBox/MailBoxPanel';

import AwaitLoaderPlugin from 'phaser3-rex-plugins/plugins/awaitloader-plugin.js';

// this one \/ is autoexecuted
import Factory from './Utils/Factory';
import { MainScreenMenus } from './scenes/sub/MainScreenMenus';
import { Wallet } from './components/Wallet';
import { LorePanel } from './components/Lores/LorePanel';

const scene = [
  PreloadScene,
  MobileCheckScene,
  ShopScreenScene,
  MainScreenScene,
  CharacterSelectScene,
  HauntChoiceScene,
  HauntMenuSubScene,
  HauntNFTListSubScene,
  TraitPanelSubScene,
  MainScreenMenus,
  MissionPanel,
  MailBoxPanel,
  Wallet,
  LorePanel,
  CharacterSelectPanel,
  LootboxWindow,
  OpenLootbox,
  MessageModalSubScene,
  HouseHauntScene,
  HauntMessageBoxSubScene,
];

export const IS_DEBUG_MODE = false;

export default {
  name: 'OniMansionGame',
  data: () => ({
    /**
     * @type {Phaser.Game}
     */
    game: null,
  }),
  mounted() {
    this.game = new Phaser.Game({
      canvas: document.getElementById('oni-mansion-game'),
      type: Phaser.WEBGL,
      scene,
      scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        width: 1920,
        height: 1080,
      },
      pixelArt: false,
      fps: 60,
      plugins: {
        global: [
          {
            key: 'rexAwaitLoader',
            plugin: AwaitLoaderPlugin,
            start: true,
          },
        ],
      },
    });
    this.game.registry.set('vue', this);
  },
};
