import traits from '../assets/traits.png';
import traits_json from '../assets/traits.json';
import tree from '../assets/tree.png';
import tree_json from '../assets/tree.json';
import candle from '../assets/candle.png';
import candle_json from '../assets/candle.json';
import loading_gif from '../assets/loading_gif.png';
import loading_gif_json from '../assets/loading_gif.json';
import lootbox_animations from '../assets/lootbox_animations.png';
import lootbox_animations_json from '../assets/lootbox_animations.json';
import customization_frames from '../assets/customization_frames.png';
import customization_frames_json from '../assets/customization_frames.json';
import shop_frames from '../assets/shop_frames.png';
import shop_frames_json from '../assets/shop_frames.json';
import mailbox from '../assets/mailbox.png';
import mailbox_json from '../assets/mailbox.json';
import click_effect from '../assets/click_effect.png';
import click_effect_json from '../assets/click_effect.json';
import transaction_gif from '../assets/transaction_gif.png';
import transaction_gif_json from '../assets/transaction_gif.json';
import lootbox_button_gif from '../assets/shop/lootbox/lootbox_button.png';
import lootbox_button_gif_json from '../assets/shop/lootbox/lootbox_button.json';
import lootbox_progress_bar from '../assets/shop/lootbox/progress_bar/lootbox_progress_bar.png';
import lootbox_progress_bar_json from '../assets/shop/lootbox/progress_bar/lootbox_progress_bar.json';
import candle_animation from '../assets/main_screen/candle_animation.png';
import candle_animation_json from '../assets/main_screen/candle_animation.json';

export const ATLAS_CONFIG = [
  {
    name: 'traits',
    image: traits,
    json: traits_json,
    multiatlas: false,
  },
  {
    name: 'loading_gif',
    image: loading_gif,
    json: loading_gif_json,
    multiatlas: false,
  },
  {
    name: 'tree',
    image: tree,
    json: tree_json,
    multiatlas: false,
  },
  {
    name: 'lootbox_animations',
    image: lootbox_animations,
    json: lootbox_animations_json,
    multiatlas: false,
  },
  {
    name: 'candle',
    image: candle,
    json: candle_json,
    multiatlas: false,
  },
  {
    name: 'customization_frames',
    multiatlas: false,
    image: customization_frames,
    json: customization_frames_json,
  },
  {
    name: 'shop_frames',
    multiatlas: false,
    image: shop_frames,
    json: shop_frames_json,
  },
  {
    name: 'mailbox',
    multiatlas: false,
    image: mailbox,
    json: mailbox_json,
  },
  {
    name: 'click_effect',
    multiatlas: false,
    image: click_effect,
    json: click_effect_json,
  },
  {
    name: 'transaction_gif',
    multiatlas: false,
    image: transaction_gif,
    json: transaction_gif_json,
  },
  {
    name: 'lootbox_button_gif',
    multiatlas: false,
    image: lootbox_button_gif,
    json: lootbox_button_gif_json,
  },
  {
    name: 'lootbox_progress_bar',
    multiatlas: false,
    image: lootbox_progress_bar,
    json: lootbox_progress_bar_json,
  },
  {
    name: 'candle_animation',
    multiatlas: false,
    image: candle_animation,
    json: candle_animation_json,
  },
];
