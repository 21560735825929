import { GAME_MESSAGES } from '../../../consts/Messages';
import { TRAITS_CONFIG } from '../consts/traits/TraitsConfig';
import { DEFAULT_ERROR } from '../consts/Messages';
import { House } from '../entities/House';
import { LoadingStateManager } from '../manager/LoadingStateManager';
import { SoundManager } from '../manager/SoundManager';
import { IS_DEBUG_MODE } from '../OniMansionGame';
import { DataService } from '../services/DataService';
import { GameUtils } from '../Utils/GameUtils';
import { TransactionWaitingGif } from '../manager/TransactionWaitingGif';
import { CurrencyComponent } from './CurrencyComponent';

export class PurchasePanel {
  constructor(scene) {
    /**
     * @type {Phaser.Scene}
     */
    this.scene = scene;

    this.dataService = new DataService();
    this.transactionGif = new TransactionWaitingGif(this.scene, false);
    this.same_slot_warning = false;

    this.owned_traits = this.dataService.owned_traits;

    this.item;

    this.overlay = this.scene.add
      .renderTexture(0, 0, this.scene.game.config.width, this.scene.game.config.height)
      .setInteractive();
    this.overlay.fill(0x000000, 0.8);
    this.overlay.depth = 20;
    this.config = { ...TRAITS_CONFIG };
    this.house_preview = new House(this.scene, 766, 137, this.config, true).setDepth(20);
    this.purchase_panel = this.scene.add
      .sprite(658, 18, 'purchase_preview_frame')
      .setOrigin(0, 0)
      .setDepth(21);
    this.value_text = new CurrencyComponent(this.scene, 1555, 559, 0, '91px').setDepth(22);
    this.purchase_text = this.scene.add
      .text(1127, 810, 'Purchase', { fontFamily: 'BlackBeard', fontStyle: 'bold', fontSize: '92px', color: '#000000' })
      .setOrigin(0, 0)
      .setDepth(22);
    this.close_button = this.scene.add
      .zone(1787, 27, 92, 92)
      .setOrigin(0, 0)
      .setDepth(21)
      .setInteractive();
    if (IS_DEBUG_MODE) {
      if (this.debug_graphics) this.debug_graphics.destroy();
      this.debug_graphics = this.scene.add.graphics().setDepth(21);
      this.debug_graphics.fillStyle(0xffff00, 0.7);
    }

    this.purchase_zone = this.scene.add
      .zone(1034, 800, 423, 132)
      .setInteractive()
      .setDepth(22)
      .setOrigin(0, 0);
    this.purchase_zone.on('pointerdown', () => {
      this.purchase();
    });

    this.hidePanel();
    this.close_button.on('pointerdown', () => {
      SoundManager.clickScreenSound(this.scene);
      this.hidePanel();
    });
    if (IS_DEBUG_MODE) {
      this.debug_graphics.fillRectShape(this.purchase_zone);
      this.debug_graphics.fillRectShape(this.close_button);
    }
  }

  async purchase() {
    const has_trait = this.owned_traits.some((trait) => trait.id === this.item.id);
    // console.log(has_trait);
    // console.log(this.coins, this.item.value, has_trait, this.item.value);
    // console.log(this.coins >= this.item.value && !has_trait && this.item.value > 0);

    // TRUE means the warning was given.
    if (this.dataService.checkAlreadyHasAttribute(this.item) && !this.same_slot_warning) {
      this.scene.add.modalMessage(GAME_MESSAGES.ALREADY_HAVE_TRAIT);
      this.same_slot_warning = true;
    } else {
      this.same_slot_warning = true;
    }

    SoundManager.clickScreenSound(this.scene);
    if (this.coins >= this.item.value && !has_trait && this.item.value > 0 && this.same_slot_warning) {
      this.transactionGif.setVisible(true);
      try {
        const response = await this.dataService.buyHouseAttribute(this.item.id, this.item.value);
        if (response) {
          const apiCreation = await this.dataService.addHouseAttributes(this.item);
          if (!apiCreation?.code) {
            this.dataService.should_reload_data = true;
            // console.log(response);
            // await this.dataService.addHouseAttributes(this.item).then();
            this.coins -= this.item.value;
            this.scene.sound.play('cash_register');
            this.coins = await this.dataService.getCoins();
            this.owned_traits.push(this.item);

            // this.owned_traits = await this.dataService.getHouseAttributes();
            // await this.dataService.getEquipedHouseAttributes();
            await this.dataService.getHouseInformation();
            this.owned_traits = this.dataService.owned_traits;
            this.hidePanel(true);
            this.scene.scene.stop('MessageModalSubScene');
          } else {
            this.scene.add.modalMessage(apiCreation.message);
          }
        }

        this.transactionGif.setVisible(false);
      } catch (error) {
        this.transactionGif.setVisible(false);
        console.log(error);
      }
    } else if (this.same_slot_warning) {
      this.scene.add.modalMessage(GAME_MESSAGES.NO_COINS_TO_BUY);
    }
  }

  setInformation(item) {
    this.coins = this.dataService.coins;
    this.same_slot_warning = false;
    try {
      this.config = { ...TRAITS_CONFIG };
      this.item = item;
      // console.log({ item });
      // this.purchase_panel.setFrame(`traits/${item.trait_type}/shop_preview/purchase/${item.name}`);
      this.config[item.trait_type] = item.name;
      this.house_preview.updateTraitFrames(this.config);
      //this.house_preview.house_traits[item.trait_type].setTexture(`${item.trait_type}/${item.name}`);
      if (item.value > 0) {
        this.value_text.updateCoinAmount(`${this.item.value}`);
        this.purchase_zone.setInteractive();
      } else {
        this.purchase_zone.disableInteractive();
        this.value_text.updateCoinAmount(``);
      }
    } catch (error) {
      console.error(error);
    }
  }

  showPanel(item) {
    this.setInformation(item);
    this.overlay.setVisible(true);
    // this.purchase_panel.setVisible(true);
    this.house_preview.setVisible(true);
    this.close_button.setVisible(true);
    this.purchase_zone.setVisible(true);
    this.value_text.setVisible(true);
    this.purchase_text.setVisible(true);
    this.purchase_panel.setVisible(true);
    if (IS_DEBUG_MODE) this.debug_graphics.setVisible(true);
  }

  hidePanel(update = false) {
    this.overlay.setVisible(false);
    // this.purchase_panel.setVisible(false);
    this.house_preview.setVisible(false);
    this.house_preview.removeAllTraits();
    this.close_button.setVisible(false);
    this.purchase_zone.setVisible(false);
    this.value_text.setVisible(false);
    this.purchase_text.setVisible(false);
    this.purchase_panel.setVisible(false);
    if (IS_DEBUG_MODE) this.debug_graphics.setVisible(false);
    this.scene.events.emit('update_scene_data', update);
  }
}
