export const LoadingAnimationConfig = {
  atlas: 'loading_gif',
  animations: [
    {
      frameRate: 9,
      key: 'loading',
      name: 'loadingLoop',
      frameLength: 16,
      repeat: -1,
    },
  ],
};
