export const CandleCountAnimationConfig = {
  atlas: 'candle_animation',
  animations: [
    {
      frameRate: 10,
      key: 'explosion/candle',
      name: 'candle_explosion',
      frameLength: 21,
      repeat: 0,
    },
    {
      frameRate: 10,
      key: 'idle/candle',
      name: 'candle_idle',
      frameLength: 12,
      repeat: -1,
    },
  ],
};
