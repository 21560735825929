import { PAGINATION_LAYOUT } from '../layouts/ShopScreen';
import lodash from 'lodash';
import { SoundManager } from '../manager/SoundManager';

export class ShopPagination extends Phaser.GameObjects.Container {
  /**
   * Class responsible for managing pagination.
   * @param { Phaser.Scene } scene
   */
  constructor(scene, complete_list) {
    super(scene, 0, 0);
    this.scene = scene;
    this.limit = 9;
    this.has_previous = false;
    this.has_next = false;
    this.page = 1;
    this.complete_list = complete_list || [];
    this.current_page_list = [];

    this.min_page = 1;
    this.max_page = Math.ceil(this.complete_list.length / this.limit) || 1;
    this.createPreviousButton();
    this.createNextButton();
    this.getCurrentData();
    this.scene.add.existing(this);
  }

  createPreviousButton() {
    this.previous_button = this.scene.make.image(PAGINATION_LAYOUT.PREVIOUS).setInteractive();
    this.previous_button.on('pointerup', (pointer) => {
      if (this.min_page < this.page) {
        SoundManager.clickScreenSound(this.scene);
        this.page--;
        this.getCurrentData(true);
      }
    });
    this.add(this.previous_button);
  }
  createNextButton() {
    this.next_button = this.scene.make.image(PAGINATION_LAYOUT.NEXT).setInteractive();
    this.next_button.on('pointerup', (pointer) => {
      if (this.max_page > this.page) {
        SoundManager.clickScreenSound(this.scene);
        this.page++;
        this.getCurrentData(true);
      }
    });
    this.add(this.next_button);
  }

  checkButtonsOpacity() {
    this.previous_button.alpha = this.page === this.min_page ? 0.4 : 1;
    this.next_button.alpha = this.page === this.max_page ? 0.4 : 1;
  }

  getCurrentData(update = false) {
    this.checkButtonsOpacity();
    this.current_page_list = lodash.cloneDeep(this.complete_list).splice(this.limit * (this.page - 1), this.limit);
    if (this.current_page_list.length === 0 && this.page > 1) {
      this.page--;
      this.max_page = Math.ceil(this.complete_list.length / this.limit) || 1;
      this.getCurrentData(true);
    }
    if (update) this.scene.events.emit('paginated_data');
  }
}
