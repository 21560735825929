import Phaser from 'phaser';
import { TRAIT } from '../layouts/MainScreen';
import { MainScreenScene } from '../scenes/MainScreenScene';
import { SoundManager } from '../manager/SoundManager';
import { GAME_MESSAGES } from '../../../consts/Messages';

export class TraitPanelElement extends Phaser.GameObjects.Sprite {
  constructor(scene) {
    super(scene, 0, 0, TRAIT.TEXTURE);
    /**
     * @type {boolean}
     */
    this.needToCheckIsVisible = false;
    /**
     * @type {number}
     */
    this.idCounter;
    this.setOrigin(TRAIT.ORIGIN.X, TRAIT.ORIGIN.Y)
      .setDepth(TRAIT.DEPTH)
      .setInteractive()
      .on('pointerup', this.onClick, this);
    scene.add.existing(this);
  }

  /**
   *
   * @param {Phaser.Input.Pointer} param0
   * @returns {void}
   */
  async onClick({ downTime, upTime }) {
    if (true) {
      const scene = this.scene.scene.get('MainScreenScene');
      scene.add.modalMessage(GAME_MESSAGES.NO_MORE_ATTRIBUTES_SWAP);
    } else {
      if ((this.scene.scroll_panel && !this.canInteract()) || upTime - downTime >= DRAG_LIMIT_DELAY) return;
      console.log('clicked');
      /**
       * @type {MainScreenScene}
       */
      const scene = this.scene.scene.get('MainScreenScene');
      SoundManager.clickScreenSound(this.scene);
      const item = this.getData('trait');
      const active = scene.dataService.active_traits_ids?.some((trait) => trait == item.id);
      if (!active) {
        scene.loader.setVisible(true);
        await this.updateEquipedAttributes(item);
        scene.loader.setVisible(false);
      } else {
        scene.add.modalMessage(GAME_MESSAGES.TRAIT_ALREADY_ACTIVE);
      }
    }
  }

  async updateEquipedAttributes(item) {
    // const scene = this.scene.scene.get('MainScreenScene');
    // scene.dataService.owned_traits?.forEach((attr) => {
    //   if (attr.trait_type_code === item.trait_type_code && attr.active) {
    //     attr.active = false;
    //   }
    //   if (attr.id === item.id) {
    //     attr.active = true;
    //   }
    // });
    // await scene.dataService.updateEquipedAttributes();
    // scene.dataService.should_reload_data = true;
    // await scene.getData(true);
    // scene.base_house.updateTraitFrames(scene.active_traits);
  }

  canInteract() {
    if (this.scene.scroll_panel.overScrollablePanel() && !this.scene.scroll_panel.isScrolling) return true;
    else return false;
  }

  setConfig(trait, id) {
    this.setData('trait', trait).setFrame(TRAIT.FRAME(trait));
    this.idCounter = id;
  }

  setCheckIsVisible(enable) {
    this.needToCheckIsVisible = enable;
  }
}
async function request() {
  /**
   * @type {MainScreenScene}
   */
  const scene = this.scene.scene.get('MainScreenScene');
  const { id, trait_type_code } = this.getData('trait');
  await scene.dataService.equipHouseAttribute(id, trait_type_code);
  scene.dataService.should_reload_data = true;
  await scene.getData();
}

const MARGIN = 10;
const IDS_THAT_NEED_MARGIN = [0, 1];
const DRAG_LIMIT_DELAY = 300;
