import { GameUtils } from '../Utils/GameUtils';
const DAYS_LEFT = {
  TIME: {
    x: 0,
    y: 0,
    origin: {
      x: 0,
      y: 0,
    },
    key: 'days_left_time_panel',
  },
  DAYS: {
    x: 191,
    y: 0,
    origin: {
      x: 0,
      y: 0,
    },
    key: 'days_left_panel',
  },
  TEXT: {
    style: {
      fontSize: '42px',
      fontFamily: 'BlackBeard',
      color: '#000000',
    },
  },
  TEXT_TIME: {
    style: {
      fontSize: '56px',
      fontFamily: 'BlackBeard',
      color: '#000000',
    },
  },
};

export class DaysLeftComponent extends Phaser.GameObjects.Container {
  /**
   * @param {Phaser.Scene} scene
   */
  constructor(scene) {
    super(scene, 1204, 12);
    this.init_time = 0;
    this.dataService = scene.dataService;
    this.time_panel = scene.make.image(DAYS_LEFT.TIME);
    this.days_panel = scene.make.image(DAYS_LEFT.DAYS);
    const center_days_panel = this.days_panel.getCenter();
    this.days_left_text = scene.add
      .text(center_days_panel.x, center_days_panel.y, `DAYS LEFT: 0`, DAYS_LEFT.TEXT.style)
      .setOrigin(0.5, 0.5);
    const center_time_panel = this.time_panel.getCenter();

    this.time_text = scene.add
      .text(center_time_panel.x, center_time_panel.y, '', DAYS_LEFT.TEXT_TIME.style)
      .setOrigin(0.5, 0.5);
    this.add([this.time_panel, this.days_panel, this.days_left_text, this.time_text]);
    this.getTimeLeft();
    this.setDaysText();
    this.setTimeText();
    scene.add.existing(this);
    this.canUpdate = true;
    scene.events.on('update', this.onUpdate, this);
  }

  getTimeLeft() {
    const { current_day, time_left_ms } = GameUtils.currentGameDay();
    this.current_day = current_day;
    this.time_left_ms = time_left_ms;
  }

  setDaysText() {
    const days_left = Math.max(0, 32 - this.current_day);
    if (this.days_left_text) this.days_left_text.setText(`DAYS LEFT: ${days_left}`);
  }

  setTimeText() {
    if (parseInt(this.dataService.currentGameDay) === parseInt(this.current_day)) {
      if (this.time_text) this.time_text?.setText(GameUtils.getHoursMinutesEndDay(this.time_left_ms || 0));
    } else {
      if (this.time_text) this.time_text?.setText('00:00');
    }
  }

  onUpdate(time) {
    if (time - this.init_time > 1000 && this.canUpdate) {
      this.init_time = time;
      this.getTimeLeft();
      this.setTimeText();
      this.setDaysText();
    }
  }

  destroy() {
    super.destroy();
    this.canUpdate = false;
  }
}
