import Phaser from 'phaser';

import { Overlay } from '../../components/Overlay';
import { IMAGES, TEXTS, BUTTONS, DELAY } from '../../layouts/MessageModal';
import { SoundManager } from '../../manager/SoundManager';
import { DebugUtils } from '../../Utils/DebugUtils';

/**
 * How to use:
 * inside of scene use this: (callback is called after player click on close button)
 * this.add.modalMessage("Error message and bla bla bla", callback);
 */

export class MessageModalSubScene extends Phaser.Scene {
  constructor() {
    super({ key: 'MessageModalSubScene', visible: false });
  }

  init(params) {
    this.params = params;
  }

  create() {
    // this.cameras.main.fadeIn(DELAY.FADE_IN, 0, 0, 0);
    this.overlay = new Overlay(this, true);
    const frame = this.make.sprite(IMAGES.MODAL);

    // this.scrollPanel = new ScrollablePanel(this);
    // this.scrollPanel.setPositions();
    this.addButtons();
    if (this.params.config && this.params.config.big) {
      frame.setTexture('error_box_2x');
      frame.setPosition(IMAGES.MODAL.x, IMAGES.MODAL.y - frame.height / 3.5);
      this.close_button.setPosition(this.close_button.x - 55, this.close_button.y - 230);
    }
    const center = frame.getCenter();
    DebugUtils.debug(this, this.close_button);
    this.make
      .text(TEXTS.MESSAGE)
      .setPosition(center.x, center.y)
      .setText(this.params.message);
    this.scene.bringToTop();
  }

  addButtons() {
    const { x, y, width, height } = BUTTONS.CLOSE;
    this.close_button = this.add
      .zone(x, y, width, height)
      .setInteractive()
      .setOrigin(0, 0);
    this.close_button.on('pointerdown', () => {
      SoundManager.clickScreenSound(this);
      this.scene.stop();
      this.params.callback?.();
    });
  }
}
